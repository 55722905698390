import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, Input, Select, Text, Stack } from '@chakra-ui/react';

const DeviceDropdown = ({ devices, value, onChange }: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDevices, setFilteredDevices] = useState(devices);

  useEffect(() => {
    setFilteredDevices(
      devices.filter((device: any) =>
        device.serialno.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, devices]);

  return (
    <Stack spacing={4}>
      <FormControl>
        <FormLabel>
          Search Device <span style={{ color: 'red', fontSize: '12px' }}>(use device serial number)</span>
        </FormLabel>
        <Input
          placeholder="Search device serial number"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Device Serial Number</FormLabel>
        {filteredDevices.length > 0 ? (
          <Select placeholder="Select a device" value={value} onChange={onChange}>
            {filteredDevices.map((device: any, index: number) => (
              <option key={index} value={device.mac_address}>
                {device.name} - {device.serialno}
                {device.is_licensed ? ' (Licensed)' : ''}
              </option>
            ))}
          </Select>
        ) : (
          <Text color="red">No devices found</Text>
        )}
      </FormControl>
    </Stack>
  );
};

export default DeviceDropdown;