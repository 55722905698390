import { Box, Flex, Select, Spacer, useToast } from "@chakra-ui/react";
// import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
// import Datatable from "../../components/DataTable";
import DatatableTwo from "../../components/DataTableTwo";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
// import { formatDateToISOString } from "../../utils";
// import { exportData } from "../../utils/reportingDownloads";

const TopUpHistory = () => {
  const toast = useToast()
  const [isActive, setIsActive] = useState<any>("top-up");
  const fetchTopUps = useStore((state) => state.fetchTops);
  const fetchAllTransactions = useStore((state) => state.fetchPosTransactions);
  // const fetchTransaction = useStore((state) => state.fetchSingleTransaction)
  const posTopUps = useStore((state) => state.topUps);
  const posTransactions = useStore((state) => state.transactions);
  const fetchTransactionsByDate = useStore((state) => state.fetchTransactionsByDate)
  const fetchTopupsByDate = useStore((state) => state.fetchTopUpsByDate)
  const isLoading = useStore((state) => state.loadingTopUps)

  const handleSelectChange = (e: any) => {
    setIsActive(e.target.value);
  };

  useEffect(() => {
    fetchTopUps();
    fetchAllTransactions();
  }, [fetchTopUps, fetchAllTransactions]);


  const fetchTransactionData = (data: any) => {
    const newDates = {
      start_date: data.start_date.toISOString().split("T")[0], 
      end_date: data.end_date.toISOString().split("T")[0]
    }
    
    fetchTransactionsByDate(newDates).then((res: any) => {
      if (res.detail){
        toast({
          title: "Error",
          description: `${res.detail}`,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: `Date range filter for transactions was applied successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    })
  }

  const fetchTopUpData = (data: any) => {
    const newDates = {
      start_date: data.start_date.toISOString().split("T")[0], 
      end_date: data.end_date.toISOString().split("T")[0]
    }

    fetchTopupsByDate(newDates).then((res: any) => {
      if (res.detail){
        toast({
          title: "Error",
          description: `${res.detail}`,
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Success",
          description: `Date range filter for transactions was applied successfully`,
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    })
  }

  return (
    <AdminAppShell>
      <Flex direction={"column"} gap={"8"}>
        {/* <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={30} />
          <Box fontSize="2xl" fontWeight="bold">
            Transaction History
          </Box>
        </Flex> */}

        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={"3"}>
            <PcCaseIcon size={30} />
            <Box fontSize="2xl" fontWeight={"bold"}>
              {" "}
              Viewing {isActive === "transactions" ? "" : isActive} transaction
              history
            </Box>
          </Box>

          <Spacer />

          <Box w={"50%"}>
            <Select
              value={isActive}
              placeholder="Select a transaction history you'd like to view"
              onChange={handleSelectChange}
            >
              <option value="top-up">Top Up</option>
              {/* <option value="fare">Fare</option> */}
              <option value="transactions">Transactions</option>
            </Select>
          </Box>
        </Flex>

        {isActive === "top-up" ? (
          <Box>
            <DatatableTwo
              columnsToSelect={[
                {
                  Header: "Pan ID",
                  accessorKey: "pan_id",
                  id: 1
                },
                {
                  Header: "Top Up Date",
                  accessorKey: "topup_date",
                  id: 2
                },
                {
                  Header: "User Code",
                  accessorKey: "user_code",
                  id: 3
                },
                {
                  Header: "Txn Code",
                  accessorKey: "txn_code",
                  id: 4
                },
                {
                  Header: "Amount",
                  accessorKey: "amount",
                  id: 5
                },
                {
                  Header: "Type",
                  accessorKey: "type",
                  id: 6
                },
                {
                  Header: "Sync Status",
                  accessorKey: "external_sync_status",
                  id: 7
                },
                {
                  Header: "Sync Timestamp",
                  accessorKey: "external_sync_ts",
                  id: 8
                },
                {
                  Header: "Remarks",
                  accessorKey: "remarks",
                  id: 9
                },
                // {
                //   Header: "Amount",
                //   accessorKey: "amount",
                //   id: 10
                // },
              ]}
              headerColumns={[
                {
                  Header: "Pan ID",
                  accessorKey: "pan_id",
                  id: 1
                },
                {
                  Header: "Top Up Date",
                  accessorKey: "topup_date",
                  id: 2
                },
                {
                  Header: "User Code",
                  accessorKey: "user_code",
                  id: 3
                },
                {
                  Header: "Txn Code",
                  accessorKey: "txn_code",
                  id: 4
                },
                {
                  Header: "Amount",
                  accessorKey: "amount",
                  id: 5
                },
                {
                  Header: "Type",
                  accessorKey: "type",
                  id: 6
                },
                {
                  Header: "Sync Status",
                  accessorKey: "external_sync_status",
                  id: 7
                },
                {
                  Header: "Sync Timestamp",
                  accessorKey: "external_sync_ts",
                  id: 8
                },
                {
                  Header: "Remarks",
                  accessorKey: "remarks",
                  id: 9
                },
              ]}
              data={posTopUps}
              dataTableHeading={"Top Up"}
              handleDelete={() => { }}
              handleUpdate={() => { }}
              handleViewMore={() => { }}
              handleDateDataFilter={fetchTopUpData}
              fetchAllTopsUps={fetchTopUps}   
              isSearch={true}
              isActions={false}
              isLoading={isLoading}
              isDownloadActions={true}
              pageSizeLimit={7}
            />
          </Box>
        ) : (
          <Box>

            <DatatableTwo
              columnsToSelect={[
                {
                  Header: "Transaction Date Time",
                  accessorKey: "transaction_datetime",
                  id: 1
                },
                {
                  Header: "Pan ID",
                  accessorKey: "pan_id",
                  id: 2
                },
                {
                  Header: "Location ID",
                  accessorKey: "location_id",
                  id: 3
                },
                {
                  Header: "Total Amount",
                  accessorKey: "total_amount",
                  id: 4
                },
                {
                  Header: "Operator",
                  accessorKey: "pto",
                  id: 5
                },
                {
                  Header: "Vehicle ID",
                  accessorKey: "vehicle_id",
                  id: 6
                },
                {
                  Header: "Sync Status",
                  accessorKey: "external_sync_status",
                  id: 7
                },
                {
                  Header: "Sync Timestamp",
                  accessorKey: "external_sync_ts",
                  id: 8
                },
                {
                  Header: "Remarks",
                  accessorKey: "remarks",
                  id: 9
                },
              ]}
              headerColumns={[
                {
                  Header: "Transaction Date Time",
                  accessorKey: "transaction_datetime",
                  id: 1
                },
                {
                  Header: "Pan ID",
                  accessorKey: "pan_id",
                  id: 2
                },
                {
                  Header: "Location ID",
                  accessorKey: "location_id",
                  id: 3
                },
                {
                  Header: "Total Amount",
                  accessorKey: "total_amount",
                  id: 4
                },
                {
                  Header: "Operator",
                  accessorKey: "pto",
                  id: 5
                },
                {
                  Header: "Vehicle ID",
                  accessorKey: "vehicle_id",
                  id: 6
                },
                {
                  Header: "Sync Status",
                  accessorKey: "external_sync_status",
                  id: 7
                },
                {
                  Header: "Sync Timestamp",
                  accessorKey: "external_sync_ts",
                  id: 8
                },
                {
                  Header: "Remarks",
                  accessorKey: "remarks",
                  id: 9
                },
              ]}
              data={posTransactions}
              dataTableHeading={"Transactions"}
              handleDelete={() => { }}
              handleUpdate={() => { }}
              handleViewMore={() => { }}
              handleDateDataFilter={fetchTransactionData}
              fetchAllTransactions={fetchAllTransactions}
              isSearch={true}
              isActions={false}
              isLoading={isLoading}
              isDownloadActions={true}
              pageSizeLimit={7}
            />
          </Box>
        )}
      </Flex>
    </AdminAppShell>
  );
};

export default TopUpHistory;
