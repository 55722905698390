import React from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Box, Flex, Spacer, useToast } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../components/DataTableThree";
import { useReconStore } from "../../hooks";

export const ViewTransactionExceptions = () => {
  const toast = useToast();
  const downloadTransactionExceptionReportFile = useReconStore(
    (state) => state.downloadTransactionExceptionReportFile
  )

  const columns = [
    { Header: "TXN GUID", accessor: "txn_guid" },
    { Header: "Transaction DateTime", accessor: "transaction_datetime" },
    { Header: "External Sync Status", accessor: "external_sync_status" },
    { Header: "External Sync TS", accessor: "external_sync_ts" },
    { Header: "Remarks", accessor: "remarks" },
    { Header: "Total Amount", accessor: "total_amount" },
  ];

     /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
     const handleDownloadFunc = async (params: any) => {
      const { format, type, search, startDate, endDate } = params;
  
      try {
        await downloadTransactionExceptionReportFile({ format, search, startDate, endDate });
  
        // Show a success toast
        toast({
          title: "Export Successful",
          description: `Your ${format.toUpperCase()} file has been downloaded.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } catch (error: any) {
        // Handle specific error responses
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400) {
            toast({
              title: "Export Failed",
              description: data.detail || "Invalid export parameters.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          } else if (status === 404) {
            toast({
              title: "No Records Found",
              description: "There are no records matching your criteria.",
              status: "warning",
              duration: 5000,
              isClosable: true,
            });
          } else if (status === 401) {
            toast({
              title: "Unauthorized",
              description: "Your session has expired. Please log in again.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            // Optionally, redirect to login
          } else {
            toast({
              title: "Export Failed",
              description: "An unexpected error occurred while exporting the data.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        } else {
          // Network or other errors
          toast({
            title: "Export Failed",
            description: "A network error occurred. Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };

  const handleEdit = (item: any) => {
    console.log("Edit clicked for item:", item);
    // Add your logic to handle editing the item
  };

  const handleResubmit = (item: any) => {
    console.log("Resubmit clicked for item:", item);
    // Add your logic to handle resubmitting the item
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            View Transaction Exceptions
          </Box>
        </Flex>
        <Spacer />
      </Flex>

      <Box py={6}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transactions-exceptions`}
          tokenKey="jwt_access_token"
          handleDownloadFunc={handleDownloadFunc}
          showActions={true}
          showEditButton={true} // Enable the Edit button
          showResubmitButton={true} // Enable the Resubmit button
          handleEdit={handleEdit} // Pass the edit handler
          handleResubmit={handleResubmit} // Pass the resubmit handler
          showIcons={false}
        />
      </Box>
    </AdminAppShell>
  );
};
