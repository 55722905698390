import React, { useEffect } from "react";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { Box, Button, Flex } from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import DataTableThree from "../../../components/DataTableThree";

const ValidationInfo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleValidationListItem = useStore(
    (state) => state.fetchSingleValidationListItem
  );
  const validation_list_item = useStore((state) => state.validation_list_item);

  const columns = [
    { Header: "TXN GUID", accessor: "pan_id" },
    { Header: "TCH GUID", accessor: "flag" },
    { Header: "Invoicing ID", accessor: "balance" },
    { Header: "Status", accessor: "timestamp" },
  ];

  useEffect(() => {
    if (id) {
      fetchSingleValidationListItem({ id: id });
    }
  }, [fetchSingleValidationListItem]);

  return (
    <AdminAppShell>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
        borderRadius="md"
        boxShadow="sm"
      >
        <Flex flexDirection={"column"} alignItems="start" gap={4}>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <CarTaxiFrontIcon size={35} />
            <Box fontSize="2xl" fontWeight="bold">
              More Info
            </Box>
          </Box>
          <Box fontSize="lg" fontWeight="bold">
            Viewing Validation List History for PAN ID:{" "}
            {validation_list_item?.pan_id || "Loading..."}
          </Box>
        </Flex>
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box py={4}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/validation-list/records-by-pan/${validation_list_item?.pan_id}`}
          tokenKey="jwt_access_token"
          downloadFunc={() => {}}
          handleDownloadFunc={() => {}}
          handleMore={() => {}}
          showExports={false}
          showActions={false}
          showDateRangeFilter={false}
        />
      </Box>
    </AdminAppShell>
  );
};

export default ValidationInfo;
