import { Spinner, Table, Tbody, Td, Th, Thead, Tr, Flex, Box } from "@chakra-ui/react";

export const DataTableFive = ({ columns, data, loading, ...props }: any) => {
  if (loading) {
    return (
      <Flex flexDirection={'column'} alignItems="center" justifyContent="center" height="54vh" gap={'4'}>
        <Spinner size="xl" />
        <Box fontWeight={'bold'}>Loading... Please wait</Box>
      </Flex>
    );
  }

  return (
    <Box overflowX="auto" width="100%" {...props}>
      <Table size="sm" variant="simple" width="100%">
        <Thead>
          <Tr>
            {columns.map((col: any) => (
              <Th key={col.accessor}>{col.Header}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.length === 0 ? (
            <Tr>
              <Td colSpan={columns.length}>
                <Flex direction="column" alignItems="center" justifyContent="center" py={10} gap={2}>
                  <Box as="span" fontSize="3xl" role="img" aria-label="no-data">
                    📭
                  </Box>
                  <Box fontWeight="bold" color="gray.600">
                    No records found
                  </Box>
                </Flex>
              </Td>
            </Tr>
          ) : (
            data.map((item: any, i: number) => (
              <Tr key={i}>
                {columns.map((col: any) => (
                  <Td key={col.accessor}>{item[col.accessor]}</Td>
                ))}
              </Tr>
            ))
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
