import { Box, Flex } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

export const TransactionStatement = () => {
  const navigate = useNavigate();
  const downloadTransactionStatements = useReconStore(
    (state) => state.downloadTransactionStatements
  );
  const downloadTransactionStatementDetails = useReconStore(
    (state) => state.downloadTransactionStatementDetails
  )
  const columns = [
    { Header: "Reference Number", accessor: "reference_number" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Vat Amount", accessor: "vat_amount" },
    { Header: "Issue Date", accessor: "issue_date" },
    { Header: "Start Date", accessor: "start_date" },
    { Header: "End Date", accessor: "end_date" },
  ];

  const handleMoreClick = (item: any) => {
    console.log(item);
    navigate(
      `/admin/transaction-statements/${item.id}?refNum=${item.reference_number}`
    );
  };

  const handleDownloadClick = (item: any) => {

    console.log("see items", item)

    downloadTransactionStatementDetails({
        format: "excel",
        tch_statement_number: item.reference_number
    }).then((res: any) => {
        console.log(res)
        // place notification
    }).catch((error: any) => {
        console.log(error)
        // place notification
    })
  }

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          Transaction Statements
        </Box>
      </Flex>

      <Box py={6}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transaction_statements`}
          tokenKey="jwt_access_token"
          downloadFunc={downloadTransactionStatements}
          handleDownloadFunc={handleDownloadClick}
          handleMore={handleMoreClick}
          showExports={true}
          showDownloadButton={true}
        />
      </Box>
    </AdminAppShell>
  );
};
