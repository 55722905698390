import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Datatable from "../../components/DataTable";
import { useAuthStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import "dayjs/locale/en";
import { generateRandomUUID } from "../../utils";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  route_code: Yup.number().required("Route Code must be a numeric value"),
  enabled: Yup.boolean().required("Enabled is required"),
  start_loc: Yup.string().required("Start Location is required"),
  end_loc: Yup.string().required("End Location is required"),
  amount: Yup.number()
    .typeError("Amount must be a numeric value")
    .positive("Amount must be greater than 0") // Ensures value is greater than 0
    .required("Amount is required"),
  type: Yup.string().required("Type is required"),
  pto_id: Yup.string().required("Operator is required"),
  code: Yup.number()
    .typeError("Tariff Code must be a numeric value")
    .positive("Tariff Code must be greater than 0") // Ensures value is > 0
    .integer("Tariff Code must be an integer") // Ensures no decimal values
    .max(99999999, "Tariff Code must be 8 digits or less") // Restrict max digits
    .required("Tariff Code is required"),
  validity: Yup.date()
    .nullable() // Allow null if the field is optional
    .transform((value, originalValue) => {
      // If the original value is empty or invalid, set it to null
      return originalValue === "" || isNaN(new Date(originalValue).getTime())
        ? null
        : value;
    })
    .typeError("Please provide a valid date in the format YYYY-MM-DD.") // Friendly error for invalid dates
    .test(
      "future-date",
      "The validity date cannot be in the past.",
      (value: any) => {
        if (value === null) return true; // Allow null values
        const date = new Date(value); // Ensure a valid date is passed
        const today = new Date(); // Get current date
        today.setHours(0, 0, 0, 0); // Remove time part for comparison
        return !isNaN(date.getTime()) && date.getTime() >= today.getTime();
      }
    ),
});

const tariffTypes = [
  {
    id: 1,
    name: "Bus",
    value: "B",
  },
  {
    id: 2,
    name: "Minibus-Taxi",
    value: "T",
  },
  {
    id: 3,
    name: "Rail",
    value: "R",
  },
  {
    id: 4,
    name: "Air",
    value: "A",
  },
  {
    id: 5,
    name: "Sea",
    value: "S",
  },
];

const Tariffs = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchTariffs = useStore((state) => state.fetchTariffs);
  const createTariff = useStore((state) => state.createTariff);
  const removeTariff = useStore((state) => state.removeTariff);
  const updateTariff = useStore((state) => state.updateTariff);
  const posTariffs = useStore((state) => state.tariffs);
  const isLoading = useStore((state) => state.loading);

  const fetchOperators = useStore((state) => state.fetchOperators);
  const posOperators = useStore((state) => state.operators);
  const fetchLocations = useStore((state) => state.fetchLocations);
  const posLocations = useStore((state) => state.locations);
  const postTariffSanral = useStore((state) => state.postTariffToSanral);
  const fetchRoutes = useStore((state) => state.fetchRoutes);
  const posRoutes = useStore((state) => state.routes);
  const generateAuth = useStore((state) => state.generateAuth);
  const posUser = useAuthStore((state) => state.user);

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [tariff, setTariff] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const [operatorRoutes, setOperatorRoutes] = useState<any[]>([]);
  const fetchOperatorRoutesInfo = useStore(
    (state) => state.fetchOperatorRoutesInfo
  );
  const [routeDetails, setRouteDetails] = useState<any>(null);
  const [routesFetched, setRoutesFetched] = useState(false);

  const initialValues = {
    route_code: "",
    code: 0,
    enabled: true,
    start_loc: "",
    end_loc: "",
    amount: 0,
    type: "",
    pto_id: "",
    validity: "",
    created_by: `${posUser?.first_name} ${posUser?.last_name}`,
  };

  useEffect(() => {
    fetchTariffs();
    fetchOperators();
    fetchLocations();
    fetchRoutes();
  }, [fetchTariffs, fetchOperators, fetchLocations, fetchRoutes]);

  const handleOperatorChange = async (
    operatorId: string,
    setFieldValue: any
  ) => {
    setFieldValue("pto_id", operatorId); // Update Formik field value

    if (operatorId) {
      try {
        const routes = await fetchOperatorRoutesInfo({
          operator_id: operatorId,
        }); // Fetch routes for selected operator
        setOperatorRoutes(routes || []); // Update state with routes or empty array
      } catch (error) {
        console.error("Error fetching routes:", error);
        setOperatorRoutes([]); // Clear routes in case of an error
      }
    } else {
      setOperatorRoutes([]); // Clear routes if no operator is selected
    }
  };

  const handleRouteChange = (routeCode: string, setFieldValue: any) => {
    setFieldValue("route_code", routeCode); // Update Formik field value

    // Find the selected route from operatorRoutes
    const selectedRoute = operatorRoutes.find(
      (route) => route.code === routeCode
    );

    if (selectedRoute) {
      setRouteDetails(selectedRoute); // Update the route details state

      // Automatically populate start and end location fields
      setFieldValue("start_loc", selectedRoute.start_loc || "");
      setFieldValue("end_loc", selectedRoute.end_loc || "");
    }
  };

  const handleSubmit = (vehicleDetails: any) => {
    // vehicleDetails.validity = vehicleDetails.validity.toISOString().split("T")[0] || {}

    // format the date

    vehicleDetails.external_sync_status = "";
    vehicleDetails.remarks = "";

    if (vehicleDetails?.validity !== "") {
      setLoading(true);
      createTariff(vehicleDetails).then((res: any) => {
        console.log("Tarrif rea", res);
        if (res?.code) {
          setLoading(false);
          onClose();
          fetchTariffs();
          toast({
            title: "Success",
            description: "Tariff was added successfully!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          setLoading(false);
        }
      });
    } else {
      const { validity, ...newVehicleDetails } = vehicleDetails;

      setLoading(true);
      createTariff(newVehicleDetails).then((res: any) => {
        console.log("Tarrif rea", res);
        if (res?.code) {
          setLoading(false);
          onClose();
          fetchTariffs();
          toast({
            title: "Success",
            description: "Tariff was added successfully!",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          setLoading(false);
        }
      });
    }
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);
    console.log("handle update submit", updatedData);

    updateTariff(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchTariffs();
        toast({
          title: "Success",
          description: "Tariff was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = async (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      route_code: item.route_code,
      code: item.code,
      enabled: item.enabled,
      start_loc: item.start_loc,
      end_loc: item.end_loc,
      amount: item.amount,
      type: item.type,
      pto_id: item.pto_id,
      validity: item.validity,
      external_sync_status: "",
      remarks: "",
      modified_by: `${posUser?.first_name} ${posUser?.last_name}`,
    };

    const date = dayjs(newPayloadFormat.validity);
    newPayloadFormat.validity = date.locale("en").format("YYYY-MM-DD");

    setTariff(newPayloadFormat);

    if (item.pto_id && !routesFetched) {
      setRoutesFetched(true);
      await fetchOperatorRoutesInfo({operator_id: item.pto_id}).then((res: any) => {
        if (res) {
          setRoutesFetched(false);
          setOperatorRoutes(res);
        }
      }
      );
    }

    if (tariff) {
      onOpen();
      console.log(tariff);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/tariffs/${item.id}`);
  };

  const handlePostingTariffToSanral = (item: any) => {
    generateAuth({
      license: process.env.REACT_APP_LICENSE,
      device_mac: process.env.REACT_APP_DEVICE_MAC,
    }).then((res: any) => {
      if (res) {
        if (res?.Response?.Status === "active") {
          const guid = generateRandomUUID();

          let currentDate = new Date();
          currentDate.setHours(currentDate.getHours() + 4);

          postTariffSanral({
            pto_code: process.env.REACT_APP_PTO_CODE,
            device_mac: process.env.REACT_APP_DEVICE_MAC,
            license: process.env.REACT_APP_LICENSE,
            auth: res?.Response?.Token,
            nonce: "u23f7ofwgq",
            authorization: "fairpayy",
            tariffPayload: {
              guid: guid,
              tariffs: [
                {
                  id: parseInt(item?.code),
                  activationDateTime: currentDate.toJSON(),
                  enabled: item?.enabled,
                  value: item?.amount * 100,
                },
              ],
            },
          })
            .then((res: any) => {
              if (res?.Response) {
                if (res?.Response?.returnCode === 0) {
                  let tariffPayload = {
                    id: item.id,
                    route_code: item.route_code,
                    code: item.code,
                    enabled: item.enabled,
                    start_loc: item.start_loc,
                    end_loc: item.end_loc,
                    amount: item.amount,
                    type: item.type,
                    pto_id: item.pto_id,
                    validity: item.validity,
                    external_sync_status: "success",
                    remarks: item.remarks,
                    modified_by: `${posUser?.first_name} ${posUser?.last_name}`,
                  };

                  updateTariff(tariffPayload).then((res: any) => {
                    if (res) {
                      fetchTariffs();
                      toast({
                        title: "Success",
                        description: "Tariff status was updated successfully!",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  });
                }
              }
            })
            .catch((err: any) => {
              toast({
                title: "Success",
                description: "Tariff status was not updated successfully!",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
        }
      }
    });
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Tariffs
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setTariff(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Tariff
        </Button>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          scrollBehavior="outside"
          size="xl"
          closeOnOverlayClick={false}
        >
          {overlay}
          <ModalContent>
            <ModalHeader>
              {tariff ? "Update Existing Tariff" : "Create a New Tariff"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={tariff ? tariff : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={tariff ? submitUpdate : handleSubmit}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <Flex
                      direction="row"
                      justifyContent="space-between"
                      gap={"1rem"}
                    >
                      <Stack w={"100%"} direction="column" spacing={8}>
                        <Field name="pto_id">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.pto_id && form.touched.pto_id
                              }
                            >
                              <FormLabel>
                                Operator <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                {...field}
                                placeholder="Select an operator"
                                onChange={(e) =>
                                  handleOperatorChange(
                                    e.target.value,
                                    setFieldValue
                                  )
                                } // Handle change
                              >
                                {posOperators &&
                                  posOperators.map(
                                    (item: any, index: number) => (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.pto_id &&
                                  form.touched.pto_id &&
                                  form.errors.pto_id}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="route_code">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.route_code &&
                                form.touched.route_code
                              }
                            >
                              <FormLabel>
                                Route Code{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                {...field}
                                placeholder={
                                  operatorRoutes.length
                                    ? "Select a Route"
                                    : "No routes available"
                                }
                                isDisabled={operatorRoutes.length === 0}
                                onChange={(e) =>
                                  handleRouteChange(
                                    e.target.value,
                                    setFieldValue
                                  )
                                }
                              >
                                {operatorRoutes.length > 0 && operatorRoutes.map(
                                  (route: any, index: number) => (
                                    <option key={index} value={route.code}>
                                      {route.code} - {route.name}
                                    </option>
                                  )
                                )}
                              </Select>

                              <FormErrorMessage>
                                {form.errors.route_code &&
                                  form.touched.route_code &&
                                  form.errors.route_code}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="start_loc">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.start_loc && form.touched.start_loc
                              }
                            >
                              <FormLabel>
                                Start Location{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                {...field}
                                placeholder="Select a Start Location"
                                type="start_loc"
                                id="start_loc"
                                isDisabled={true}
                              >
                                {posLocations &&
                                  posLocations.map(
                                    (item: any, index: number) => (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.start_loc &&
                                  form.touched.start_loc &&
                                  form.errors.start_loc}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="end_loc">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.end_loc && form.touched.end_loc
                              }
                            >
                              <FormLabel>
                                End Location{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Select
                                {...field}
                                placeholder="Select an End Location"
                                type="end_loc"
                                id="end_loc"
                                isDisabled={true}
                              >
                                {posLocations &&
                                  posLocations.map(
                                    (item: any, index: number) => (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.end_loc &&
                                  form.touched.end_loc &&
                                  form.errors.end_loc}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>

                      <Stack w={"100%"} direction="column" spacing={8}>
                        <Field name="code">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={form.errors.code && form.touched.code}
                            >
                              <FormLabel>
                                Tariff Code{" "}
                                <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input {...field} type="code" id="code" />
                              <FormErrorMessage>
                                {form.errors.code &&
                                  form.touched.code &&
                                  form.errors.code}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="type">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={form.errors.type && form.touched.type}
                            >
                              <FormLabel>
                                Type <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              {/* <Input {...field} type="type" id="type" /> */}

                              <Select
                                {...field}
                                placeholder="Select a tariff type"
                                type="type"
                                id="type"
                              >
                                {tariffTypes &&
                                  tariffTypes.map(
                                    (item: any, index: number) => (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    )
                                  )}
                              </Select>
                              <FormErrorMessage>
                                {form.errors.type &&
                                  form.touched.type &&
                                  form.errors.type}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="amount">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.amount && form.touched.amount
                              }
                            >
                              <FormLabel>
                                Amount <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input {...field} type="amount" id="amount" />
                              <FormErrorMessage>
                                {form.errors.amount &&
                                  form.touched.amount &&
                                  form.errors.amount}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="validity">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.validity && form.touched.validity
                              }
                            >
                              <FormLabel htmlFor="validity">
                                Validity <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Input
                                {...field}
                                id="validity"
                                type="date" // Chakra UI Input with type="date"
                                value={field.value || ""} // Ensure it's compatible with Formik
                                min={new Date().toISOString().split("T")[0]}
                                onChange={(e) => {
                                  form.setFieldValue(
                                    field.name,
                                    e.target.value
                                  ); // Update Formik value
                                }}
                              />
                              <FormErrorMessage>
                                {form.errors.validity &&
                                  form.touched.validity &&
                                  form.errors.validity}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>

                        <Field name="enabled">
                          {({ field, form }: any) => (
                            <FormControl
                              isInvalid={
                                form.errors.enabled && form.touched.enabled
                              }
                            >
                              <FormLabel>
                                Enabled <span style={{ color: "red" }}>*</span>
                              </FormLabel>
                              <Checkbox
                                isChecked={field.value}
                                {...field}
                                colorScheme="teal"
                                size="lg"
                                iconColor="red.500"
                                type="enabled"
                                id="enabled"
                              >
                                Enabled
                              </Checkbox>
                              <FormErrorMessage>
                                {form.errors.enabled &&
                                  form.touched.enabled &&
                                  form.errors.enabled}
                              </FormErrorMessage>
                            </FormControl>
                          )}
                        </Field>
                      </Stack>
                    </Flex>

                    <Stack direction="row" spacing={4} marginY="1rem">
                      <Button
                        width="100%"
                        colorScheme="teal"
                        variant="solid"
                        type="submit"
                      >
                        {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              header: "Route Name",
              accessorKey: "route_name",
            },
            {
              header: "Route Code",
              accessorKey: "route_code",
            },
            {
              header: " Tariff Code",
              accessorKey: "code",
            },
            {
              header: "Enabled",
              accessorKey: "enabled",
            },
            // {
            //   Header: "Start Location",
            //   accessorKey: "start_loc",
            // },
            // {
            //   Header: "End Location",
            //   accessorKey: "end_loc",
            // },
            {
              header: "Amount",
              accessorKey: "amount",
            },
            {
              header: "Type",
              accessorKey: "type",
              cell: ({ row }: any) => {
                const actualType = tariffTypes.find((item) => {
                  return item.id === parseInt(row.original.type);
                });
                return actualType?.name || "Unknown Type";
              },
            },
            {
              Header: "Validity",
              accessorKey: "validity",
            },
          ]}
          data={posTariffs}
          dataTableHeading={"Tariffs"}
          handleDelete={removeTariff}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          handlePostTariffToSanral={handlePostingTariffToSanral}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Tariffs;
