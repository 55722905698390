import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { DatePicker } from "antd";
import { useState } from "react";
import moment from "moment";
import _ from "lodash";

export const FilterByDate = ({
  isOpen,
  onClose,
  dateFilterTitle,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  handleDateFilterAction,
  handleDateRangeStatusFilter
}: any) => {
  const handleFromDateChange = (date: any) => {
    console.log("From Date:", date);
    setFromDate(date);
  };

  const handleToDateChange = (date: any) => {
    console.log("To Date:", date);
    setToDate(date);
  };

  const handleDateFilter = () => {
    if (!_.isEmpty(fromDate) && !_.isEmpty(toDate)) {
      handleDateFilterAction({fromDate, toDate})
      handleDateRangeStatusFilter("0")
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>Filtering {dateFilterTitle} Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl marginY={"4"}>
              <FormLabel>From</FormLabel>
              <DatePicker
                onChange={handleFromDateChange}
                format="YYYY-MM-DD" // Format the date as you like
                style={{ width: "100%", fontSize: "1rem" }} // Adjust width to match Chakra UI inputs
              />
            </FormControl>

            <FormControl marginY={"4"}>
              <FormLabel>To</FormLabel>
              <DatePicker
                onChange={handleToDateChange}
                format="YYYY-MM-DD" // Format the date as you like
                style={{ width: "100%", fontSize: "1rem" }} // Adjust width to match Chakra UI inputs
              />
            </FormControl>

            <Button colorScheme={"green"} onClick={() => handleDateFilter()} w={'full'}>
              Submit
            </Button>
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
};
