import React, { useState } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Box, Button, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, SimpleGrid, Stack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../components/DataTableThree";
import { useReconStore } from "../../hooks";
import dayjs from "dayjs";
import DataTableFour from "../../components/DataTableFour";

export const TransactionMao = () => {
  const toast = useToast();
  const downloadTansactionStatusReport = useReconStore(
    (state) => state.downloadTansactionStatusReport
  );

  const columns = [
    {
      label: "Transaction Date Time",
      accessor: "transaction_datetime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { label: "Pan ID", accessor: "pan_id" },
    { label: "Status", accessor: "status" },
    { label: "Status Meaning", accessor: "status_meaning" },
    { label: "Financial ID", accessor: "financial_id" },
    { label: "Amount", accessor: "amount" },
    { label: "Total Amount", accessor: "total_amount" },
    { label: "Nominal Amount", accessor: "nominal_amount" },
    { label: "Invoice Financial ID", accessor: "inv_fin_id" },
    // { label: "Invoice Ref Number", accessor: "invoice_ref_number" },
    {
      label: "Financial Transaction Concept",
      accessor: "fin_transaction_concept",
    },
    {
      label: "Invoice Financial Concept",
      accessor: "invoicefin_transaction_concept",
    },
    { label: "Invoice Reference", accessor: "invreference" },
    { label: "GUID", accessor: "guid" },
    { label: "TXN GUID", accessor: "txn_guid" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [startDate, setStartDate] = useState<string>(""); // Moved here
  const [endDate, setEndDate] = useState<string>("");

  // New state variables for export functionality
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportType, setExportType] = useState<"full" | "filtered" | null>(
    null
  );
  const [exportFormat, setExportFormat] = useState<"csv" | "excel">("csv");
  const [modalStartDate, setModalStartDate] = useState<string>("");
  const [modalEndDate, setModalEndDate] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState(false);

  const [selectedFields, setSelectedFields] = useState<string[]>([
    "transaction_datetime",
    "pan_id",
    "status",
    "total_amount",
    "status_meaning",
    "financial_id",
    "amount",
    "nominal_amount",
    "inv_fin_id",
    "fin_transaction_concept",
    "invoicefin_transaction_concept",
    "invreference",
    "guid",
    "txn_guid",
  ]);

  const relatedColumns = [
    {
      Header: "Transaction Date Time",
      accessor: "transaction_datetime",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { Header: "Pan ID", accessor: "pan_id" },
    { Header: "Status", accessor: "status" },
    { Header: "Status Meaning", accessor: "status_meaning" },
    { Header: "Financial ID", accessor: "financial_id" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Nominal Amount", accessor: "nominal_amount" },
    { Header: "Invoice Financial ID", accessor: "inv_fin_id" },
    // { Header: "Invoice Ref Number", accessor: "invoice_ref_number" },
    {
      Header: "Financial Transaction Concept",
      accessor: "fin_transaction_concept",
    },
    {
      Header: "Invoice Financial Concept",
      accessor: "invoicefin_transaction_concept",
    },
    { Header: "Invoice Reference", accessor: "invreference" },
    { Header: "GUID", accessor: "guid" },
    { Header: "TXN GUID", accessor: "txn_guid" },
  ];

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadTansactionStatusReport({
        format,
        search,
        startDate,
        endDate,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleExportMenuClick = (type: "full" | "filtered") => {
    setExportType(type);

    if (type === "filtered") {
      // Optionally, pre-fill date ranges if they are managed in a shared state or store
      // For simplicity, we'll leave them empty and let the user input in the modal
      setModalStartDate(startDate);
      setModalEndDate(endDate);
    } else {
      // Reset modal dates for full export
      setModalStartDate("");
      setModalEndDate("");
    }

    setExportFormat("csv"); // Default format
    setIsExportModalOpen(true);
  };

   const handleExportDownload = async () => {
      if (!exportType) {
        toast({
          title: "Export Type Missing",
          description: "Please select an export type.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
  
      // Validate date range if exportType is 'filtered'
      if (exportType === "filtered") {
        if (
          modalStartDate &&
          modalEndDate &&
          new Date(modalStartDate) > new Date(modalEndDate)
        ) {
          toast({
            title: "Invalid Date Range",
            description: "Start date cannot be after end date.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }
  
      setIsDownloading(true);
  
      // Prepare download parameters
      const downloadDetails = {
        format: exportFormat,
        fields: selectedFields.join(","),
        search: undefined, // You can include search if needed
        startDate:
          exportType === "filtered" && modalStartDate
            ? dayjs(modalStartDate).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
        endDate:
          exportType === "filtered" && modalEndDate
            ? dayjs(modalEndDate).format("YYYY-MM-DD HH:mm:ss")
            : undefined,
      };
  
      try {
        if (exportType === "full") {
          await downloadTansactionStatusReport(downloadDetails);
        } else if (exportType === "filtered") {
          await downloadTansactionStatusReport(downloadDetails);
        }
        // Close the modal after successful download
        setIsExportModalOpen(false);
      } catch (error: any) {
        // Errors are already handled in the download functions
        setIsExportModalOpen(false);
      } finally {
        setIsDownloading(false);
      }
    };

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Transaction Statuses
          </Box>
        </Flex>
      </Flex>

      <Box py={4}>
        <DataTableFour
          allFields={columns}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transaction/report`}
          tokenKey="jwt_access_token"
          downloadFunc={() => {}}
          handleDownloadFunc={handleDownloadFunc}
          showActions={false}
          startDate={startDate} // Pass startDate
          endDate={endDate} // Pass endDate
          setStartDate={setStartDate} // Pass setStartDate
          setEndDate={setEndDate} // Pass setEndDate
          showDownloadButton={true}
          onExport={handleExportMenuClick}
        />
      </Box>

       {/* New Export Modal */}
       <Modal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        size="2xl"
        isCentered
        scrollBehavior="outside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Transactions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {/* Summary of Selected Filters */}
              <Box>
                <Text fontWeight="bold" mb={2}>
                  Export Summary:
                </Text>
                {/* <Text>
                  Selected Fields: {selectedFields.join(", ") || "All"}
                </Text> */}
                {exportType === "filtered" && (
                  <>
                    <Text>Start Date: {modalStartDate || "Not specified"}</Text>
                    <Text>End Date: {modalEndDate || "Not specified"}</Text>
                  </>
                )}
              </Box>

              {/* Export Type Selection */}
              <FormControl>
                <FormLabel fontWeight={'bold'}>Select Columns to Include</FormLabel>
                <CheckboxGroup
                  value={selectedFields}
                  onChange={(vals) => setSelectedFields(vals as string[])}
                >
                  <Stack spacing={2}>
                    {/* Use SimpleGrid to arrange fields in multiple columns */}
                    <SimpleGrid columns={[2, 3, 4]} spacing={2}>
                      {columns.map((field: any) => (
                        <Checkbox key={field.accessor} value={field.accessor}>
                          {field.label}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              {/* Date Range Selection for 'filtered' Export */}
              {exportType === "filtered" && (
                <Box>
                  <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <Input
                      type="date"
                      value={modalStartDate}
                      onChange={(e) => setModalStartDate(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>End Date</FormLabel>
                    <Input
                      type="date"
                      value={modalEndDate}
                      onChange={(e) => setModalEndDate(e.target.value)}
                    />
                  </FormControl>
                </Box>
              )}

              {/* Format Selection */}
              <FormControl as="fieldset">
                <FormLabel as="legend">Select Download Format</FormLabel>
                <RadioGroup
                  onChange={(value) =>
                    setExportFormat(value as "csv" | "excel")
                  }
                  value={exportFormat}
                >
                  <Stack direction="row">
                    <Radio value="csv">CSV</Radio>
                    <Radio value="excel">Excel</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsExportModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleExportDownload}
              isLoading={isDownloading}
              loadingText="Downloading"
            >
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminAppShell>
  );
};
