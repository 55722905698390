import { create, SetState } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import _ from "lodash";
import toast from "react-hot-toast";
import axios from 'axios'

const BASEURL = process.env.REACT_APP_BASE_URL;
// const BASEURL = "http://localhost:8000/api/v1";

export const useReconStore = create<any>()(
  devtools((set: SetState<any>) => ({
    processingFeeList: [],

    downloadProcessingFeeData: async (format: string) => {
        const BASEURL = process.env.REACT_APP_BASE_URL;

        console.log("BASEURL", BASEURL, format);
      
        try {
          const response = await axios.get(`${BASEURL}/detail_invoice_financial_statements/processing-fee/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

          console.log("response", response);

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `processing_fee_data.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadExceptionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/master_exceptions/download_detailed_exceptions`, {
            responseType: "blob",
            params: { 
              mst_id: downloadDetails.mstId,
              id: downloadDetails.id,
              file_type: downloadDetails.fileType,
             },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `ExceptionReport.${downloadDetails.fileType === "excel" ? "xlsx" : downloadDetails.fileType}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    }, 

    downloadFinancialTransactionStatement: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statements/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Financial Transaction Statement.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTransactionStatement: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statuses/download/data`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Transaction Statuses.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTransactionStatements: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statements/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Transaction Statements.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTopUpTransactionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
      try {
        const response = await axios.get(`${BASEURL}/master_topup_recon/recon-detail/download`, {
          responseType: "blob",
          params: { format: downloadDetails.format, top_up_date: downloadDetails.top_up_date },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Top Up Transaction Report.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },

    downloadTransactionMapReport: async (downloadDetails: any) => {

      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("downloadDetails", downloadDetails);

      try {
        const response = await axios.get(`${BASEURL}/transaction/report/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format},
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Transactions Report.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },

    downloadPTOTripDetails: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
      try {
        const response = await axios.get(`${BASEURL}/operators/${downloadDetails.id}/trip-details/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `PTO Trip Details.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },
    downloadTransactionExceptionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("downloadDetails", downloadDetails);
      
      try {
        const response = await axios.get(`${BASEURL}/transactions-exceptions/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Transaction Exceptions.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },
    downloadTransactionStatementDetails: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
    
      try {
        const response = await axios.get(
          // `${BASEURL}/transaction-statements/${downloadDetails.reference_number}/download-details`,
          `${BASEURL}/transaction_statements/${downloadDetails.tch_statement_number}/download-details`,
          {
            responseType: "blob",
            params: { 
              file_format: downloadDetails.format,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          }
        );
    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
    
        let extension = "excel";
    
        if (downloadDetails.format === "excel") {
          extension = "xlsx";
        } else if (downloadDetails.format === "csv") {
          extension = "zip"; // Since CSV files are returned in a ZIP archive
        } else if (downloadDetails.format === "pdf") {
          extension = "pdf";
        } else {
          extension = downloadDetails.format;
        }
    
        link.setAttribute(
          "download",
          `TransactionStatementDetails_${downloadDetails.tch_statement_number}.${extension}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    }
    
  }))
);
