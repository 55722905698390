import { create, SetState } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import _ from "lodash";
import toast from "react-hot-toast";
import axios from 'axios'

const BASEURL = process.env.REACT_APP_BASE_URL;
// const BASEURL = "http://localhost:8000/api/v1";

export const useReconStore = create<any>()(
  devtools((set: SetState<any>) => ({
    processingFeeList: [],

    downloadProcessingFeeData: async (format: string) => {
        const BASEURL = process.env.REACT_APP_BASE_URL;

        console.log("BASEURL", BASEURL, format);
      
        try {
          const response = await axios.get(`${BASEURL}/detail_invoice_financial_statements/processing-fee/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

          console.log("response", response);

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `processing_fee_data.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadExceptionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("downloadDetails", downloadDetails);
      
        try {
          const response = await axios.get(`${BASEURL}/master_exceptions/download_detailed_exceptions`, {
            responseType: "blob",
            params: { 
              mst_id: downloadDetails.details.id,
              id: downloadDetails.details.id,
              file_type: downloadDetails.format,
             },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${downloadDetails.details.file_name.split('.')[0]}.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    }, 

    downloadFinancialTransactionStatement: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("BASEURL", BASEURL, format);
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statements/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Financial Transaction Statement.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTransactionStatement: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statuses/download/data`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Transaction Statuses.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTransactionStatements: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("BASEURL", BASEURL, format);
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statements/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Transaction Statements.${format.format === "excel" ? "xlsx" : format.format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTopUpTransactionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("downloadDetails", downloadDetails);
      
      try {
        const response = await axios.get(`${BASEURL}/master_topup_recon/recon-detail/download`, {
          responseType: "blob",
          params: { format: downloadDetails.format, top_up_date: downloadDetails.details.file_topup_date },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${downloadDetails.details.file_name.split('.')[0]}.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },

    downloadTransactionMapReport: async (downloadDetails: any) => {

      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("downloadDetails", downloadDetails);

      try {
        const response = await axios.get(`${BASEURL}/transaction/report/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format},
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Transactions Report.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },

    downloadPTOTripDetails: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
      try {
        const response = await axios.get(`${BASEURL}/operators/${downloadDetails.id}/trip-details/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `PTO Trip Details.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },
    downloadTransactionExceptionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;

      console.log("downloadDetails", downloadDetails);
      
      try {
        const response = await axios.get(`${BASEURL}/transactions-exceptions/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Transaction Exceptions.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },
    downloadTransactionStatementDetails: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
    
      try {
        const response = await axios.get(
          // `${BASEURL}/transaction-statements/${downloadDetails.reference_number}/download-details`,
          `${BASEURL}/transaction_statements/${downloadDetails.details.reference_number}/download-details`,
          {
            responseType: "blob",
            params: { 
              file_format: downloadDetails.format,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          }
        );
    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
    
        let extension = "excel";
    
        if (downloadDetails.format === "excel") {
          extension = "xlsx";
        } else if (downloadDetails.format === "csv") {
          extension = "zip"; // Since CSV files are returned in a ZIP archive
        } else if (downloadDetails.format === "pdf") {
          extension = "pdf";
        } else {
          extension = downloadDetails.format;
        }
    
        link.setAttribute(
          "download",
          `TransactionStatementDetails_${downloadDetails.details.reference_number}.${extension}`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },
    downloadLatestPanRecords: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;

        const response = await axios.get(`${BASEURL}/validation-list/latest-pan-records/export`, {
          responseType: "blob",
          params: {
            format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";

        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link to trigger the download
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `latest_pan_records.${extension}`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading latest PAN records:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadLatestPanRecord: async (downloadDetails: {
      details: any; // Unique identifier for the PAN record
      format: "csv" | "excel";
    }) => {
      try {

        console.log("downloadDetails", downloadDetails);
        const { details, format } = downloadDetails;

        const response = await axios.get(
          `${BASEURL}/validation-list/records-by-pan/${details.pan_id}/export`, // Adjust the endpoint as per your API
          {
            responseType: "blob",
            params: {
              format,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          }
        );

        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";

        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link to trigger the download
        const link: any = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `latest_pan_record_${details.pan_id}.${extension}`);
        document.body.appendChild(link);
        link.click();

        // Clean up
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading latest PAN record:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadTrips: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/trips/export`, {
          responseType: "blob",
          params: {
            format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `trips.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading trips:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadLatestTransactionPanRecords: async (downloadDetails: {
      format: "csv" | "excel";
      fields?: string;
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {

        console.log("downloadDetails", downloadDetails);

        const { format, fields, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(
          `${BASEURL}/transactions/latest-transaction-pan-records/export`,
          {
            responseType: "blob",
            params: {
              format,
              fields: fields || undefined,
              search: search || undefined,
              startDate: startDate || undefined,
              endDate: endDate || undefined,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          }
        );
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Extract filename from Content-Disposition header if available
        const contentDisposition = response.headers["content-disposition"];
        let filename = `latest_transaction_pan_records_with_selected_fields.${extension}`;
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match && match[1]) {
            filename = match[1];
          }
        }
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading latest transaction PAN records:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadAllTransactionPanRecords: async (downloadDetails: {
      format: "csv" | "excel";
      fields?: string;
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {

        console.log("downloadDetails", downloadDetails);

        const { format, fields, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(
          `${BASEURL}/transactions/transactions/export`,
          {
            responseType: "blob",
            params: {
              format,
              fields: fields || undefined,
              search: search || undefined,
              startDate: startDate || undefined,
              endDate: endDate || undefined,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          }
        );
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Extract filename from Content-Disposition header if available
        const contentDisposition = response.headers["content-disposition"];
        let filename = `all_transaction_pan_records_with_selected_fields.${extension}`;
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?(.+)"?/);
          if (match && match[1]) {
            filename = match[1];
          }
        }
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading latest transaction PAN records:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadTansactionStatusReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/transaction/report/download`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transaction-status-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Transaction Status Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadTansactionStatementReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/transaction_statements/download`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transaction-statement-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Transaction Status Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadFinancialTransactionStatementReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
      tch_statement_number?: any;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/detail_financial_statements/export`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            tch_statement_number: downloadDetails.tch_statement_number || undefined
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `detailed-financial-statement-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadInvoiceTransactionStatementReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
      tch_statement_number?: any;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/detail_invoice_statements/export`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            tch_statement_number: downloadDetails.tch_statement_number || undefined
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `detailed-invoice-statement-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadTransitTransactionStatementReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
      tch_statement_number?: any;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/detail_transit_transaction/export`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            tch_statement_number: downloadDetails.tch_statement_number || undefined
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `detailed-transit-transactions-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadDetailInvoiceFinancialTransactionStatementReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
      tch_statement_number?: any;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/detail_invoice_financial_statements/export`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            tch_statement_number: downloadDetails.tch_statement_number || undefined
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `detailed-invoice-financial-statements-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadProcessingFeeStatementReport: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/detail_invoice_financial_statements/processing-fee/download`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `processing-fee-statements-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadTransactionExceptionReportFile: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
    }) => {
      try {
        const { format, search, startDate, endDate } = downloadDetails;
    
        const response = await axios.get(`${BASEURL}/transactions-exceptions/download`, {
          timeout: 60000,
          responseType: "blob",
          params: {
            file_format: format,
            search: search || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            
          },
        });
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        // Create a URL for the file
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transaction-exception-list-report.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading Report:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },

    downloadTransactionsByPanIdFile: async (downloadDetails: {
      format: "csv" | "excel";
      search?: string;
      startDate?: string;
      endDate?: string;
      details: any;
    }) => {
      try {

        console.log("downloadDetails", downloadDetails);
        const { details, format, search, startDate, endDate } = downloadDetails;
    
        // Determine the file extension
        const extension = format === "excel" ? "xlsx" : "csv";
    
        const response = await axios.get(
          `${BASEURL}/transactions/by-pan-id/${details.pan_id}/export`, 
          {
            timeout: 60000,
            responseType: "blob",
            params: {
              format: format,
              search: search || undefined,
              startDate: startDate || undefined,
              endDate: endDate || undefined,
            },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          }
        );
    
        // Create a URL for the returned blob
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `transactions_by_pan_id_${details.pan_id}.${extension}`);
        document.body.appendChild(link);
        link.click();
    
        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error: any) {
        console.error("Error downloading file:", error);
        throw error; // Propagate the error to handle it in the frontend
      }
    },
    
  }))
);
