import React, { useState, useEffect, useRef } from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  useOutsideClick,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const DeviceDropdown = ({ devices, value, onChange }: any) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  
  const filteredDevices = devices.filter((device: any) =>
    device.serialno.toLowerCase().includes(searchTerm.toLowerCase()) ||
    device.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const selectedDevice = devices.find((device: any) => device.mac_address === value);

  useOutsideClick({
    ref: menuRef,
    handler: () => setIsOpen(false),
  });

  const handleSelect = (device: any) => {
    onChange({ target: { value: device.mac_address } });
    setIsOpen(false);
  };

  return (
    <FormControl>
      <FormLabel>Device</FormLabel>
      <Box ref={menuRef} position="relative" width="100%">
        <Menu isOpen={isOpen} onClose={() => setIsOpen(false)} matchWidth>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            onClick={() => setIsOpen(!isOpen)}
            width="100%"
            textAlign="left"
          >
            {selectedDevice ? `${selectedDevice.name} - ${selectedDevice.serialno}` : 'Select a device'}
          </MenuButton>
          <MenuList maxH="300px" overflowY="auto" width="100%">
            <Box px={4} py={2} width="100%">
              <Input
                placeholder="Search devices..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                size="sm"
                width="100%"
              />
            </Box>
            {filteredDevices.length > 0 ? (
              filteredDevices.map((device: any) => (
                <MenuItem
                  key={device.mac_address}
                  onClick={() => handleSelect(device)}
                  bg={value === device.mac_address ? 'gray.100' : undefined}
                  width="100%"
                >
                  <Box width="100%">
                    <Text fontWeight="medium">
                      {device.name} - {device.serialno}
                    </Text>
                    {device.is_licensed && (
                      <Text fontSize="sm" color="gray.500">
                        (Licensed)
                      </Text>
                    )}
                  </Box>
                </MenuItem>
              ))
            ) : (
              <Box p={4}>
                <Text color="gray.500">No devices found</Text>
              </Box>
            )}
          </MenuList>
        </Menu>
      </Box>
    </FormControl>
  );
};

export default DeviceDropdown;