import {
  Box,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  CarTaxiFrontIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  LogOutIcon,
  PcCaseIcon,
  SplitIcon,
  Users2Icon,
} from "lucide-react";
import { useStore } from "../../hooks";
import "../../index.css";
import FaipayLogo from "../../assets/FairPayLogo.png";
import _ from "lodash";

function SideBarNav() {
  const [isOpen, setIsOpen] = useState(true);
  const [expanded, setExpanded] = useState<any>({}); // Tracks which items are expanded
  const navigate = useNavigate();

  const signOut = useStore((state) => state.signOutUser);
  const posUser = useStore((state) => state.user);

  const logOut = () => {
    signOut();
    navigate("/login");
  };

  const ROUTES = [
    {
      id: 2,
      title: "ROLES AND PERMISSION",
      icon: Users2Icon,
      subItems: [
        {
          title: "ROLES",
          path: "/admin/operators/add",
        },
        {
          title: "PERMISSIONS",
          path: "/admin/operators/add",
        },
      ],
    },
    {
      id: 3,
      title: "OPERATOR MANAGEMENT",
      icon: Users2Icon,
      subItems: [
        { title: "Operators", path: "/admin/operators/add" },
        { title: "Locations", path: "/admin/operators/add" },
        { title: "Routes", path: "/admin/operators/add" },
        { title: "Tariffs", path: "/admin/operators/add" },
        { title: "Devices", path: "/admin/operators/add" },
        { title: "License", path: "/admin/operators/add" },
        { title: "Vehicles", path: "/admin/operators/add" },
        { title: "Drivers", path: "/admin/operators/add" },
      ],
    },
    {
      id: 4,
      title: "AGENT MANAGEMENT",
      icon: Users2Icon,
      subItems: [{ title: "Devices", path: "/admin/operators/add" }],
    },
    {
      id: 5,
      title: "TRANSACTIONS",
      icon: Users2Icon,
      subItems: [
        { title: "Transaction History", path: "/admin/operators/add" },
        { title: "Reporting", path: "/admin/operators/add" },
      ],
    },
    {
      id: 6,
      title: "VALIDATION LIST",
      icon: Users2Icon,
      subItems: [],
    },
    {
      id: 7,
      title: "CARD MANAGEMENT",
      icon: Users2Icon,
      subItems: [{ title: "Card Stock", path: "/admin/operators/add" }],
    },
    {
      id: 8,
      title: "CONFIGURATIONS",
      icon: Users2Icon,
      subItems: [
        { title: "Limits", path: "/admin/operators/add" },
        { title: "Rules", path: "/admin/operators/add" },
      ],
    },
  ];

  // Toggle the expanded state for a menu item
  const toggleExpansion = (id: any) => {
    setExpanded((prevState: any) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  return (
    <Stack
      borderRight="0.01rem solid #6c757d"
      initial={false}
      animate={isOpen ? "open" : "closed"}
      as={motion.nav}
      bg="primary.800"
      h="auto"
      w={isOpen ? "240px" : "82px"}
      px="10px"
      py="32px"
      justify="space-between"
      transition="all .4s ease-in-out"
      position="sticky"
      top="0"
    >
      <Stack align="flex-start" gap={6}>
        <HStack w="100%" justify="space-between">
          <Image src={FaipayLogo} alt="Fairpay logo" w={"220px"} />
        </HStack>

        <List w={"full"} spacing="16px" >
          {ROUTES.map((route) => (
            <React.Fragment key={route.id}>
              <ListItem
                role="group"
                transition="all .4s ease-in-out"
                width={'100%'}
                border={'3px'}
                px="4px"
                _hover={{
                  color: "#123E68",
                  bg: "#FCFCFD",
                  transition: "0.3s",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
                onClick={() => toggleExpansion(route.id)}
              >
                <Box display={'flex'} alignItems={'center'} gap={'2'}>
                  <Icon as={route.icon} />

                  <Flex justifyContent={'space-between'} alignItems={'start'} w={'100%'}>
                    <Box>
                        <Box fontWeight={600} fontSize="12px">{route.title} </Box>
                    </Box>
                    {route.subItems && route.subItems.length > 0 && (
                      <Icon
                        as={
                          expanded[route.id]
                            ? ChevronDownIcon
                            : ChevronRightIcon
                        }
                      />
                    )}
                  </Flex>
                </Box>
              </ListItem>
              {expanded[route.id] && (
                <VStack align="start" pl="40px">
                  {route.subItems.map((subItem, index) => (
                    <NavLink
                      key={index}
                      to={subItem.path}
                      className={({ isActive }) => (isActive ? `red` : ``)}
                    >
                      <Text fontSize="sm">{subItem.title}</Text>
                    </NavLink>
                  ))}
                </VStack>
              )}
            </React.Fragment>
          ))}
        </List>
      </Stack>
      <Stack spacing="24px" align="flex-start">
        <Stack>
          <List spacing="12px">
            <>
              <ListItem
                role="group"
                transition="ease 0.3s"
                py="8px"
                px="12px"
                onClick={() => console.log("heeeey me")}
                _hover={{
                  color: "#123E68",
                  bg: "#FCFCFD",
                  borderRadius: "6px",
                  transition: "0.3s",
                  cursor: "pointer",
                }}
              >
                <HStack spacing="24px" onClick={() => logOut()}>
                  <Icon as={LogOutIcon} />
                  <Box fontWeight={600} fontSize="14px">
                    LOG OUT
                  </Box>
                </HStack>
              </ListItem>
            </>
          </List>
        </Stack>
        <Divider />
        {/* <HStack spacing="12px">
          <Stack>
            <Text fontSize="14px">
              {!_.isEmpty(posUser) ? `${posUser.name}` : ``}
            </Text>
            <Text fontSize="14px">
              {!_.isEmpty(posUser) ? `${posUser.contact_email}` : ``}
            </Text>
          </Stack>
          <IconButton
            aria-label="loguout"
            variant="ghost"
            _hover={{ bg: "none" }}
            icon={<User />}
          />
        </HStack> */}
      </Stack>
    </Stack>
  );
}

export default SideBarNav;
