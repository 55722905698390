import { create, SetState } from "zustand";
import { devtools } from "zustand/middleware";
import toast from "react-hot-toast";
import { apiClient } from "../utils";

const BASEURL = process.env.REACT_APP_BASE_URL;
// const BASEURL = "http://localhost:8000/api/v1";

export const useStore = create<any>()(
  devtools((set: SetState<any>) => ({
    // OPERATORS MANAGEMENT
    operators: [],
    operator: null,
    operator_by_pto_code: null,
    operatorInfo: null,
    vehicleInfo: [],
    tariffInfo: [],
    routesInfo: [],
    driversInfo: [],

    fetchOperators: async () => {
      try {
        set({ loading: true });
        const operatorsUrl = `${BASEURL}/operators`;

        const response = await apiClient(operatorsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ operators: result });
          set({ loading: false });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createOperators: async (operatorDetails: any) => {
      try {
        const operatorsUrl = `${BASEURL}/operators`;

        const response = await apiClient(operatorsUrl, {
          method: "POST",
          body: JSON.stringify(operatorDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please contact support!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeOperator: async (operatorDetails: any) => {
      try {
        const operatorsUrl = `${BASEURL}/operators/${operatorDetails.id}`;

        const response = await apiClient(operatorsUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ operators: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(`${"An error occurred. Please try again!"}`, {
          //   duration: 5000,
          //   position: "top-center",
          //   style: { background: "#d00000", color: "#fff" },
          // });
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateOperator: async (operatorDetails: any) => {
      try {
        const operatorsUrl = `${BASEURL}/operators/${operatorDetails.id}`;
        const token = window.localStorage.getItem("jwt_access_token");

        const newPayload = {
          name: operatorDetails.name,
          code: operatorDetails.code,
          address: operatorDetails.address,
          contact_person: operatorDetails.contact_person,
          contact_number: operatorDetails.contact_number,
          contact_email: operatorDetails.contact_email,
          license_number: operatorDetails.license_number,
          total_vehicles: operatorDetails.total_vehicles,
          total_agents: operatorDetails.total_agents,
          enabled: operatorDetails.enabled,
          modified_by: operatorDetails.modified_by,
        };

        const response = await apiClient(operatorsUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleOperator: async (operatorDetails: any) => {
      try {
        const operatorsUrl = `${BASEURL}/operators/${operatorDetails.id}`;

        const response = await apiClient(operatorsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ operator: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleOperatorByPtoCode: async (operatorDetails: any) => {
      try {
        const operatorsUrl = `${BASEURL}/licenses/get_operator_by_pto_code/${operatorDetails.pto_code}`;

        const response = await apiClient(operatorsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ operator_by_pto_code: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchOperatorDeviceInfo: async (operatorDetails: any) => {
      try {
        const operatorUrl = `${BASEURL}/operators/${operatorDetails.operator_id}/devices-licenses`;

        const response = await apiClient(operatorUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ operatorInfo: result });
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchOperatorVehicleInfo: async (operatorDetails: any) => {
      const operatorUrl = `${BASEURL}/operators/${operatorDetails.operator_id}/vehicles`;

      const response = await apiClient(operatorUrl, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.json();
        set({ vehicleInfo: result });
        return result;
      } else {
        const result = await response.json();
        return result;
      }
    },

    fetchOperatorTariffInfo: async (operatorDetails: any) => {
      const operatorUrl = `${BASEURL}/operators/${operatorDetails.operator_id}/tariffs`;

      const response = await apiClient(operatorUrl, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.json();
        set({ tariffInfo: result });
        return result;
      } else {
        const result = await response.json();
        return result;
      }
    },

    fetchOperatorRoutesInfo: async (operatorDetails: any) => {
      const operatorUrl = `${BASEURL}/operators/${operatorDetails.operator_id}/routes`;

      const response = await apiClient(operatorUrl, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.json();
        set({ routesInfo: result });
        return result;
      } else {
        const result = await response.json();
        return result;
      }
    },

    fetchOperatorDriversInfo: async (operatorDetails: any) => {
      const operatorUrl = `${BASEURL}/operators/${operatorDetails.operator_id}/drivers`;

      const response = await apiClient(operatorUrl, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.json();
        set({ driversInfo: result });
        return result;
      } else {
        const result = await response.json();
        return result;
      }
    },

    // DEVICES MANAGEMENT
    devices: [],
    device: null,
    device_by_mac_address: null,
    deviceInfo: null,

    fetchDevices: async () => {
      try {
        set({ loading: true });
        const devicesUrl = `${BASEURL}/devices`;

        const response = await apiClient(devicesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ devices: result });
          // console.log("this is my response", result)
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createDevices: async (deviceDetails: any) => {
      try {
        const devicesUrl = `${BASEURL}/devices`;

        const response = await apiClient(devicesUrl, {
          method: "POST",
          body: JSON.stringify(deviceDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    removeDevices: async (deviceDetails: any) => {
      try {
        const devicesUrl = `${BASEURL}/devices/${deviceDetails?.id}`;

        const response = await apiClient(devicesUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ devices: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateDevices: async (deviceDetails: any) => {
      try {
        const devicesUrl = `${BASEURL}/devices/${deviceDetails.id}`;

        const newPayload = {
          name: deviceDetails.name,
          make: deviceDetails.make,
          model: deviceDetails.model,
          type: deviceDetails.type,
          mac_address: deviceDetails.mac_address,
          serialno: deviceDetails.serialno,
          enabled: deviceDetails.enabled,
        };

        const response = await apiClient(devicesUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleDevice: async (deviceDetails: any) => {
      try {
        const devicesUrl = `${BASEURL}/devices/${deviceDetails?.id}`;

        const response = await apiClient(devicesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ device: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleDeviceByMacAddress: async (deviceDetails: any) => {
      try {
        const devicesUrl = `${BASEURL}/licenses/get_device_by_mac_address/${deviceDetails?.device_mac_address}`;

        const response = await apiClient(devicesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ device_by_mac_address: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchDeviceOperatorInfo: async (deviceDetails: any) => {
      try {
        const deviceUrl = `${BASEURL}/devices/device/${deviceDetails.device_id}`;

        const response = await apiClient(deviceUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ deviceInfo: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    deleteDeviceVehicleMapping: async (deviceDetails: any) => {
      try {
        const deviceUrl = `${BASEURL}/vehicle-devices/${deviceDetails.device_id}/all`;

        const response = await apiClient(deviceUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";

          if (result.detail) {
            toast.success(errorMessage, {
              duration: 5000,
              position: "top-center",
            });
          } else {
            toast.error(errorMessage, {
              duration: 5000,
              position: "top-center",
              style: { background: "#d00000", color: "#fff" },
            });
          }
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
      }
    },

    checkIfDeviceIsMapped: async (deviceDetails: any) => {
      try {
        const deviceUrl = `${BASEURL}/vehicle-devices/device/${deviceDetails.device_id}/check-mapping`;

        const response = await apiClient(deviceUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";

          if (result.detail) {
            // toast.success(errorMessage, {
            //   duration: 5000,
            //   position: "top-center",
            // });

            console.log("this is the result", result.detail);
          } else {
            toast.error(errorMessage, {
              duration: 5000,
              position: "top-center",
              style: { background: "#d00000", color: "#fff" },
            });
          }
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
      }
    },

    checkIfVehicleIsMapped: async (vehicleDetails: any) => {
      try {
        const vehicleUrl = `${BASEURL}/vehicle-devices/vehicle/${vehicleDetails.vehicle_id}/check-validator-mapping`;

        const response = await apiClient(vehicleUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";

          if (result.detail) {
            // toast.success(errorMessage, {
            //   duration: 5000,
            //   position: "top-center",
            // });
            console.log("this is the result", result.detail);
          } else {
            toast.error(errorMessage, {
              duration: 5000,
              position: "top-center",
              style: { background: "#d00000", color: "#fff" },
            });
          }
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
      }
    },

    // LOCATIONS MANAGEMENT
    locations: [],
    location: null,
    fetchLocations: async () => {
      try {
        set({ loading: true });
        const locationsUrl = `${BASEURL}/locations`;

        const response = await apiClient(locationsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ locations: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createLocation: async (locationDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/locations`;

        const response = await apiClient(locationsUrl, {
          method: "POST",
          body: JSON.stringify(locationDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeLocation: async (locationDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/locations/${locationDetails.id}`;

        const response = await apiClient(locationsUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ locations: result });
          return result;
        } else {
          const result = await response.json();
          console.log("REEES", result);
          return result;
        }
      } catch (error) {
        set({ loading: false });
        console.log(error);
      }
    },

    updateLocation: async (locationDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/locations/${locationDetails.id}`;

        console.log("locationDetails", locationDetails);

        const newPayload = {
          name: locationDetails.name,
          longitude: locationDetails.longitude,
          latitude: locationDetails.latitude,
          type: locationDetails.type,
          facility: locationDetails.facility,
          enabled: locationDetails.enabled,
          location_id: locationDetails.location_id,
          external_sync_status: locationDetails.external_sync_status,
          remarks: locationDetails.remarks,
          modified_by: locationDetails.modified_by,
        };

        const response = await apiClient(locationsUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleLocation: async (locationDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/locations/${locationDetails.id}`;

        const response = await apiClient(locationsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ location: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    postLocationToSanral: async (locationDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/external-apis/locations/sanral?pto_code=${locationDetails?.pto_code}&device_mac=${locationDetails?.device_mac}&license=${locationDetails?.license}&auth=${locationDetails?.auth}&nonce=${locationDetails.nonce}&authorization=${locationDetails?.authorization}`;

        const response = await apiClient(locationsUrl, {
          method: "POST",
          body: JSON.stringify(locationDetails?.locationPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    generateAuth: async (authDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/external-apis/generate-auth/sanral?license=${authDetails?.license}&device_mac=${authDetails?.device_mac}&pto_code=${authDetails?.pto_code}`;

        const response = await apiClient(locationsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          console.log("this is my aith token", result);
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    // VEHICLES MANAGEMENT
    vehicles: [],
    vehicle: null,
    vehicleDevices: null,
    vehicleRoutes: null,

    fetchVehicles: async () => {
      try {
        set({ loading: true });
        const vehiclesUrl = `${BASEURL}/vehicles`;

        const response = await apiClient(vehiclesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ vehicles: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createVehicles: async (vehicleDetails: any) => {
      try {
        const vehiclesUrl = `${BASEURL}/vehicles`;

        const response = await apiClient(vehiclesUrl, {
          method: "POST",
          body: JSON.stringify(vehicleDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeVehicle: async (vehicleDetails: any) => {
      try {
        const vehiclesUrl = `${BASEURL}/vehicles/${vehicleDetails.id}`;

        const response = await apiClient(vehiclesUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          console.log("RESULTS FROM THE SERVER", result);
          set({ vehicles: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateVehicle: async (vehicleDetails: any) => {
      try {
        const vehiclesUrl = `${BASEURL}/vehicles/${vehicleDetails.id}`;

        const newPayload = {
          model: vehicleDetails.model,
          name: vehicleDetails.name,
          make: vehicleDetails.make,
          type: vehicleDetails.type,
          vehicle_number: vehicleDetails.vehicle_number,
          fuel_type: vehicleDetails.fuel_type,
          pto_id: vehicleDetails.pto_id,
          vehicle_capacity: vehicleDetails.vehicle_capacity,
          enabled: vehicleDetails.enabled,
          modified_by: vehicleDetails.modified_by,
        };

        const response = await apiClient(vehiclesUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleVehicle: async (vehicleDetails: any) => {
      try {
        const vehiclesUrl = `${BASEURL}/vehicles/${vehicleDetails.id}`;

        const response = await apiClient(vehiclesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ vehicle: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    // mapping vehicles to a device
    mapVehicleToDevice: async (vehicleDevicesDetails: any) => {
      try {
        const vehicleDevicesUrl = `${BASEURL}/vehicle-devices`;

        const response = await apiClient(vehicleDevicesUrl, {
          method: "POST",
          body: JSON.stringify(vehicleDevicesDetails),
        });

        if (response.ok) {
          const result = response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });

          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    // delete  vehicle to device mapping
    removeVehicleToDeviceMapping: async (vehicleDeviceDetails: any) => {
      try {
        const vehicleDeviceUrl = `${BASEURL}/vehicle-devices/${vehicleDeviceDetails?.id}/${vehicleDeviceDetails?.vehicle_id}/${vehicleDeviceDetails?.device_id}`;

        const response = await apiClient(vehicleDeviceUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeVehicleDeviceMap: async (vehicleDeviceDetails: any) => {
      try {
        const vehicleDeviceUrl = `${BASEURL}/vehicle-devices/${vehicleDeviceDetails.vehicle_id}/${vehicleDeviceDetails.device_id}`;

        const response = await apiClient(vehicleDeviceUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchVehicleDeviceRecord: async (vehicleDeviceDetails: any) => {
      try {
        const vehicleDeviceUrl = `${BASEURL}/vehicle-devices/${vehicleDeviceDetails?.vehicle_id}/${vehicleDeviceDetails?.device_id}`;

        const response = await apiClient(vehicleDeviceUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ vehicle: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    // mapping vehicles to a route
    mapVehicleToRoute: async (vehicleRouteDetails: any) => {
      try {
        const vehicleRoutesUrl = `${BASEURL}/vehicle-routes`;

        const response = await apiClient(vehicleRoutesUrl, {
          method: "POST",
          body: JSON.stringify(vehicleRouteDetails),
        });

        if (response.ok) {
          const result = response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    // delete  vehicle to device mapping
    removeVehicleToRouteMapping: async (vehicleRouteDetails: any) => {
      try {
        const vehicleRouteUrl = `${BASEURL}/vehicle-routes/${vehicleRouteDetails?.id}/${vehicleRouteDetails?.vehicle_id}/${vehicleRouteDetails?.route_id}`;

        const response = await apiClient(vehicleRouteUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeVehicleRouteMapping: async (vehicleRouteDetails: any) => {
      try {
        const vehicleRouteUrl = `${BASEURL}/vehicle-routes/${vehicleRouteDetails?.vehicle_id}/${vehicleRouteDetails?.route_id}`;

        const response = await apiClient(vehicleRouteUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchVehicleDevices: async (vehicleDetails: any) => {
      try {
        const vehicleDevicesUrl = `${BASEURL}/vehicles/${vehicleDetails?.id}/devices`;

        const response = await apiClient(vehicleDevicesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();

          console.log(result);
          set({ vehicleDevices: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchVehicleRoutes: async (vehicleDetails: any) => {
      try {
        const vehicleRoutesUrl = `${BASEURL}/vehicles/${vehicleDetails?.id}/routes`;

        const response = await apiClient(vehicleRoutesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();

          console.log(result);
          set({ vehicleRoutes: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    // TARIFFS MANAGEMENT
    tariffs: [],
    tariff: null,

    fetchTariffs: async () => {
      try {
        set({ loading: true });
        const tariffsUrl = `${BASEURL}/tariffs`;

        const response = await apiClient(tariffsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ tariffs: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createTariff: async (tariffDetails: any) => {
      try {
        const tariffsUrl = `${BASEURL}/tariffs`;

        const response = await apiClient(tariffsUrl, {
          method: "POST",
          body: JSON.stringify(tariffDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          console.log(result);
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeTariff: async (tariffDetails: any) => {
      try {
        const tariffsUrl = `${BASEURL}/tariffs/${tariffDetails.id}`;

        const response = await apiClient(tariffsUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ tariffs: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateTariff: async (tariffDetails: any) => {
      try {
        const tariffsUrl = `${BASEURL}/tariffs/${tariffDetails.id}`;

        const newPayload = {
          route_code: tariffDetails.route_code,
          code: tariffDetails.code,
          enabled: tariffDetails.enabled,
          start_loc: tariffDetails.start_loc,
          end_loc: tariffDetails.end_loc,
          amount: tariffDetails.amount,
          type: tariffDetails.type,
          pto_id: tariffDetails.pto_id,
          validity: tariffDetails.validity,
          external_sync_status: tariffDetails.external_sync_status,
          remarks: tariffDetails.remarks,
          modified_by: tariffDetails.modified_by,
        };

        const response = await apiClient(tariffsUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errormessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errormessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleTariff: async (tariffDetails: any) => {
      try {
        const tariffsUrl = `${BASEURL}/tariffs/${tariffDetails.id}`;

        const response = await apiClient(tariffsUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ tariff: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    postTariffToSanral: async (tariffDetails: any) => {
      try {
        const locationsUrl = `${BASEURL}/external-apis/tariffs/sanral?pto_code=${tariffDetails?.pto_code}&device_mac=${tariffDetails?.device_mac}&license=${tariffDetails?.license}&auth=${tariffDetails?.auth}&nonce=${tariffDetails.nonce}&authorization=${tariffDetails?.authorization}`;

        const response = await apiClient(locationsUrl, {
          method: "POST",
          body: JSON.stringify(tariffDetails?.tariffPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    // DRIVERS MANAGEMENT
    drivers: [],
    driver: null,

    fetchDrivers: async () => {
      try {
        set({ loading: true });
        const driversUrl = `${BASEURL}/users?user_type=driver`;

        const response = await apiClient(driversUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ drivers: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again.";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createDriver: async (driverDetails: any) => {
      try {
        const driversUrl = `${BASEURL}/users`;

        const response = await apiClient(driversUrl, {
          method: "POST",
          body: JSON.stringify(driverDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage =
            result.detail || "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeDriver: async (driverDetails: any) => {
      try {
        const driversUrl = `${BASEURL}/users/${driverDetails.id}`;

        const response = await apiClient(driversUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ drivers: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateDriver: async (driverDetails: any) => {
      try {
        const driversUrl = `${BASEURL}/users/${driverDetails.id}`;

        const newPayload = {
          name: driverDetails.name,
          national_id: driverDetails.national_id,
          user_type: driverDetails.user_type,
          contact_number: driverDetails.contact_number,
          contact_email: driverDetails.contact_email,
          address: driverDetails.address,
          gender: driverDetails.gender,
          photo_file: driverDetails.photo_file,
          id_doc_file: driverDetails.id_doc_file,
          user_code: driverDetails.user_code,
          enabled: driverDetails.enabled,
          pto_id: driverDetails.pto_id,
          modified_by: driverDetails.modified_by,
        };

        const response = await apiClient(driversUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.detail
          ? error.detail
          : "An error occurred. Please contact support!";
        console.log(error);
      }
    },

    fetchSingleDriver: async (driverDetails: any) => {
      try {
        const driversUrl = `${BASEURL}/users/${driverDetails.id}`;

        const response = await apiClient(driversUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ driver: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    resetDriverPassword: async (driverDetails: any) => {
      const driversUrl = `${BASEURL}/users/reset-password/${driverDetails.id}?new_password=${driverDetails.newPin}`;

      try {
        const response = await apiClient(driversUrl, {
          method: "PUT",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    // ROUTES MANAGEMENT
    routes: [],
    route: null,
    createdRoute: null,
    stops: [],
    stop: null,

    fetchRoutes: async () => {
      try {
        set({ loading: true });
        const routesUrl = `${BASEURL}/routes`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ routes: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createRoute: async (routeDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/routes`;

        const response = await apiClient(routesUrl, {
          method: "POST",
          body: JSON.stringify(routeDetails),
        });

        if (response.ok) {
          const result = await response.json();
          set({ createdRoute: result });
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeRoute: async (routeDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/routes/${routeDetails.id}`;

        const response = await apiClient(routesUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ routes: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateRoute: async (routeDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/routes/${routeDetails.id}`;

        const newPayload = {
          name: routeDetails.name,
          code: routeDetails.code,
          enabled: routeDetails.enabled,
          start_loc: routeDetails.start_loc,
          end_loc: routeDetails.end_loc,
          pto_id: routeDetails.pto_id,
          modified_by: routeDetails.modified_by,
        };

        const response = await apiClient(routesUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          // toast.error(errorMessage, {
          //   duration: 5000,
          //   position: "top-center",
          //   style: { background: "#d00000", color: "#fff" },
          // });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleRoute: async (routeDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/routes/${routeDetails.id}`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ route: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchStopsFromARoute: async (routeDetails: any) => {
      try {
        set({ loading: true });
        const routesUrl = `${BASEURL}/routes/stops/${routeDetails.id}`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ stops: result });
          set({ loading: false });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    // STOPS MANAGEMENT

    fetchStops: async () => {
      try {
        set({ loading: true });
        const routesUrl = `${BASEURL}/stops`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ stops: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          toast.error(`${result.detail}`, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error) {
        console.log(error);
        toast.error(`${"An error occurred. Please contact support!"}`, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createStop: async (stopDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/stops`;

        const response = await apiClient(routesUrl, {
          method: "POST",
          body: JSON.stringify(stopDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result: any = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    removeStop: async (stopDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/stops/${stopDetails.id}`;

        const response = await apiClient(routesUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ stops: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateStop: async (stopDetails: any) => {
      console.log("stopDetails", stopDetails);
      try {
        const routesUrl = `${BASEURL}/stops/${stopDetails.id}`;

        const newPayload = {
          location_seq: stopDetails.location_seq,
          route_direction: stopDetails.route_direction,
          enabled: stopDetails.enabled,
          route_id: stopDetails.route_id,
          location_id: stopDetails.location_id,
        };

        const response = await apiClient(routesUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    fetchSingleStop: async (stopDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/stops/${stopDetails.id}`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ stop: result });
          return result;
        } else {
          const result = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.error(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    // VALIDATION LIST MANAGEMENT
    validation_list_items: [],
    validation_list_item: null,
    fetchAllValidationListItems: async () => {
      try {
        set({ loading: true });
        const routesUrl = `${BASEURL}/validation-list`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ validation_list_items: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchSingleValidationListItem: async (validationListDetails: any) => {
      try {
        const routesUrl = `${BASEURL}/validation-list/record/${validationListDetails.id}`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ validation_list_item: result });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An erroR occured. Please try again!";
          toast.error(errorMessage, {
            duration: 5000,
          });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    generateLicense: async (generateLicenseDetails: any) => {
      try {
        console.log(generateLicenseDetails);
        const routesUrl = `${BASEURL}/external-apis/generate-license/${generateLicenseDetails?.pto_code}/${generateLicenseDetails?.device_mac}`;

        const response = await apiClient(routesUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          return result;
        } else {
          const result: any = await response.json();
          console.log("ERRRRRROR", result);
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    checkLicense: async (licenseDetails: any) => {
      try {
        const checkLicenseUrl = `${BASEURL}/licenses/check_license/${licenseDetails?.device_id}`;

        const response = await apiClient(checkLicenseUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          return result;
        } else {
          const result: any = await response.json();
          // const errorMessage = result.detail
          //   ? result.detail
          //   : "An error Occured. Please try again";
          // toast.error(errorMessage, {
          //   duration: 5000,
          // });
          // console.log("ERRRRRROR", result);
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    updateStatusToInactive: async (statusDetails: any) => {
      try {
        console.log(statusDetails);
        const changeToInactiveUrl = `${BASEURL}/licenses/change_license_status/${statusDetails?.pto_id}/${statusDetails?.device_id}`;

        const response = await apiClient(changeToInactiveUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          const errorMessage = result.detail
            ? result.detail
            : "An error occurred. Please check your fields and try again!";
          toast.custom(errorMessage, {
            duration: 5000,
            position: "top-center",
            style: { background: "#d00000", color: "#fff" },
          });
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        console.log(error);
      }
    },

    removelicense: async (licenseDetails: any) => {
      try {
        const removeLicenseUrl = `${BASEURL}/licenses/remove_license/${licenseDetails?.pto_id}/${licenseDetails?.device_id}`;

        const response = await apiClient(removeLicenseUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error: any) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    topUps: [],
    topUp: null,
    loadingTopUps: false,

    fetchTops: async () => {
      try {
        set({ loadingTopUps: true });
        const fetchTopUps = `${BASEURL}/top-ups/`;

        const response = await apiClient(fetchTopUps, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ topUps: result });
          set({ loadingTopUps: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loadingTopUps: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loadingTopUps: false });
      }
    },

    fetchTopUp: async (topupDetails: any) => {
      try {
        const fetchSingleTopup = `${BASEURL}/top-ups/${topupDetails?.id}`;

        const response = await apiClient(fetchSingleTopup, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ topUp: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchTopUpsByDate: async (topUpDetails: any) => {
      try {
        set({ loadingTopUps: false });
        const fetchTopUpsByDateURL = `${BASEURL}/top-ups/date-range-filter`;

        const response = await apiClient(fetchTopUpsByDateURL, {
          method: "POST",
          body: JSON.stringify(topUpDetails),
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          set({ topUps: result });
          set({ loadingTopUps: false });
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          set({ loadingTopUps: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loadingTopUps: false });
      }
    },

    // REPORTING STATE
    transactions: [],
    transaction: null,
    loadingTransactions: false,

    fetchPosTransactions: async () => {
      try {
        set({ loadingTransactions: true });
        const fetchTransactionsURL = `${BASEURL}/transactions/`;

        const response = await apiClient(fetchTransactionsURL, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          console.log("result", result);
          set({ transactions: result });
          set({ loadingTransactions: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loadingTransactions: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loadingTransactions: false });
      }
    },

    fetchSingleTransaction: async (transactionDetails: any) => {
      try {
        const fetchSingleTransaction = `${BASEURL}/transactions/${transactionDetails?.id}`;

        const response = await apiClient(fetchSingleTransaction, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ transaction: result });
          set({ loading: false });
          return result;
        } else {
          const result: any = await response.json();
          const errorMessage = result.detail
            ? result.detail
            : "An error occured. Please try again";
          toast.error(errorMessage, {
            duration: 5000,
          });
          set({ loading: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchTransactionsByDate: async (transactionDetails: any) => {
      try {
        set({ loadingTransactions: false });
        const fetchTopUpsByDateURL = `${BASEURL}/transactions/date-range-filter`;

        const response = await apiClient(fetchTopUpsByDateURL, {
          method: "POST",
          body: JSON.stringify(transactionDetails),
        });

        if (response.ok) {
          const result = await response.json();
          console.log(result);
          set({ transactions: result });
          set({ loadingTransactions: false });
          return result;
        } else {
          const result = await response.json();
          console.log(result);
          set({ loadingTransactions: false });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loadingTransactions: false });
      }
    },

    // TRANSACTION STATUS MANAGEMENT
    transaction_statuses: [],

    fetchAllTransactionStatuses: async () => {
      try {
        set({ loading: true });
        const transactionStatusURL = `${BASEURL}/transaction_statuses`;

        const response = await apiClient(transactionStatusURL, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ transaction_statuses: result });
          set({ loading: false });
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    // ROLES MANAHEMENT
    roles: [],
    role: null,

    fetchAllRoles: async () => {
      try {
        const roleUrl = `${BASEURL}/roles`;

        const response = await apiClient(roleUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ roles: result });
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    createNewRole: async (roleDetails: any) => {
      try {
        const createRoleUrl = `${BASEURL}/roles`;

        const response = await apiClient(createRoleUrl, {
          method: "POST",
          body: JSON.stringify(roleDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchSingleRole: async (roleDetails: any) => {
      try {
        const fetchSingleRoleUrl = `${BASEURL}/roles/${roleDetails?.id}`;

        const response = await apiClient(fetchSingleRoleUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ role: result });
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    updateRole: async (roleDetails: any) => {
      try {
        const fetchSingleRoleUrl = `${BASEURL}/roles/${roleDetails?.id}`;

        const newPayload = {
          title: roleDetails.title,
          description: roleDetails.description,
          is_active: roleDetails.is_active,
        };

        const response = await apiClient(fetchSingleRoleUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          set({ role: result });
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    deleteRole: async (roleDetails: any) => {
      try {
        const rolesUrl = `${BASEURL}/roles/${roleDetails?.id}`;

        const response = await apiClient(rolesUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ roles: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    internalUsers: [],
    internalUser: null,

    fetchAllUsers: async () => {
      try {
        const fetchUsersUrl = `${BASEURL}/internal-user`;

        const response = await apiClient(fetchUsersUrl, {
          method: "GET",
        });

        if (response.ok) {
          const result = await response.json();
          set({ internalUsers: result });
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    fetchSingleInternalUser: async (internalUseerDetails: any) => {
      const fetchSingleUserUrl = `${BASEURL}/internal-user/${internalUseerDetails.id}`;

      const response = await apiClient(fetchSingleUserUrl, {
        method: "GET",
      });

      if (response.ok) {
        const result = await response.json();
        set({ internalUser: result });
        return result;
      } else {
        const result = await response.json();
        return result;
      }
    },

    createInternalUser: async (internalUseerDetails: any) => {
      try {
        const createInternalUserUrl = `${BASEURL}/internal-user`;

        console.log(internalUseerDetails);

        const response = await apiClient(createInternalUserUrl, {
          method: "POST",
          body: JSON.stringify(internalUseerDetails),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error: any) {
        console.log(error);
        const errorMessage = error.message
          ? error.message
          : "An error occurred. Please contact support!";
        toast.error(errorMessage, {
          duration: 5000,
          position: "top-center",
          style: { background: "#d00000", color: "#fff" },
        });
        set({ loading: false });
      }
    },

    updateInternalUser: async (internalUserDetails: any) => {
      try {
        const updateInternalUserUrl = `${BASEURL}/internal-user/${internalUserDetails?.id}`;

        const newPayload = {
          first_name: internalUserDetails.first_name,
          last_name: internalUserDetails.last_name,
          email: internalUserDetails.email,
          is_active: internalUserDetails.is_active,
          role_id: internalUserDetails.role_id,
          updated_by: internalUserDetails.updated_by,
        };

        const response = await apiClient(updateInternalUserUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error) {}
    },

    removeInternalUser: async (internalUseerDetails: any) => {
      try {
        const removeInternalUserUrl = `${BASEURL}/internal-user/${internalUseerDetails?.id}`;

        const response = await apiClient(removeInternalUserUrl, {
          method: "DELETE",
        });

        if (response.ok) {
          const result = await response.json();
          set({ roles: result });
          return result;
        } else {
          const result = await response.json();
          // toast.error(
          //   `${"An error occurred. Please check your fields and try again!"}`,
          //   {
          //     duration: 5000,
          //     position: "top-center",
          //     style: { background: "#d00000", color: "#fff" },
          //   }
          // );
          return result;
        }
      } catch (error) {
        set({ loading: false });
        // toast.error(`${"An error occurred. Please contact support!"}`, {
        //   duration: 5000,
        //   position: "top-center",
        //   style: { background: "#d00000", color: "#fff" },
        // });
        console.log(error);
      }
    },

    updateInternalUserPassword: async (internalUserDetails: any) => {
      try {
        const updateInternalUserPasswordUrl = `${BASEURL}/internal-user/${internalUserDetails.id}/update-password`;

        const newPayload = {
          password: internalUserDetails.password,
          confirm_password: internalUserDetails.confirm_password,
        };

        const response = await apiClient(updateInternalUserPasswordUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error) {}
    },

    updatePersonalInternalUserPassword: async (internalUserDetails: any) => {
      try {
        const updateInternalUserPasswordUrl = `${BASEURL}/internal-user/${internalUserDetails.id}/update-personal-password`;

        const newPayload = {
          current_password: internalUserDetails.current_password,
          password: internalUserDetails.password,
          confirm_password: internalUserDetails.confirm_password,
        };

        const response = await apiClient(updateInternalUserPasswordUrl, {
          method: "PUT",
          body: JSON.stringify(newPayload),
        });

        if (response.ok) {
          const result = await response.json();
          return result;
        } else {
          const result = await response.json();
          return result;
        }
      } catch (error) {}
    },
  }))
);
