import React from "react";
import { Routes, Route } from "react-router-dom";
import Devices from "./pages/Devices";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Operators from "./pages/Operators";
import { PrivateRouteGuard, PublicRouteGuard, RoleGuard } from "./utils";
import { Toaster } from "react-hot-toast";
import Locations from "./pages/Locations";
import Vehicles from "./pages/Vehicles";
import Drivers from "./pages/Drivers";
import Tariffs from "./pages/Tariffs";
import MyRoutes from "./pages/Routes";
import MyRoute from "./pages/Routes/Route";
import Stops from "./pages/Stops";
import Licensing from "./pages/Licensing";
import ValidationList from "./pages/ValidationList";
import Profile from "./pages/Profile";
import Operator from "./pages/Operators/Operator";
import Device from "./pages/Devices/Device";
import Location from "./pages/Locations/Location";
import Vehicle from "./pages/Vehicles/Vehicle";
import Driver from "./pages/Drivers/Driver";
import Tariff from "./pages/Tariffs/Tariff";
import Stop from "./pages/Stops/Stop";
import MapDeviceRoute from "./pages/MapDeviceRoute";
import MapRoute from "./pages/MapRoute";
import "./App.css";
import Test from "./pages/Test";
import TransactionHistory from "./pages/TransactionHistory";
import Roles from "./pages/Roles";
import UserManagement from "./pages/UserManagement";
import Role from "./pages/Roles/Role";
import Unauthorized from "./pages/Unauthorized";
import { TransactionStatus } from "./pages/Recon/TransactionStatus/TransactionStatus";
import {
  ProcessingFeeInvoice,
  ReconciliationStatus,
  TransactionStatement,
} from "./pages/Recon";
import { SingleTransactionStatement } from "./pages/Recon/TransactionStatement/SingleTransactionStatement.tsx";
import {
  SingleTransactionReport,
  TransactionReport,
  ViewExceptions,
} from "./pages/HyphenRecon";
import { ViewSingleException } from "./pages/HyphenRecon/ViewExceptions/ViewSingleException/ViewSingleException";
import TopUpHistory from "./pages/TopUpHistory";
import { TransactionMao } from "./pages/TransactionMap";
import { ViewTransactionExceptions } from "./pages/ViewTransactionsExceptions";
import { EditUserInfo } from "./pages/UserManagement/EditUserInfo";
import User from "./pages/UserManagement/User";
import ValidationInfo from "./pages/ValidationList/ValidationInfo";
import TripsHistory from "./pages/TripsHistory";
import TransactionsSummary from "./pages/TransactionsSummary";
import TopUpSummary from "./pages/TopUpSummary";

function App() {
  return (
    <div>
      <Toaster />
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={[
                  "Administrator",
                  "Super Admin User",
                  "Operator",
                  "Finance",
                ]}
              >
                <Home />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />
        <Route
          path="/login"
          element={
            <PublicRouteGuard>
              <Login />
            </PublicRouteGuard>
          }
        />

        <Route
          path="admin/operators"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Operators />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/operators/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Operator />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/licenses"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Licensing />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/devices"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Devices />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="test"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Test />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/devices/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Device />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/locations"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Locations />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/locations/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Location />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/vehicles"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Vehicles />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/vehicles/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Vehicle />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/vehicles/:id/map-device-route"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <MapDeviceRoute />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/vehicles/:id/map-route"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <MapRoute />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/drivers"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Drivers />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/trips"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <TripsHistory />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/trips/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <TripsHistory />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/transactions/view-exceptions"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <ViewTransactionExceptions />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/drivers/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Driver />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/tariffs"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Tariffs />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/tariffs/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Tariff />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/routes"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <MyRoutes />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/routes/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <MyRoute />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/stops"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Stops />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/stops/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Operator"]}
              >
                <Stop />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/validation-list"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Administrator", "Super Admin User"]}>
                <ValidationList />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/validation-list/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Administrator", "Super Admin User"]}>
                <ValidationInfo />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/profile"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={[
                  "Administrator",
                  "Super Admin User",
                  "Operator",
                  "Finance",
                ]}
              >
                <Profile />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/transaction/history"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TransactionHistory />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/transaction-summary/history"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TransactionsSummary />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/topup/history"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TopUpHistory />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/topup-summary/history"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TopUpSummary />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        {/* <Route
          path="admin/reconciliation"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <Recon />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        /> */}

        {/* reconciloation routes*/}

        <Route
          path="/admin/transaction/status"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TransactionStatus />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/processing-fee-invoice"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <ProcessingFeeInvoice />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/transaction-statements"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TransactionStatement />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/transaction-statements/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <SingleTransactionStatement />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/reconciliation-status"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <ReconciliationStatus />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/exceptions"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <ViewExceptions />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/exceptions/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <ViewSingleException />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/hyphen-transaction"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TransactionReport />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/hyphen-transaction/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <SingleTransactionReport />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/admin/transaction-map"
          element={
            <PrivateRouteGuard>
              <RoleGuard
                allowedRoles={["Administrator", "Super Admin User", "Finance"]}
              >
                <TransactionMao />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        {/* end of reconciliation routes */}

        <Route
          path="admin/roles-management"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Super Admin User"]}>
                <Roles />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/roles-management/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Super Admin User"]}>
                <Role />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/user-management"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Super Admin User"]}>
                <UserManagement />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/user-management/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Super Admin User"]}>
                <EditUserInfo />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="admin/user-management/edit/:id"
          element={
            <PrivateRouteGuard>
              <RoleGuard allowedRoles={["Super Admin User"]}>
                <User />
              </RoleGuard>
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/unauthorized"
          element={
            <PrivateRouteGuard>
              <Unauthorized />
            </PrivateRouteGuard>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
