import { Box, Button, Flex, Spacer } from '@chakra-ui/react';
import { PcCaseIcon } from 'lucide-react';
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import DataTableThree from '../../../../components/DataTableThree';
import AdminAppShell from '../../../../layouts/AdminAppShell';
import { useReconStore } from '../../../../hooks';

export const ViewSingleException = () => {

    let { id } = useParams();
    const navigate = useNavigate();
    const downloadExceptionCSVsFileContent = useReconStore((state) => state.downloadExceptionCSVsFileContent);
    const columns = [
        {Header: "Agg ID", accessor: "agg_id"},
        {Header: "Facs ID", accessor: "fac_id"},
        {Header: "Pan", accessor: "pan_id"},
        {Header: "Amount", accessor: "amount"},
        {Header: "Remarks", accessor: "remarks"}
    ]

    const handleDownload = () => {
      if (id) {
        downloadExceptionCSVsFileContent({file_name:id});
      }
    }
  return (
    <AdminAppShell>
        <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          {id}
        </Box>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>


      <Box py={6}>
        <DataTableThree
             columns={columns}
             fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_topup_recon/exp_csvs/content/records/${id}`}
             tokenKey="jwt_access_token"
             downloadFunc={handleDownload}
             showActions={false}
             showExports={false}
             showCSVExports={true}
             showDateRangeFilter={false}
        />
      </Box>
    </AdminAppShell>
  )
}
