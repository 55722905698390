import {
  Box,
  Flex,
  Spacer,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Datatable from "../../components/DataTable";
import { useReconStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import DataTableThree from "../../components/DataTableThree";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const ValidationList = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const downloadLatestPanRecords = useReconStore(
    (state) => state.downloadLatestPanRecords
  );
  const downloadLatestPanRecord = useReconStore(
    (state) => state.downloadLatestPanRecord
  );

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadLatestPanRecords({ format, search, startDate, endDate });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleDownloadRelatedPansFunc = async (params: any) => {
    const { format, details } = params;

    try {
      await downloadLatestPanRecord({
        format,
        search: "",
        startDate: "",
        endDate: "",
        details,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const columns = [
    { Header: "Pan ID", accessor: "pan_id" },
    { Header: "Flag", accessor: "flag" },
    { Header: "Balance", accessor: "balance" },
    { Header: "Timestamp", accessor: "timestamp", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    }, },
  ];

  const handleMoreClick = (item: any) => {
    // console.log(item);
    // navigate(
    //   `/admin/validation-list/${item.id}`
    // );
    setSelectedItem(item); // Set the clicked item
    onOpen(); // Open the modal
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold"> 
            Validation List
          </Box>
        </Flex>
        <Spacer />
      </Flex>
      <Box py={2}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/validation-list/latest-pan-records`}
          tokenKey="jwt_access_token"
          downloadFunc={handleDownloadRelatedPansFunc}
          handleDownloadFunc={handleDownloadFunc}
          showActions={true}
          handleMore={handleMoreClick}
        />
      </Box>

      {/* Modal for showing more details */}
      <Modal size={'6xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Details for {selectedItem?.pan_id}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedItem ? (
              <>
                <DataTableThree
                  columns={columns}
                  fetchUrl={`${process.env.REACT_APP_BASE_URL}/validation-list/records-by-pan/${selectedItem?.pan_id}`}
                  tokenKey="jwt_access_token"
                  downloadFunc={handleDownloadRelatedPansFunc}
                  handleDownloadFunc={handleDownloadFunc}
                  showActions={false}
                  handleMore={handleMoreClick}
                  showExports={false}
                />
              </>
            ) : (
              <Text>Loading...</Text>
            )}
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminAppShell>
  );
};

export default ValidationList;
