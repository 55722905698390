import React, { useState } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import {
  Box,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  Grid,
  Divider,
  useToast,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../components/DataTableThree";
import { useReconStore } from "../../hooks";
import dayjs from "dayjs";

const TripsHistory = () => {
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState<any>(null);
  const downloadTrips = useReconStore((state) => state.downloadTrips);

  const columns = [
    { Header: "Operator Name", accessor: "pto.name" , Cell: ({ row }: any) => row.pto?.name || "--"},
    { Header: "Operator Code", accessor: "pto.code" , Cell: ({ row }: any) => row.pto?.code || "--"},
    { Header: "Trip ID", accessor: "trip_id" },
    { Header: "Driver User Code", accessor: "driver_username" },
    { Header: "Driver", accessor: "driver" },
    { Header: "Route ID", accessor: "route_id" },
    { Header: "Boarded Passengers", accessor: "boarded_passengers" },
    { Header: "Fare", accessor: "fare" },
    { Header: "Total Fare Collected", accessor: "total_fare_collected" },
    { Header: "Start Time", accessor: "start_time", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    } },
    { Header: "End Time", accessor: "end_time", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    } },
    {Header: "In Trip Status", accessor: "intrip", Cell: ({ row }: any) => row.intrip ? "Yes" : "No"},
  ];

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadTrips({ format, search, startDate, endDate });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleViewMoreClick = (row: any) => {
    setSelectedTrip(row);
    setIsModalOpen(true);
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center" mb={4}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={30} />
          <Box fontSize="2xl" fontWeight="bold">
            Trips History
          </Box>
        </Flex>
      </Flex>

      <Box>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/trips`}
          tokenKey="jwt_access_token"
          downloadFunc={() => {}}
          handleDownloadFunc={handleDownloadFunc}
          showActions={true}
          handleMore={handleViewMoreClick}
          showDownloadButton={false}
          tableText="Trips"
          singleTableText="Trip"
        />
      </Box>

      {/* Modal for displaying trip details */}
      {selectedTrip && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          size="6xl"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Trip Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* Trip Information Section */}
              <Box mb={2}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Trip Information
                </Text>
                <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                  <Box>
                    <Text fontWeight="semibold">Trip ID:</Text>
                    <Text>{selectedTrip.trip_id || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Route ID:</Text>
                    <Text>{selectedTrip.route_id || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Start Location:</Text>
                    <Text>{selectedTrip.start_name || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">End Location:</Text>
                    <Text>{selectedTrip.end_name || "N/A"}</Text>
                  </Box>
                </Grid>
              </Box>

              <Divider />

              {/* Combined Driver, Device, and Operator Information Section */}
              <Box my={2}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Driver, Device, & Operator Information
                </Text>
                <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                  <Box>
                    <Text fontWeight="semibold">Driver Username:</Text>
                    <Text>{selectedTrip.driver_username || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Driver Name:</Text>
                    <Text>{selectedTrip.driver || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Device Serial No:</Text>
                    <Text>{selectedTrip.device?.serialno || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Device Name:</Text>
                    <Text>{selectedTrip.device?.name || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">PTO CODE:</Text>
                    <Text>{selectedTrip.pto?.code || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">PTO Name:</Text>
                    <Text>{selectedTrip.pto?.name || "N/A"}</Text>
                  </Box>
                </Grid>
              </Box>

              <Divider />

              {/* Passenger and Fare Information */}
              <Box my={2}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Passenger & Fare Information
                </Text>
                <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                  <Box>
                    <Text fontWeight="semibold">Boarded Passengers:</Text>
                    <Text>{selectedTrip.boarded_passengers || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Alighted Passengers:</Text>
                    <Text>{selectedTrip.alighted_passengers || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Transferred Passengers:</Text>
                    <Text>{selectedTrip.transferred_passengers || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Fare:</Text>
                    <Text>{selectedTrip.fare || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Total Fare Collected:</Text>
                    <Text>{selectedTrip.total_fare_collected || "N/A"}</Text>
                  </Box>
                </Grid>
              </Box>

              <Divider />

              {/* Timing Information */}
              <Box my={2}>
                <Text fontSize="lg" fontWeight="bold" mb={2}>
                  Timing & Distance Information
                </Text>
                <Grid templateColumns="repeat(4, 1fr)" gap={2}>
                  <Box>
                    <Text fontWeight="semibold">Start Time:</Text>
                    <Text>
                      {selectedTrip.start_time
                        ? new Date(selectedTrip.start_time).toLocaleString()
                        : "N/A"}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">End Time:</Text>
                    <Text>
                      {selectedTrip.end_time
                        ? new Date(selectedTrip.end_time).toLocaleString()
                        : "N/A"}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Hours Travelled:</Text>
                    <Text>{selectedTrip.hours_travelled || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Estimated Distance:</Text>
                    <Text>{selectedTrip.estimated_distance || "N/A"}</Text>
                  </Box>
                  <Box>
                    <Text fontWeight="semibold">Distance Travelled:</Text>
                    <Text>{selectedTrip.distance_travelled || "N/A"}</Text>
                  </Box>
                </Grid>
              </Box>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setIsModalOpen(false)}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </AdminAppShell>
  );
};

export default TripsHistory;
