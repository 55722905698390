import {
    Box,
    Button,
    Card,
    CardBody,
    Flex,
    Heading,
    Input,
    Spacer,
    Spinner,
    Text,
  } from "@chakra-ui/react";
  import { CarTaxiFrontIcon } from "lucide-react";
  import React, { useEffect } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import { useStore } from "../../../hooks";
  import AdminAppShell from "../../../layouts/AdminAppShell";
  
  const User = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const fetchSingleInternalUser = useStore((state) => state.fetchSingleInternalUser);
    const posInternalUser = useStore((state) => state.internalUser);
  
    useEffect(() => {
      if (id) {
        fetchSingleInternalUser({ id: id });
      }
    }, [fetchSingleInternalUser, id]);
  
    return (
      <AdminAppShell>
        <Flex alignItems={"center"} mb={8}>
          <Flex alignItems="center" gap={2}>
            <CarTaxiFrontIcon size={35} />
            <Box fontSize="3xl" fontWeight="bold">
              Internal User - {posInternalUser ? (posInternalUser?.is_active ? "Active" : "Inactive") : ""}
            </Box>
          </Flex>
          <Spacer />
          <Button colorScheme="green" onClick={() => navigate(-1)}>
            Go Back
          </Button>
        </Flex>
  
        {posInternalUser ? (
          <Box>
            <Card align="center">
              <CardBody w={"100%"}>
                <Flex w={"100%"} gap={"8"} direction={{ base: "column", md: "row" }}>
                  {/* Left Column */}
                  <Flex direction={"column"} gap={"4"} w={{ base: "100%", md: "50%" }}>
                    <Flex alignItems={"center"} gap={"2"}>
                      <Heading size="md" w={"150px"}>
                        First Name:
                      </Heading>
                      <Input readOnly py="2" value={posInternalUser?.first_name} />
                    </Flex>
  
                    <Flex alignItems={"center"} gap={"2"}>
                      <Heading size="md" w={"150px"}>
                        Last Name:
                      </Heading>
                      <Input readOnly py="2" value={posInternalUser?.last_name} />
                    </Flex>
  
                    <Flex alignItems={"center"} gap={"2"}>
                      <Heading size="md" w={"150px"}>
                        Email:
                      </Heading>
                      <Input readOnly py="2" value={posInternalUser?.email} />
                    </Flex>
                  </Flex>
  
                  {/* Right Column */}
                  <Flex direction={"column"} gap={"4"} w={{ base: "100%", md: "50%" }}>
                    <Flex alignItems={"center"} gap={"2"}>
                      <Heading size="md" w={"150px"}>
                        Role:
                      </Heading>
                      <Input readOnly py="2" value={posInternalUser?.role?.title} />
                    </Flex>
  
                    {/* <Flex alignItems={"center"} gap={"2"}>
                      <Heading size="md" w={"150px"}>
                        Created By:
                      </Heading>
                      <Input readOnly py="2" value={posInternalUser?.created_by} />
                    </Flex>
  
                    <Flex alignItems={"center"} gap={"2"}>
                      <Heading size="md" w={"150px"}>
                        Updated By:
                      </Heading>
                      <Input readOnly py="2" value={posInternalUser?.updated_by} />
                    </Flex> */}
                  </Flex>
                </Flex>
  
                {/* Additional Information */}
                <Flex direction={"column"} mt={6} gap={"4"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"150px"}>
                      Created At:
                    </Heading>
                    <Input readOnly py="2" value={posInternalUser?.created_ts} />
                  </Flex>
  
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"150px"}>
                      Updated At:
                    </Heading>
                    <Input readOnly py="2" value={posInternalUser?.updated_ts} />
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </Box>
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"50vh"}
          >
            <Spinner />
            <Text fontWeight={"bolder"}>Please wait. Loading....</Text>
          </Box>
        )}
      </AdminAppShell>
    );
  };
  
  export default User;
  