import React from "react";
import {
  Box,
  Flex,
  Button,
  ButtonGroup,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  List,
  ListItem,
  ListIcon,
  Avatar,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  IconButton,
  useColorMode,
} from "@chakra-ui/react";
import {
  MoonIcon,
  SunIcon,
  CheckCircleIcon,
  BellIcon,
} from "@chakra-ui/icons";
import AdminAppShell from "../../layouts/AdminAppShell";

const Home = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const userName = "Alex";
  const stats = [
    { label: "🟢 Active Devices", value: "150" },
    { label: "💳 Transactions Today", value: "1,200" },
    { label: "💰 Total Revenue", value: "$15,000" },
  ];
  const activities = [
    "✅ Transaction ID 12345 completed",
    "⚠️ POS Device ID 67890 went offline",
    "👤 New user 'admin' added",
  ];

  return (
    <AdminAppShell>
      <Flex
        direction="column"
        height="84vh"
        px={{ base: 4, md: 8 }}
        py={{ base: 4, md: 0 }}
        overflow="hidden"
      >
        {/* Header Section */}
        <Flex justify="space-between" align="center" mb={4} flexShrink={0}>
          <Flex align="center">
            <Avatar
              size="lg"
              name="Alex Johnson"
              src="path_to_avatar_image"
            />
            <Box ml={3}>
              <Heading size="lg">
                👋 Welcome back, {userName}!
              </Heading>
              <Button onClick={toggleColorMode} mt={2} size="sm">
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button>
            </Box>
          </Flex>
          <IconButton
            variant="ghost"
            aria-label="Notifications"
            icon={<BellIcon />}
            size="md"
          />
        </Flex>

        {/* Main Content */}
        <Box flexGrow={1} overflowY="auto">
          {/* Statistics Section */}
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={4}>
            {stats.map((stat) => (
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
                key={stat.label}
              >
                <Stat>
                  <StatLabel fontSize="md">{stat.label}</StatLabel>
                  <StatNumber fontSize="2xl">{stat.value}</StatNumber>
                </Stat>
              </Box>
            ))}
          </SimpleGrid>

          {/* Actions Section */}
          <ButtonGroup spacing={4} mb={4}>
            <Button colorScheme="teal" size="md">
              📲 Add New Device
            </Button>
            <Button colorScheme="blue" size="md">
              📊 Generate Report
            </Button>
            <Button colorScheme="purple" size="md">
              👥 Manage Users
            </Button>
          </ButtonGroup>

          {/* Activities Section */}
          <Box mb={4}>
            <Heading size="md" mb={3}>
              📝 Recent Activities
            </Heading>
            <List spacing={2}>
              {activities.map((activity, index) => (
                <ListItem key={index} fontSize="sm">
                  {activity}
                </ListItem>
              ))}
            </List>
          </Box>
        </Box>

        {/* Alert Section */}
        <Alert status="info" mb={2} borderRadius="md" flexShrink={0}>
          <AlertIcon boxSize="24px" />
          <Box flex="1">
            <AlertTitle fontSize="md">ℹ️ System Update:</AlertTitle>
            <AlertDescription display="block" fontSize="sm">
              New features have been added to the reporting module.
            </AlertDescription>
          </Box>
        </Alert>
      </Flex>
    </AdminAppShell>
  );
};

export default Home;
