import React, { useState } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Input,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import { DataTableFive } from "../../components";
import axios from "axios";
import dayjs from "dayjs";

const TopUpSummary = () => {
  const toast = useToast();
  const availableColumns = [
    {
      label: "TopUp Date",
      key: "topup_date",
    },
    { label: "PAN ID", key: "pan_id" },
    { label: "User Code", key: "user_code" },
    { label: "Transaction Code", key: "txn_code" },
    { label: "Amount", key: "amount" },
    { label: "Type", key: "type" },
    { label: "Sync Status", key: "external_sync_status" },
    { label: "Sync TimeStamp", key: "external_sync_ts", },
    { label: "Remarks", key: "remarks" },
  ];
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [columns, setColumns] = useState(["pan_id", "topup_date"]);

  // Data & loading states
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Pagination states
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(14);
  const [total, setTotal] = useState(0);

  const handleSubmit = async () => {
    // Only fetch if startDate and endDate are set
    if (!startDate || !endDate) return;

    // Format the startDate as 'YYYY-MM-DD 00:00:00'
    const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD") + " 00:00:00";
    // Format the endDate as 'YYYY-MM-DD 23:59:59'
    const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD") + " 23:59:59";

    setLoading(true);
    try {
      // Build fields parameter from selected columns
      const fields = columns.join(",");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/top-ups/filtered`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            fields,
            page,
            size,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "jwt_access_token"
            )}`,
          },
        }
      );

      const { items, total, page: currentPage, size: pageSize } = response.data;
      setData(items);
      setTotal(total);

      // Show toast if no data is found
      if (!items || items.length === 0) {
        toast({
          title: "No Data",
          description: "No top-ups found for the selected date range.",
          status: "warning",
          duration: 5000,
          isClosable: true,
          position: "top-right"
        });
      }

      // Update page and size from response if they differ (usually they won't)
      if (currentPage !== page) setPage(currentPage);
      if (pageSize !== size) setSize(pageSize);
    } catch (err) {
      console.error("Error fetching filtered transactions:", err);
      toast({
        title: "Error",
        description: "Failed to fetch top-up data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleColumnChange = (colKey: string) => {
    setColumns(
      (prev) =>
        prev.includes(colKey)
          ? prev.filter((c) => c !== colKey) // Remove column if already selected
          : [...prev, colKey] // Add column if not selected
    );
  };

  const isSubmitDisabled = !startDate || !endDate;

  // Calculate total pages
  const totalPages = Math.ceil(total / size);

  return (
    <AdminAppShell>
      <Box py={2} minH="90vh">
        <Box fontSize={"xl"} fontWeight={"bold"} mb={2}>
          Viewing Top Up Transactions History
        </Box>

        <Grid templateColumns={{ base: "1fr", md: "300px 1fr" }} gap={6}>
          {/* Left Section: Filters */}
          <GridItem p={2} borderRadius="md" boxShadow="sm">
            <Text fontSize="xs" color="white.600">
              Use the form below to filter top up transactions by date range and select
              which columns to display in the summary.
            </Text>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setPage(1); // Reset to first page when filters change
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setPage(1); // Reset to first page when filters change
                  }}
                />
              </FormControl>

              <Box>
                <FormLabel>Columns to Display</FormLabel>
                <Stack spacing={2}>
                  {availableColumns.map((col: any) => (
                    <Checkbox
                      key={col.key}
                      isChecked={columns.includes(col.key)}
                      onChange={() => {
                        handleColumnChange(col.key);
                        setPage(1); // If columns changed, go back to first page
                      }}
                    >
                      {col.label}
                    </Checkbox>
                  ))}
                </Stack>
              </Box>

              <Button
                colorScheme="green"
                onClick={() => {
                  setPage(1); // Always fetch from first page on new submit
                  handleSubmit();
                }}
                isLoading={loading}
                isDisabled={isSubmitDisabled}
                title={
                  isSubmitDisabled
                    ? "Please select both start and end dates"
                    : ""
                }
              >
                Submit filter request
              </Button>
            </Stack>
          </GridItem>

          {/* Right Section: Transactions Data */}
          <GridItem
            p={2}
            borderRadius="md"
            boxShadow="sm"
            // Set a fixed height and allow scrolling inside this section
            height="620px"
            display="flex"
            flexDirection="column"
            overflowX={"scroll"}
          >
            <Heading as="h2" size="md" mb={4}>
              Top Ups Transactions Data
            </Heading>

            {/* Scrollable container for the table - both X and Y */}
            <Box flex="1" overflowX="auto" overflowY="auto">
              {data.length > 0 ? (
                <DataTableFive
                  columns={availableColumns
                    .filter((col: any) => columns.includes(col.key))
                    .map((col: any) => ({
                      Header: col.label,
                      accessor: col.key,
                    }))}
                  data={data}
                  loading={loading}
                />
              ) : (
                <>
                  <Text fontSize="sm" colorScheme="white">
                    Once filters are applied, transaction data would appear
                    here.
                  </Text>
                </>
              )}
            </Box>

            {/* Pagination Controls - placed outside the scrollable section */}
            {data.length > 0 && (
              <Box
                mt={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  {/* First Page Button */}
                  <Button
                    onClick={() => {
                      if (page !== 1) {
                        setPage(1);
                        handleSubmit(); // Fetch first page
                      }
                    }}
                    isDisabled={page === 1 || loading}
                    size="sm"
                  >
                    <ArrowLeftIcon mr={1} />
                  </Button>

                  {/* Previous Page Button */}
                  <Button
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                        handleSubmit(); // Fetch previous page
                      }
                    }}
                    isDisabled={page === 1 || loading}
                    size="sm"
                  >
                    <ArrowBackIcon />
                  </Button>
                </Stack>

                <Box fontWeight={"bold"}>
                  Page {page} of {totalPages} (Total: {total})
                </Box>

                <Stack direction="row" spacing={2} alignItems="center">
                  {/* Next Page Button */}
                  <Button
                    onClick={() => {
                      if (page < totalPages) {
                        setPage(page + 1);
                        handleSubmit(); // Fetch next page
                      }
                    }}
                    isDisabled={page >= totalPages || loading}
                    size="sm"
                  >
                    <ArrowForwardIcon />
                  </Button>

                  {/* Last Page Button */}
                  <Button
                    onClick={() => {
                      if (page !== totalPages) {
                        setPage(totalPages);
                        handleSubmit(); // Fetch last page
                      }
                    }}
                    isDisabled={page >= totalPages || loading}
                    size="sm"
                  >
                    <ArrowRightIcon ml={1} />
                  </Button>
                </Stack>
              </Box>
            )}
          </GridItem>
        </Grid>
      </Box>
    </AdminAppShell>
  );
};

export default TopUpSummary;
