import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Checkbox,
  Select,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useEffect } from "react";
import Datatable from "../../components/DataTable";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Formik, Field, Form } from "formik";
import { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { generateRandomUUID } from "../../utils/generateUUID";
import * as Yup from "yup";

const initialValues = {
  name: "",
  code: "",
  longitude: 0,
  latitude: 0,
  type: 0,
  facility: "",
  location_id: 0,
  enabled: true,
  external_sync_status: "NOT POSTED",
  remarks: "",
};

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(25, "Name cannot exceed 25 alphanumeric characters")
    .matches(
      /^[a-zA-Z0-9 ]*$/,
      "Location Name can only contain alphanumeric characters and spaces"
    ),
  longitude: Yup.string()
    .required("Longitude is required")
    .transform((value) => value.replace(/\s+/g, "")) // Remove spaces
    .test(
      "is-valid-decimal",
      "Longitude must be a valid decimal number",
      (value) => /^-?\d+(\.\d+)?$/.test(value) // Validate decimal format
    )
    .test(
      "is-in-range",
      "Longitude must be between -180 and 180 degrees",
      (value) => {
        const num = parseFloat(value);
        return num >= -180 && num <= 180;
      }
    )
    .test(
      "decimal-places",
      "Longitude must have between 5 and 7 decimal places",
      (value) => {
        const decimalPlaces = value.split(".")[1];
        const numDecimalPlaces = decimalPlaces ? decimalPlaces.length : 0;
        return numDecimalPlaces >= 5 && numDecimalPlaces <= 7;
      }
    ),
  latitude: Yup.string()
    .required("Latitude is required")
    .transform((value) => value.replace(/\s+/g, "")) // Remove spaces
    .test(
      "is-valid-decimal",
      "Latitude must be a valid decimal number",
      (value) => /^-?\d+(\.\d+)?$/.test(value) // Validate decimal format
    )
    .test(
      "is-in-range",
      "Latitude must be between -90 and 90 degrees",
      (value) => {
        const num = parseFloat(value);
        return num >= -90 && num <= 90;
      }
    )
    .test(
      "decimal-places",
      "Latitude must have between 5 and 7 decimal places",
      (value) => {
        const decimalPlaces = value.split(".")[1];
        const numDecimalPlaces = decimalPlaces ? decimalPlaces.length : 0;
        return numDecimalPlaces >= 5 && numDecimalPlaces <= 7;
      }
    ),
  type: Yup.number().required("Type is required"),
  facility: Yup.string().required("Facility is required"),
  location_id: Yup.string()
    .matches(/^\d+$/, "Location ID must be digits only")
    .test(
      "greater-than-zero",
      "Location ID must be greater than 0",
      (value: any) => parseInt(value, 10) > 0
    )
    .max(8, "Location ID must be 8 digits or less")
    .required("Location ID is required"),
});

const locationsType = [
  {
    id: 1,
    title: "Informal",
    value: 0,
  },
  {
    id: 2,
    title: "Bus/Taxi Stops",
    value: 1,
  },
  {
    id: 3,
    title: "Facility",
    value: 2,
  },
  {
    id: 4,
    title: "Route",
    value: 3,
  },
];

const locationFacilities = [
  {
    id: 1,
    title: "Minibus-Taxi Rank",
    value: "TR",
  },
  {
    id: 2,
    title: "Minibus-Taxi Holding",
    value: "TH",
  },
  {
    id: 3,
    title: "Bus Terminal",
    value: "BT",
  },
  {
    id: 4,
    title: "Bus Depot",
    value: "BD",
  },
  {
    id: 5,
    title: "Bus Holding",
    value: "BH",
  },
  {
    id: 6,
    title: "Ralway Station",
    value: "RS",
  },
  {
    id: 7,
    title: "Park & Ride",
    value: "PR",
  },
  {
    id: 8,
    title: "Metered Taxi Rank",
    value: "MR",
  },
  {
    id: 9,
    title: "Airport",
    value: "AP",
  },
  {
    id: 10,
    title: "Seaport",
    value: "SP",
  },
];

const Locations = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const posLocations = useStore((state) => state.locations);
  const fetchLocations = useStore((state) => state.fetchLocations);
  const removeLocation = useStore((state) => state.removeLocation);
  const createLocation = useStore((state) => state.createLocation);
  const updateLocation = useStore((state) => state.updateLocation);
  const postLocationToSanral = useStore((state) => state.postLocationToSanral);
  const generateAuth = useStore((state) => state.generateAuth);
  const isLoading = useStore((state) => state.loading);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations]);

  const handleSubmit = (locationDetails: any) => {
    setLoading(true);

    if (!locationDetails.enabled) {
      locationDetails.enabled = false;
    }

    createLocation(locationDetails).then((res: any) => {
      if (res.name) {
        setLoading(false);
        onClose();
        fetchLocations();
        toast({
          title: "Success",
          description: "Location was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);

    if (!updatedData.enabled) {
      updatedData.enabled = false;
    }

    updatedData.external_sync_status = "";
    updatedData.remarks = "";

    updateLocation(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchLocations();
        toast({
          title: "Success",
          description: "Location was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = (item: any) => {
    console.log("this is my item", item);
    const newPayloadFormat: any = {
      id: item.id,
      name: item.name,
      longitude: item.longitude,
      latitude: item.latitude,
      type: item.type,
      facility: item.facility,
      enabled: true,
      location_id: item.location_id,
    };

    setLocation(newPayloadFormat);

    if (location) {
      onOpen();
      console.log(location);
    }
  };

  const handleViewMore = (item: any) => {
    console.log(item);
    navigate(`/admin/locations/${item?.id}`);
  };

  const handlePostLocationToSanral = (item: any) => {
    // generate auth

    console.log("ksvbjhds", item);

    generateAuth({
      license: process.env.REACT_APP_LICENSE,
      device_mac: process.env.REACT_APP_DEVICE_MAC,
    }).then((res: any) => {
      console.log(res);

      if (res) {
        if (res?.Response?.Status === "active") {
          const guid = generateRandomUUID();

          postLocationToSanral({
            pto_code: process.env.REACT_APP_PTO_CODE,
            device_mac: process.env.REACT_APP_DEVICE_MAC,
            license: process.env.REACT_APP_LICENSE,
            auth: res?.Response?.Token,
            nonce: "u23f7ofwgq",
            authorization: "fairpayy",
            locationPayload: {
              guid: guid,
              locations: [
                {
                  id: item?.location_id,
                  name: item?.name,
                  latitude: parseFloat(item?.latitude),
                  longitude: parseFloat(item?.longitude),
                  ndotType: item?.type,
                  ndotFacility: item?.facility,
                  enabled: item?.enabled,
                },
              ],
            },
          })
            .then((res: any) => {
              console.log("resss", res);
              // check if there is a valid response or error
              // update the locations external_status_sync field to success | failed | pending

              if (res?.Response) {
                if (res?.Response?.returnCode === 0) {
                  let locationPayload = {
                    id: item.id,
                    name: item.name,
                    longitude: item.longitude,
                    latitude: item.latitude,
                    type: item.type,
                    facility: item.facility,
                    enabled: item.enabled,
                    location_id: item.location_id,
                    external_sync_status: "success",
                    remarks: item.remarks,
                  };

                  updateLocation(locationPayload).then((res: any) => {
                    if (res) {
                      fetchLocations();
                      toast({
                        title: "Success",
                        description:
                          "Location status was updated successfully!",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  });
                }
              }
            })
            .catch((err: any) => {
              toast({
                title: "Success",
                description: "Location status was not updated successfully!",
                status: "error",
                duration: 9000,
                isClosable: true,
              });
            });
        }
      }
    });
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Locations
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setLocation(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Location
        </Button>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={onClose}
          size="xl"
          closeOnOverlayClick={false}
          scrollBehavior="outside"
        >
          {overlay}
          <ModalContent>
            <ModalHeader>
              {location ? "Update Existing Location" : "Create a New Location"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={location ? location : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={location ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Stack direction="column" spacing={6}>
                    <Field name="name">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel>
                            Location Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input {...field} type="name" id="name" />
                          <FormErrorMessage>
                            {form.errors.name &&
                              form.touched.name &&
                              form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="longitude">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.longitude && form.touched.longitude
                          }
                        >
                          <FormLabel>
                            Longitude <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input {...field} type="longitude" id="longitude" />
                          <FormErrorMessage>
                            {form.errors.longitude &&
                              form.touched.longitude &&
                              form.errors.longitude}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="latitude">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.latitude && form.touched.latitude
                          }
                        >
                          <FormLabel>
                            Latitude <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input {...field} type="latitude" id="latitude" />
                          <FormErrorMessage>
                            {form.errors.latitude &&
                              form.touched.latitude &&
                              form.errors.latitude}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="type">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={form.errors.type && form.touched.type}
                        >
                          <FormLabel>
                            Type <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          {/* <Input {...field} type="type" id="type" /> */}
                          <Select
                            {...field}
                            placeholder="Select a location type"
                            type="type"
                            id="type"
                          >
                            {locationsType &&
                              locationsType.map((item: any, index: number) => (
                                <option key={index} value={item.id}>
                                  {item.title}
                                </option>
                              ))}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.type &&
                              form.touched.type &&
                              form.errors.type}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="facility">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.facility && form.touched.facility
                          }
                        >
                          <FormLabel>
                            Facility <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          {/* <Input {...field} type="facility" id="facility" /> */}
                          <Select
                            {...field}
                            placeholder="Select a location facility"
                            type="facility"
                            id="facility"
                          >
                            {locationFacilities &&
                              locationFacilities.map(
                                (item: any, index: number) => (
                                  <option key={index} value={item.id}>
                                    {item.title}
                                  </option>
                                )
                              )}
                          </Select>
                          <FormErrorMessage>
                            {form.errors.facility &&
                              form.touched.facility &&
                              form.errors.facility}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="location_id">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.location_id && form.touched.location_id
                          }
                        >
                          <FormLabel>
                            Location ID <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input
                            {...field}
                            type="location_id"
                            id="location_id"
                          />
                          <FormErrorMessage>
                            {form.errors.location_id &&
                              form.touched.location_id &&
                              form.errors.location_id}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Field name="enabled">
                      {({ field, form }: any) => (
                        <FormControl
                          isInvalid={
                            form.errors.enabled && form.touched.enabled
                          }
                        >
                          <FormLabel>
                            Enabled <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Checkbox
                            isChecked={field.value}
                            {...field}
                            colorScheme="teal"
                            size="lg"
                            iconColor="red.500"
                            type="enabled"
                            id="enabled"
                          >
                            Enabled
                          </Checkbox>
                          <FormErrorMessage>
                            {form.errors.enabled &&
                              form.touched.enabled &&
                              form.errors.enabled}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Stack direction="row" spacing={4}>
                      <Button
                        width="100%"
                        colorScheme="teal"
                        variant="solid"
                        type="submit"
                      >
                        {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                      </Button>
                    </Stack>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              header: "Location Name",
              accessorKey: "name",
            },
            {
              header: "location ID",
              accessorKey: "location_id",
            },
            {
              header: "Enabled",
              accessorKey: "enabled",
            },
            {
              header: "Longitude",
              accessorKey: "longitude",
            },
            {
              header: "Latitude",
              accessorKey: "latitude",
            },
            {
              header: "Type",
              accessorKey: "type",
              cell: ({ row }: any) => {
                const type: any = locationsType.find(
                  (item) => item.value === row.original.type
                );
                return type.title ? type.title : "Unknown Type";
              },
            },
            {
              header: "Facility",
              accessorKey: "facility",
              cell: ({ row }: any) => {
                const facility = locationFacilities.find(
                  (item) => item.id === parseInt(row.original.facility)
                );
                return facility ? facility.title : "Unknown Facility";
              },
            },
          ]}
          data={posLocations}
          dataTableHeading={"Locations"}
          handleDelete={removeLocation}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          handlePostLocationToSanral={handlePostLocationToSanral}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Locations;
