import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  Text,
  Center,
  Spinner,
  Select,
  Input,
  Flex,
  Spacer,
  Badge,
  Tooltip,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputLeftAddon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Portal,
  CheckboxGroup,
  Stack,
  Checkbox,
} from "@chakra-ui/react";
import {
  EyeIcon,
  FileDownIcon,
  Filter,
  FilterX,
  FileUpIcon,
} from "lucide-react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import EditTransactionModal from "../EditTransactionModal";
import { useColorModeValue } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { keyframes } from "@emotion/react";

const DataTableFour = ({
  allFields,
  fetchUrl,
  tokenKey,
  downloadFunc,
  showActions = true,
  showExports = true,
  handleMore,
  handleDownloadFunc,
  showEditButton = false, // New prop for Edit button
  showResubmitButton = false, // New prop for Resubmit button
  handleEdit, // New prop for handling edit
  handleResubmit, // New prop for handling resubmit,
  showDownloadButton = true,
  id,
  showIcons = true,
  showDateRangeFilter = true,
  selectedFields, 
  setSelectedFields,
  onExport, 
  startDate, // New prop
  endDate, // New prop
  setStartDate, // New prop
  setEndDate, // New prop
}: any) => {
  // State to track selected fields

  // Adjust columns based on selected fields
  const columns = allFields.filter((field: any) =>
    selectedFields.includes(field.accessor)
  );

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null); // Track selected item for editing
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editedRecords, setEditedRecords] = useState(new Set());
  const [loadingStates, setLoadingStates] = useState<any>({});
  const [downloadLoading, setDownloadLoading] = useState("");
  const [itemDownloadLoading, setItemDownloadLoading] = useState(null);
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [exportType, setExportType] = useState<"full" | "filtered" | null>(
    null
  );
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [isExportFormatModalOpen, setIsExportFormatModalOpen] = useState(false);
  const [selectedExportFormat, setSelectedExportFormat] = useState<
    "csv" | "excel" | null
  >(null);

  const bgColor = useColorModeValue("white", "gray.800");

  const scaleUp = keyframes`
    0% { transform: scale(0); opacity: 0; }
    100% { transform: scale(1); opacity: 1; }
  `;

  const toast = useToast();

  useEffect(() => {
    fetchData(
      currentPage,
      pageSize,
      sortConfig,
      searchQuery,
      startDate,
      endDate
    )
  }, [
    currentPage,
    pageSize,
    sortConfig,
    searchQuery,
    startDate,
    endDate,
    selectedFields,
  ]);

  useEffect(() => {
    // Whenever startDate or endDate changes, reset currentPage to 1
    setCurrentPage(1);
  }, [startDate, endDate]);

  const fetchData = async (
    page: any,
    size: any,
    sortConfig: any,
    searchQuery: any,
    startDate: any,
    endDate: any
  ) => {
    setLoading(true);
    try {
      const formattedStartDate = startDate
        ? dayjs(startDate).startOf("day").format("YYYY-MM-DD HH:mm:ss")
        : undefined;
      const formattedEndDate = endDate
        ? dayjs(endDate).endOf("day").format("YYYY-MM-DD HH:mm:ss")
        : undefined;

      // Join selectedFields to create the fields parameter
      const fieldsParam = selectedFields.join(",");

      const response = await axios.get(fetchUrl, {
        params: {
          page,
          size,
          sortField: sortConfig.key,
          sortDirection: sortConfig.direction,
          search: searchQuery,
          startDate: formattedStartDate, // Include startDate
          endDate: formattedEndDate,
          fields: fieldsParam,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(tokenKey)}`,
        },
      });

      console.log("Response", response.data);
      setData(response.data.items);
      setTotalPages(response.data.pages);
    } catch (error: any) {
      console.error("Error fetching data", error);

      if (error.response && error.response.status === 401) {
        // Unauthorized error
        setIsSessionExpired(true);
      } else {
        // Handle other errors if needed
      }
    }
    setLoading(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handlePageSizeChange = (e: any) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSort = (key: any) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDownload = async (format: string) => {
    setDownloadLoading(format);
    if (id) {
      console.log("Download with id", id, format);

      if (format === "excel") {
        downloadFunc({ format, id })
          .then((res: any) => {
            console.log("DOWNLOAD", res);
          })
          .catch((error: any) => {
            console.log("ERROR", error);
          })
          .finally(() => {
            setDownloadLoading("");
          });
      }

      if (format === "csv") {
        downloadFunc({ format, id })
          .then((res: any) => {
            console.log("DOWNLOAD", res);
          })
          .catch((error: any) => {
            console.log("ERROR", error);
          })
          .finally(() => {
            setDownloadLoading("");
          });
      }
    } else {
      console.log("fooooormat", format);

      if (format === "excel") {
        downloadFunc({ format })
          .then((res: any) => {
            console.log("DOWNLOAD", res);
          })
          .catch((error: any) => {
            console.log("ERROR", error);
          })
          .finally(() => {
            setDownloadLoading("");
          });
      }

      if (format === "csv") {
        downloadFunc({ format })
          .then((res: any) => {
            console.log("DOWNLOAD", res);
          })
          .catch((error: any) => {
            console.log("ERROR", error);
          })
          .finally(() => {
            setDownloadLoading("");
          });
      }
    }
  };

  const handleViewMore = (item: any) => {
    handleMore(item);
  };

  const handleEditClick = (item: any) => {
    // if (handleEdit) {
    //   handleEdit(item);
    // }
    setSelectedItem(item); // Set the selected item for editing
    onOpen();
  };

  const handleEditSubmit = async (updatedItem: any) => {
    console.log("Updated item", updatedItem);
    setLoadingStates((prev: any) => ({ ...prev, [updatedItem.id]: true }));
    try {
      const response = await fetch(`${fetchUrl}/${updatedItem.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(tokenKey)}`, // Assuming you're still using token-based auth
        },
        body: JSON.stringify(updatedItem),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update transaction");
      }

      const updatedTransaction = await response.json();

      // Mark this record as edited
      setEditedRecords((prev) => new Set(prev).add(updatedTransaction.id));

      toast({
        title: "Success",
        description: `Transaction was updated successfully! Please Resubmit to comlete the transaction update process.`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      // Optionally, refresh the table data after successful update
      // fetchData(currentPage, pageSize, sortConfig, searchQuery);
    } catch (error) {
      console.error("Error updating item", error);
      toast({
        title: "Error",
        description: `Could not update the transaction successfully.`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingStates((prev: any) => ({ ...prev, [updatedItem.id]: false })); // Reset Edit button loading states
    }
  };

  const handleResubmitClick = async (item: any) => {
    setLoadingStates((prev: any) => ({ ...prev, [item.id]: true }));
    try {
      const response = await fetch(`${fetchUrl}/${item.id}/change-status`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem(tokenKey)}`,
        },
        body: JSON.stringify({ new_status: "-1" }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.detail || "Failed to change transaction status"
        );
      }

      const updatedTransaction = await response.json();
      console.log(
        "Transaction status updated successfully",
        updatedTransaction
      );
      toast({
        title: "Success",
        description: `Transaction was updated successfully!`,
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      // Optionally, refresh the table data after status update
      fetchData(
        currentPage,
        pageSize,
        sortConfig,
        searchQuery,
        startDate,
        endDate
      );
    } catch (error) {
      console.error("Error changing transaction status", error);
      toast({
        title: "Error",
        description: `Could not update the transaction successfully.`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      // Reset Resubmit butt
      setLoadingStates((prev: any) => ({ ...prev, [item.id]: false }));
    }
  };

  const getStatusBadge = (status: string) => {
    console.log("sdvsd", status);
    const statusMeaning: { [key: string]: string } = {
      Assigned:
        "The transaction has been assigned to a user account and is payable to the PTO",
      Unassigned:
        "The Transaction is valid but has not been assigned to a user account yet.",
      "Technical Loss":
        "The transaction ihas been deemed as a duplicate during processing and is not payable to the PTO",
      "TCH Rejected":
        "The transaction has been rejected by the ABT Back Office according to its processing rules.",
      "TCH Exempt":
        "The transaction has been associated to an Exempt account in the TCH",
      Unpaid:
        "After the 7 day grace period, if the transaction could not be ssigned to any account it will be marked as Unpaid",
    };
    return statusMeaning[status] || "Not Reconciled";
  };

  const getBadgeColor = (status: string) => {
    const colors: { [key: string]: string } = {
      Assigned: "green",
      Unassigned: "red",
      "Technical Loss": "yellow",
      "TCH Rejected": "orange",
      "TCH Exempt": "blue",
      Unpaid: "purple",
    };
    return colors[status] || "gray";
  };

  const getConceptTooltip = (concept: string) => {
    const conceptMeanings: { [key: string]: string } = {
      CCADJ:
        "Customer Claim Adjustment: these are debits created against a transaction to represent an adjustment made as per a customer claim (e.g.: the customer was changed an incorrect or excessive fare).",
      CETA: "Exempt - Transaction Adjustment: these are debits created against a transaction to represent an adjustment made to transform the transaction into an Exempt one (e.g.: 100% discount). This is not currently in place for ABT but has been included in the spec in case the Exempt figure becomes relevant in future.",
      ETOLL:
        "Guaranteed - TCH Exempt - Transaction: this concept groups all Exempt transactions received and processed where the payment is guaranteed to the PTO.",
      GTOLL:
        "Guaranteed Transaction: this concept groups all regular transactions received and processed, which were assigned to accounts, and where the payment is guaranteed to the PTO.",
      NGTOLL:
        "Assigned - Non-Guaranteed Transaction: this concept groups all regular transactions received and processed, which were assigned to accounts, and where the payment is not guaranteed to the PTO.",
      PTOLL:
        "Unassigned – Non-Guaranteed Transaction: this concept groups all regular transactions received and processed, which were not assigned to accounts yet, and therefore the payment is not guaranteed to the PTO. Note that the transactions included in this concept will 'move' to a different concept in subsequent statements once they have reached their final status.",
      RTOLL:
        "TCH Rejected - Transaction: this concept groups all regular transactions received and processed, which were deemed rejected, therefore not payable to the PTO.",
      TTOLL:
        "TCH Duplicated - Transaction: this concept groups all regular transactions received and processed, which were deemed as duplicates, therefore not payable to the PTO.",
      UTOLL:
        "Non-Guaranteed - Unpaid – Transaction: this concept groups all regular transactions received and processed, which were deemed as unpaid (not possible to assign to an account after the 7-day grace period expired), therefore not payable to the PTO.",
      GPFEE:
        "Guaranteed Transaction Processing Fee: this is the processing fee calculated for all transactions marked as Guaranteed in current statement (ETOLL and GTOLL groups).",
      NGPFEE:
        "Non-Guaranteed Transaction Processing Fee: this is the processing fee calculated for all transactions marked as Non-Guaranteed in current statement, including the 'failed' states (NGTOLL, RTOLL, TTOLL, and UTOLL groups).",
    };
    return conceptMeanings[concept] || "Unknown concept";
  };

  const clearDateFilters = () => {
    setStartDate("");
    setEndDate("");
    setCurrentPage(1);
  };

  const applyDateFilters = () => {
    if (startDate && endDate && startDate > endDate) {
      toast({
        title: "Invalid Date Range",
        description: "Start date cannot be after end date.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    // Reset to first page when applying new filters
    setCurrentPage(1);
  };

  const triggerDownload = async (
    type: "full" | "filtered" | null,
    format: "csv" | "excel"
  ) => {
    if (!type) {
      toast({
        title: "Export Error",
        description: "Please select an export type.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setDownloadLoading(format);

    try {
      // Prepare the parameters based on export type
      const params: any = {
        format,
        // Additional parameters if needed
      };

      if (type === "filtered") {
        params.startDate = dayjs(startDate)
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        params.endDate = dayjs(endDate)
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      }

      // // Call the handleDownloadFunc with the appropriate parameters
      await handleDownloadFunc(params);

      // Show tick animation
      setDownloadSuccess(true);

      // After a short delay, close the modal and reset the success state
      setTimeout(() => {
        setDownloadSuccess(false);
        setIsExportFormatModalOpen(false);
      }, 1500); // 1.5 seconds delay
    } catch (error: any) {
      console.error("Error exporting data", error);
      toast({
        title: "Export Failed",
        description: "An error occurred while exporting the data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setDownloadLoading("");
    }
  };

  const handleConfirmExport = (format: "csv" | "excel") => {
    if (exportType) {
      triggerDownload(exportType, format);
    } else {
      toast({
        title: "Export Type Required",
        description: "Please select an export type before choosing the format.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDownloadFormat = async (item: any, format: "csv" | "excel") => {
    setItemDownloadLoading(item.id); // Set loading state for this item
    try {
      console.log("Item", item);
      console.log("Format", format);

      await downloadFunc({ format, details: item });
    } catch (error: any) {
      console.error("Error downloading item", error);
    } finally {
      setItemDownloadLoading(null); // Reset loading state
    }
  };

  return (
    <>
      {(downloadLoading !== "" || itemDownloadLoading !== null) && (
        <Modal isOpen={true} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Download in Progress</ModalHeader>
            <ModalBody>
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <Spinner size="xl" />
                <Text>
                  {downloadLoading !== ""
                    ? `Downloading ${downloadLoading.toUpperCase()} file...`
                    : "Downloading item..."}
                </Text>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isSessionExpired && (
        <Modal isOpen={true} onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader display="flex" alignItems="center">
              Session Expired{" "}
              <span
                role="img"
                aria-label="warning"
                style={{ marginLeft: "8px" }}
              >
                ⚠️
              </span>
            </ModalHeader>
            <ModalBody>
              <Text fontSize="lg" mb={4}>
                Your session has expired. Please log in again{" "}
                <span role="img" aria-label="hourglass">
                  ⏳
                </span>
              </Text>
              <Button
                mt={4}
                colorScheme="blue"
                size="md"
                leftIcon={
                  <span role="img" aria-label="key">
                    🔑
                  </span>
                }
                onClick={() => {
                  // Clear token and redirect to login
                  window.localStorage.removeItem(tokenKey);
                  navigate("/login");
                }}
              >
                Go to Login
              </Button>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* Format Selection Modal */}
      <Modal
        isOpen={isExportFormatModalOpen}
        onClose={() => setIsExportFormatModalOpen(false)}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Export Format</ModalHeader>
          <ModalBody>
            {downloadSuccess ? (
              // Render the tick animation upon successful download
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <Box
                  as="span"
                  role="img"
                  aria-label="success"
                  fontSize="6xl"
                  color="green.500"
                  css={{
                    animation: `${scaleUp} 0.3s ease-out forwards`,
                  }}
                >
                  ✅
                </Box>
                <Text>Download Successful!</Text>
              </Flex>
            ) : (
              // Render the export format buttons
              <Flex direction="column" gap={4}>
                <Button
                  colorScheme="blue"
                  onClick={() => handleConfirmExport("excel")}
                  leftIcon={
                    <span role="img" aria-label="excel">
                      📊
                    </span>
                  }
                >
                  Excel
                </Button>
                <Button
                  colorScheme="green"
                  onClick={() => handleConfirmExport("csv")}
                  leftIcon={
                    <span role="img" aria-label="csv">
                      🗂️
                    </span>
                  }
                >
                  CSV
                </Button>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <TableContainer width="100%">
        <Box display={'flex'} flexDirection={'column'} gap={2} boxShadow={'outline'} mb={4}> 
          <Box fontWeight={'bold'}>Filter by columns of choice</Box>
          <CheckboxGroup
            colorScheme="green"
            value={selectedFields}
            onChange={(values) => setSelectedFields(values as string[])}
          >
            <Stack direction="row" wrap="wrap" spacing={4} pb={2}>
              {allFields.map((field: any) => (
                <Checkbox key={field.accessor} value={field.accessor}>
                  {field.label}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Box>

        <Flex mb={2} justifyContent={"space-between"} alignItems={"center"}>
          <Flex mb={2} direction="column">
            {/* Search bar and export buttons */}
            <Flex
              justifyContent="space-between"
              alignItems="center"
              w={"500px"}
            >
              {/* Search Bar */}
              <Input
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                width="100%"
              />
            </Flex>

            {/* Date Filters beneath the Search Bar */}
            {showDateRangeFilter && (
              <Flex gap={2} py={2} alignItems="center">
                <InputGroup>
                  <InputLeftAddon>Start Date</InputLeftAddon>
                  <Input
                    placeholder="Start Date"
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value)
                      setCurrentPage(1); 
                    }}
                  />
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon>End Date</InputLeftAddon>
                  <Input
                    placeholder="End Date"
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value)
                      setCurrentPage(1); 
                    }}
                  />
                </InputGroup>
                <Tooltip
                  label="Clear Date Filters"
                  aria-label="Clear Date Filters Tooltip"
                >
                  <Button
                    onClick={clearDateFilters}
                    variant="ghost"
                    colorScheme="red"
                    isDisabled={!startDate && !endDate}
                    size={'xl'}
                  >
                    <FilterX />
                  </Button>
                </Tooltip>
              </Flex>
            )}
          </Flex>

          <Spacer />

          {showExports && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={<FileUpIcon />}
                colorScheme="green"
                isDisabled={downloadLoading !== ""}
                size="sm"
              >
                Export Data
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    // setExportType("full");
                    // setIsExportFormatModalOpen(true);
                    if (onExport) onExport('full');
                  }}
                  isDisabled={downloadLoading !== ""}
                >
                  Export Full Range Data
                </MenuItem>
                {startDate && endDate && (
                  <MenuItem
                    onClick={() => {
                    //   setExportType("filtered");
                    //   setIsExportFormatModalOpen(true);
                      if (onExport) onExport('filtered');
                    }}
                    isDisabled={downloadLoading !== ""}
                  >
                    Export Date Range Filtered Data
                  </MenuItem>
                )}
              </MenuList>
            </Menu>
          )}
        </Flex>
        {loading ? (
          <Flex
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            h="400px"
          >
            <Spinner size="xl" />
            <Box fontWeight={"bold"} my={"4"}>
              Please wait as we load data...
            </Box>
          </Flex>
        ) : (
          <>
            <TableContainer position={"relative"} overflowX="auto">
              <Table size={"sm"} variant={"simple"} width="100%">
                <Thead>
                  <Tr position="relative" zIndex={0}>
                    {columns.map((column: any, index: number) => (
                      <Th
                        key={index}
                        onClick={() => handleSort(column.accessor)}
                        cursor="pointer"
                      >
                        {column.label}{" "}
                        {sortConfig.key === column.accessor
                          ? sortConfig.direction === "asc"
                            ? "↑"
                            : "↓"
                          : ""}
                      </Th>
                    ))}
                    {showActions && (showEditButton || showResubmitButton) && (
                      <Th
                        position="sticky"
                        right={0}
                        background={bgColor}
                        zIndex={3}
                        minWidth="150px"
                      >
                        Actions
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {data.length === 0 ? (
                    <Tr>
                      <Td colSpan={10} textAlign="center" py={10}>
                        <Flex
                          direction="column"
                          align="center"
                          justify="center"
                          gap={6}
                        >
                          <Box
                            as="span"
                            role="img"
                            aria-label="no-data"
                            fontSize="4xl"
                          >
                            📭
                          </Box>
                          <Text fontSize="lg" color="gray.500">
                            No records found.
                          </Text>
                        </Flex>
                      </Td>
                    </Tr>
                  ) : (
                    data.map((item: any, index: number) => (
                      <Tr key={index} position="relative" zIndex={0}>
                        {columns.map((column: any, index: number) => (
                          <Td key={index} zIndex={1}>
                          {column.Cell ? (
                            column.Cell({ value: item[column.accessor], row: item })
                          ) : column.accessor === "accounting_date" ? (
                            new Date(item[column.accessor]).toLocaleDateString()
                          ) : column.accessor === "status_meaning" ? (
                            <Tooltip
                              label={getStatusBadge(item[column.accessor])}
                              aria-label="Status Tooltip"
                              placement="top-end"
                            >
                              <Badge colorScheme={getBadgeColor(item[column.accessor])}>
                                {item[column.accessor]}
                              </Badge>
                            </Tooltip>
                          ) : column.accessor === "fin_transaction_concept" ||
                            column.accessor === "invoicefin_transaction_concept" ? (
                            <Tooltip
                              label={getConceptTooltip(item[column.accessor])}
                              aria-label="Concept Tooltip"
                              placement="top-end"
                            >
                              <span>{item[column.accessor]}</span>
                            </Tooltip>
                          ) : (
                            item[column.accessor]
                          )}
                        </Td>
                        ))}

                        {showActions && (
                          <Td
                            position="sticky"
                            right={0}
                            background={bgColor}
                            zIndex={2}
                            minWidth="150px"
                          >
                            {item.external_sync_status === "99" &&
                              showEditButton && (
                                <Button
                                  colorScheme="orange"
                                  size="xs"
                                  variant={"ghost"}
                                  borderRadius={"full"}
                                  onClick={() => handleEditClick(item)}
                                  ml={2}
                                  isDisabled={
                                    editedRecords.has(item.id) ||
                                    loadingStates[item.id]
                                  }
                                >
                                  {loadingStates[item.id] &&
                                  !editedRecords.has(item.id) ? (
                                    <Spinner size="xs" />
                                  ) : (
                                    "Edit"
                                  )}
                                </Button>
                              )}
                            {item.external_sync_status === "99" &&
                              showResubmitButton && (
                                <Button
                                  colorScheme="green"
                                  size="xs"
                                  variant={"ghost"}
                                  borderRadius={"full"}
                                  onClick={() => handleResubmitClick(item)}
                                  ml={2}
                                  isDisabled={
                                    !editedRecords.has(item.id) ||
                                    loadingStates[item.id]
                                  }
                                >
                                  {loadingStates[item.id] &&
                                  editedRecords.has(item.id) ? (
                                    <Spinner size="xs" />
                                  ) : (
                                    "Resubmit"
                                  )}
                                </Button>
                              )}

                            {showIcons && (
                              <>
                                <Tooltip label="View More about Item">
                                  <Button
                                    colorScheme="blue"
                                    size="xs"
                                    variant={"ghost"}
                                    borderRadius={"full"}
                                    onClick={() => handleViewMore(item)}
                                  >
                                    <EyeIcon />
                                  </Button>
                                </Tooltip>

                                {showDownloadButton && (
                                  <Menu placement="bottom-end">
                                    <Tooltip label="Download Item Details">
                                      <MenuButton
                                        as={Button}
                                        colorScheme="green"
                                        size="xs"
                                        variant="ghost"
                                        borderRadius="full"
                                        isLoading={
                                          itemDownloadLoading === item.pan_id
                                        }
                                        isDisabled={
                                          itemDownloadLoading !== null
                                        }
                                      >
                                        <FileDownIcon />
                                      </MenuButton>
                                    </Tooltip>

                                    <Portal>
                                      <MenuList zIndex="1000">
                                        <MenuItem
                                          onClick={() =>
                                            handleDownloadFormat(item, "excel")
                                          }
                                        >
                                          Excel
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() =>
                                            handleDownloadFormat(item, "csv")
                                          }
                                        >
                                          CSV
                                        </MenuItem>
                                      </MenuList>
                                    </Portal>
                                  </Menu>
                                )}
                              </>
                            )}
                          </Td>
                        )}
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        )}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={6}
          gap={"2"}
        >
          <Button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ArrowLeftIcon />
          </Button>
          <Button
            onClick={() => setCurrentPage(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <ArrowBackIcon />
          </Button>

          <Box>
            <Text mt={"4"} fontWeight={"bold"}>
              Page {currentPage} of {totalPages}
            </Text>
            {/* <Select
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </Select> */}
          </Box>

          <Button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <ArrowForwardIcon />
          </Button>
          <Button
            onClick={() => setCurrentPage(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <ArrowRightIcon />
          </Button>
        </Box>
      </TableContainer>
      {/* Render the Edit modal */}
      {selectedItem && (
        <EditTransactionModal
          isOpen={isOpen}
          onClose={onClose}
          initialValues={selectedItem}
          onSubmit={handleEditSubmit}
        />
      )}
    </>
  );
};

export default DataTableFour;
