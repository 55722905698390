import { Box, Button, Card, CardBody, Flex, Heading, Input, Spacer, Text } from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const Location = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleLocation = useStore((state) => state.fetchSingleLocation);
  const posLocation = useStore((state) => state.location);

  const locationsType = [
    { id: 1, title: "Informal", value: 0 },
    { id: 2, title: "Bus/Taxi Stops", value: 1 },
    { id: 3, title: "Facility", value: 2 },
    { id: 4, title: "Route", value: 3 },
  ];

  const locationsFacility = [
    {
      id: 1,
      title: "Minibus-Taxi Rank",
      value: "TR",
    },
    {
      id: 2,
      title: "Minibus-Taxi Holding",
      value: "TH",
    },
    {
      id: 3,
      title: "Bus Terminal",
      value: "BT",
    },
    {
      id: 4,
      title: "Bus Depot",
      value: "BD",
    },
    {
      id: 5,
      title: "Bus Holding",
      value: "BH",
    },
    {
      id: 6,
      title: "Ralway Station",
      value: "RS",
    },
    {
      id: 7,
      title: "Park & Ride",
      value: "PR",
    },
    {
      id: 8,
      title: "Metered Taxi Rank",
      value: "MR",
    },
    {
      id: 9,
      title: "Airport",
      value: "AP",
    },
    {
      id: 10,
      title: "Seaport",
      value: "SP",
    },
   ]

  const getTypeTitle = (typeValue: any) => {
    const type = locationsType.find((item) => item.value === typeValue);
    return type ? type.title : "Unknown Type";
  };

  const getFacilityTitle = (facilityValue: any) => { 
    const facility = locationsFacility.find((item) => item.id === parseInt(facilityValue));
    return facility ? facility.title : "Unknown Facility";
  }

  useEffect(() => {
    if (id) {
      fetchSingleLocation({ id: id });
    }
  }, [fetchSingleLocation, id]);

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Location - {posLocation ? posLocation?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box marginY={'8'}>
      <Card align="center">
          <CardBody>
          <Flex direction={'column'} gap={'3'} justifyContent={'center'}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Name: </Heading>
                <Input readOnly py="2" value={posLocation?.name}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Longitude: </Heading>
                <Input readOnly py="2" value={posLocation?.longitude}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Latitude: </Heading>
                <Input readOnly py="2" value={posLocation?.latitude}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Type:
                </Heading>
                <Input
                  readOnly
                  py="2"
                  value={getTypeTitle(posLocation?.type)}
                />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Facility: </Heading>
                <Input readOnly py="2" value={getFacilityTitle(posLocation?.facility)}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Created: </Heading>
                <Input readOnly py="2" value={posLocation?.created_ts}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Updated: </Heading>
                <Input readOnly py="2" value={posLocation?.updated_ts ? posLocation?.updated_ts : 'N/A'}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Created By: </Heading>
                <Input readOnly py="2" value={posLocation?.created_by ? posLocation?.created_by : 'N/A'}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Updated By: </Heading>
                <Input readOnly py="2" value={posLocation?.modified_by ? posLocation?.modified_by : 'N/A'}/>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Location;
