import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import ImageHeader from "../components/ImageHeader";
import LiveRoastHeader from "../components/LiveRoastHeader";
import LiveRoastNav from "../components/LiveRoastNav";
import SideBarNav from "../components/SideBarNav";

export default function AdminAppShell({ children }: any) {
  let hasImageHeader = true;
  let hasRightNav = false;

  return (
    <>
      <Flex
        flexDir="row"
        flex={1}
        w="100vw"
        flexBasis="100%"
        minW="100vw"
        minH="100vh"
        overflow="auto"
      >
        <SideBarNav />
        <Flex flexDir="column" overflow="hidden" as="main">
          {hasImageHeader ? <ImageHeader /> : <LiveRoastHeader />}
          <Box overflow="auto" px={4}>
            {children}
          </Box>
          {/* <Outlet /> */}
        </Flex>
        {hasRightNav && <LiveRoastNav />}
      </Flex>
    </>
  );
}
