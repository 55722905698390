import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { useLocation, useNavigate } from "react-router-dom";

const Licensing = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const posOperators = useStore((state) => state.operators);
  const fetchOperators = useStore((state) => state.fetchOperators);
  const posDevices = useStore((state) => state.devices);
  const fetchDevices = useStore((state) => state.fetchDevices);
  const fetchSingleDevice = useStore((state) => state.fetchSingleDevice);
  const posDevice = useStore((state) => state.device);
  const generateLicense = useStore((state) => state.generateLicense);
  const checkLicense = useStore((state) => state.checkLicense);
  const fetchDeviceByMacAddress = useStore(
    (state) => state.fetchSingleDeviceByMacAddress
  );
  const fetchSingleOperator = useStore((state) => state.fetchSingleOperator);
  const removeLicense = useStore((state) => state.removelicense);
  const toast = useToast();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isValid, setIsValid] = useState(false);
  const [ptoCode, setPtoCode] = useState("");
  const [deviceMac, setDeviceMac] = useState("");
  const [errors, setErrors] = useState<any>({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [licensingDetails, setLicensingDetails] = useState<any>(null);
  const [removedLicense, setRemovedLicense] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState<string>("");
  const [portalDevice, setPortalDevice] = useState<any>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredDevices = posDevices.filter((item: any) =>
    item.serialno.toLowerCase().includes(searchTerm)
  );

  const validate = () => {
    if (!ptoCode) {
      errors.pto_code = "Operator Code is required";
      setIsValid(true);
    }

    if (!deviceMac) {
      errors.device_mac = "Device Mac is required";
      setIsValid(true);
    }

    setTimeout(() => {
      setErrors({});
    }, 5000);
  };

  const handlePtoChange = (e: any) => {
    setPtoCode(e.target.value);
  };

  const handleDeviceChange = (e: any) => {
    setDeviceMac(e.target.value);
  };

  const removeLicenseFound = () => {
    // DELETE THE record and dont chanef the status
    console.log(removeLicense);
    setIsLoading(true);
    removeLicense({
      pto_id: removedLicense?.pto_id,
      device_id: removedLicense?.device_id,
    }).then((res: any) => {
      if (res) {
        if (res?.message === "License deleted successfully") {
          // generate the license
          generateLicense(licensingDetails).then((res: any) => {
            if (res?.Response) {
              toast({
                title: "Success",
                description: "Successfull linkage",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              setIsLoading(false);
              onClose();
            } else if (res?.error) {
              toast({
                title: "Error",
                description:
                  "An Error occured! Please check the fields you are passing.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
              setIsLoading(false);
              onClose();
            }
          });
        }
      }
    });
  };

  const handleSubmit = () => {
    validate();

    const deviceMacAddress = posDevice?.mac_address || deviceMac;

    if (ptoCode && deviceMacAddress) {
      console.log(ptoCode, deviceMac);
      const licensingDetails = {
        pto_code: ptoCode,
        device_mac: deviceMacAddress,
      };

      setLoading(true);

      setLicensingDetails(licensingDetails);

      // get the device by mac address
      fetchDeviceByMacAddress({ device_mac_address: deviceMacAddress }).then(
        (res: any) => {
          if (res) {
            if (res?.id) {
              // hit the check device license endpoint
              checkLicense({ device_id: res?.id }).then((res: any) => {
                console.log(res);
                if (res) {
                  if (res?.license_key) {
                    let x = res;
                    setRemovedLicense(x);
                    setLoading(false);
                    // using the pto_id returned fetch the pto details
                    fetchSingleOperator({ id: res?.pto_id }).then(
                      (res: any) => {
                        // get the pto code and check if they match the one being passed in the form

                        if (res) {
                          if (res?.code) {
                            if (res?.code === ptoCode) {
                              console.log(
                                "it matches thus not reuiring any linkage."
                              );
                              toast({
                                title: "Success",
                                description:
                                  "Linkage already exists between the device and the operator",
                                status: "success",
                                duration: 5000,
                                isClosable: true,
                              });
                              setLoading(false);
                            } else {
                              console.log(
                                "create a function to make the status field inactive i.e is for the license row returned"
                              );
                              console.log(res);

                              // Launch modal to ask the user whether they would want to proceed with the operation

                              onOpen();
                              setLoading(false);
                            }
                          }
                        }
                      }
                    );
                  } else {
                    // generate the license
                    generateLicense(licensingDetails).then((res: any) => {
                      if (res?.Response) {
                        toast({
                          title: "Success",
                          description: "Successfull linkage",
                          status: "success",
                          duration: 5000,
                          isClosable: true,
                        });
                        setLoading(false);
                      } else if (res?.error) {
                        console.log(res);
                        toast({
                          title: "Error",
                          description:
                            "An Error occured! Please check the fields you are passing.",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        setLoading(false);
                      } else if (res?.detail) {
                        toast({
                          title: "Error",
                          description: res?.detail,
                          status: "warning",
                          duration: 5000,
                          isClosable: true,
                        });
                        setLoading(false);
                      }
                    });
                  }
                } else {
                  // generate the license
                  generateLicense(licensingDetails).then((res: any) => {
                    if (res?.Response) {
                      toast({
                        title: "Success",
                        description: "Successfull linkage",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(false);
                    } else if (res?.error) {
                      console.log(res);
                      toast({
                        title: "Error",
                        description:
                          "An Error occured! Please check the fields you are passing.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(false);
                    } else if (res?.detail) {
                      toast({
                        title: "Error",
                        description: res?.detail,
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(false);
                    }
                  });
                }
              });
            }
          }
        }
      );
    }

    if (ptoCode && posDevice?.mac_address) {
      const licensingDetails = {
        pto_code: ptoCode,
        device_mac: posDevice?.mac_address,
      };

      setLoading(true);

      setLicensingDetails(licensingDetails);

      // get the device by mac address
      fetchDeviceByMacAddress({
        device_mac_address: posDevice?.mac_address,
      }).then((res: any) => {
        if (res) {
          if (res?.id) {
            // hit the check device license endpoint
            checkLicense({ device_id: res?.id }).then((res: any) => {
              console.log(res);
              if (res) {
                if (res?.license_key) {
                  let x = res;
                  setRemovedLicense(x);
                  setLoading(false);
                  // using the pto_id returned fetch the pto details
                  fetchSingleOperator({ id: res?.pto_id }).then((res: any) => {
                    // get the pto code and check if they match the one being passed in the form

                    if (res) {
                      if (res?.code) {
                        if (res?.code === ptoCode) {
                          console.log(
                            "it matches thus not reuiring any linkage."
                          );
                          toast({
                            title: "Success",
                            description:
                              "Linkage already exists between the device and the operator",
                            status: "success",
                            duration: 5000,
                            isClosable: true,
                          });
                          setLoading(false);
                        } else {
                          console.log(
                            "create a function to make the status field inactive i.e is for the license row returned"
                          );
                          console.log(res);

                          // Launch modal to ask the user whether they would want to proceed with the operation

                          onOpen();
                          setLoading(false);
                        }
                      }
                    }
                  });
                } else {
                  // generate the license
                  generateLicense(licensingDetails).then((res: any) => {
                    if (res?.Response) {
                      toast({
                        title: "Success",
                        description: "Successfull linkage",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(false);
                      fetchDevices();
                      navigate("/admin/devices");
                    } else if (res?.error) {
                      toast({
                        title: "Error",
                        description:
                          "An Error occured! Please check the fields you are passing.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(false);
                      fetchDevices();
                      navigate("/admin/devices");
                    } else if (res?.detail) {
                      toast({
                        title: "Error",
                        description: res?.detail,
                        status: "warning",
                        duration: 5000,
                        isClosable: true,
                      });
                      setLoading(false);
                    }
                  });
                }
              } else {
                // generate the license
                generateLicense(licensingDetails).then((res: any) => {
                  if (res?.Response) {
                    toast({
                      title: "Success",
                      description: "Successfull linkage",
                      status: "success",
                      duration: 5000,
                      isClosable: true,
                    });
                    setLoading(false);
                  } else if (res?.error) {
                    toast({
                      title: "Error",
                      description:
                        "An Error occured! Please check the fields you are passing.",
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                    setLoading(false);
                  } else if (res?.detail) {
                    toast({
                      title: "Error",
                      description: res?.detail,
                      status: "warning",
                      duration: 5000,
                      isClosable: true,
                    });
                    setLoading(false);
                  }
                });
              }
            });
          }
        }
      });
    }
  };

  const handleDeviceSelect = (serialno: string) => {
    setSearchTerm(serialno); // Update input with selected value
    handleDeviceChange({ target: { value: serialno } }); // Call existing handler
  };

  useEffect(() => {
    fetchDevices();
    fetchOperators();

    const searchParams = new URLSearchParams(location.search);

    // Get specific query parameters
    const param1 = searchParams.get("deviceId");

    if (param1) {
      setParams(param1);
      fetchSingleDevice({ id: param1 }).then((device: any) => {
        setPortalDevice(device);
      });
    }
  }, [fetchDevices, fetchOperators, location, fetchSingleDevice]);

  return (
    <AdminAppShell>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Licensing</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text
              textAlign={"center"}
              fontWeight={"bold"}
              py={"8"}
              fontSize={"xl"}
            >
              There is an existing license. Are you sure that you want to
              proceed?
            </Text>

            <ButtonGroup display={"flex"} justifyContent={"space-around"}>
              <Button
                colorScheme={"orange"}
                isDisabled={isLoading}
                onClick={() => onClose()}
              >
                Cancel
              </Button>
              <Button colorScheme={"green"} onClick={removeLicenseFound}>
                {isLoading ? (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    colorScheme="green"
                  />
                ) : (
                  <>Proceed</>
                )}
              </Button>
            </ButtonGroup>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Card align="center" h={"70vh"}>
        <CardHeader>
          <Heading size="md">
            Link {params ? `${posDevice?.name}` : `a device`} to an Operator
          </Heading>
        </CardHeader>
        <CardBody w="50vw">
          <Stack direction="column" spacing={6}>
            <FormControl>
              <FormLabel>Operator Code</FormLabel>
              <Select
                placeholder="Select an operator code"
                id="pto_code"
                name="ptoCode"
                onChange={handlePtoChange}
                required
                borderColor={errors?.pto_code && `red`}
              >
                {posOperators &&
                  posOperators.map((item: any, index: number) => (
                    <option key={index} value={item.code}>
                      {item.code} - {item.name}
                    </option>
                  ))}
              </Select>
              <Text color={"red"}>{errors.pto_code}</Text>
            </FormControl>

            {params ? (
              <FormControl>
                <FormLabel>
                  Device <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  value={posDevice?.mac_address}
                  isDisabled={posDevice?.mac_address}
                  id="device_mac"
                />
              </FormControl>
            ) : (
              <Stack spacing={4}>
                {/* Filtered Device Dropdown */}
                <FormControl>
                  <FormLabel>Device Serial Number</FormLabel>
                  <Input
                    placeholder="Search device serial number"
                    value={searchTerm}
                    onChange={(e) =>
                      setSearchTerm(e.target.value.toLowerCase())
                    }
                    borderColor={errors?.device_mac && `red`}
                  />
                  { filteredDevices.length > 0 && (
                    <Box
                    border="1px solid #ccc"
                    borderRadius="md"
                    boxShadow="md"
                    zIndex="1"
                    maxH="160px"
                    overflowY="auto"
                    w="100%"
                    mt={2}
                  >
                    {filteredDevices
                      .slice(0, 5)
                      .map((item: any, index: number) => (
                        <Box
                          key={index}
                          px={4}
                          py={2}
                          _hover={{ cursor: "pointer" }}
                          onClick={() => handleDeviceSelect(item.serialno)}
                        >
                          {`${item.name} - ${item.serialno}`}{" "}
                          {item.is_licensed ? <> - Licensed</> : <></>}
                        </Box>
                      ))}
                  </Box>
                  )}
                  <Text color={"red"}>{errors.device_mac}</Text>
                </FormControl>
              </Stack>
            )}

            <Stack direction="row" spacing={4}>
              {deviceMac && ptoCode && (
                <Button
                  width="100%"
                  colorScheme="teal"
                  variant="solid"
                  type="submit"
                  onClick={handleSubmit}
                >
                  {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                </Button>
              )}
            </Stack>
          </Stack>
        </CardBody>
      </Card>
    </AdminAppShell>
  );
};

export default Licensing;
