import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Stack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Eye, EyeOff, PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useStore } from "../../hooks";
import Datatable from "../../components/DataTable";
import { useNavigate } from "react-router-dom";

const UserManagement = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const toast = useToast();
  const router = useNavigate();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null);
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const isLoading = useStore((state) => state.loading);
  const posInternalUsers = useStore((state) => state.internalUsers);
  const fetchAllUsers = useStore((state) => state.fetchAllUsers);
  const removeInternalUser = useStore((state) => state.removeInternalUser);
  const fetchRoles = useStore((state) => state.fetchAllRoles);
  const posRoles = useStore((state) => state.roles);
  const createInternalUser = useStore((state) => state.createInternalUser);

  useEffect(() => {
    fetchAllUsers();
    fetchRoles();
  }, []);

  const handleUpdate = (item: any) => {
    setUser(item);
    if (user) {
      router(`/admin/user-management/${item.id}`);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/user-management/edit/${item.id}`);
  };

  const removeUser = async (item: any) => {
    let response = await removeInternalUser(item);

    if (response) {
      fetchAllUsers();
    }
  };

  // Formik validation schema
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .max(50, "First name must be at most 50 characters")
      .matches(
        /^[a-zA-Z\s]*$/,
        "First name cannot contain digits or special characters"
      )
      .required("First name is required"),
    last_name: Yup.string()
      .max(50, "Last name must be at most 50 characters")
      .matches(
        /^[a-zA-Z\s]*$/,
        "Last name cannot contain digits or special characters"
      )
      .required("Last name is required"),
    email: Yup.string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .required("Email is required"),
    role_id: Yup.string().required("Role is required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .matches(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Confirm password is required"),
    is_active: Yup.boolean(),
  });

  const handleUserSubmit = (values: any) => {
    setLoading(true);

    createInternalUser(values).then((res: any) => {
      if (res.detail  === "User with such an email already exists") {
        toast({
          title: "Error.",
          description: "User with such an email already exists.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
      } else {
        toast({
          title: "User created.",
          description: "New user has been successfully created.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
        fetchAllUsers();
      }
    });
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Users
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setUser(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create User
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
          {overlay}
          <ModalContent>
            <ModalHeader>Create a New User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  role_id: "",
                  password: "",
                  confirm_password: "",
                  is_active: true,
                }}
                validationSchema={validationSchema}
                onSubmit={handleUserSubmit}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Stack spacing={4}>
                      <Field name="first_name">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.first_name && form.touched.first_name
                            }
                          >
                            <FormLabel htmlFor="first_name">
                              First Name
                            </FormLabel>
                            <Input
                              {...field}
                              id="first_name"
                              placeholder="First Name"
                            />
                            <FormErrorMessage>
                              {form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="last_name">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.last_name && form.touched.last_name
                            }
                          >
                            <FormLabel htmlFor="last_name">Last Name</FormLabel>
                            <Input
                              {...field}
                              id="last_name"
                              placeholder="Last Name"
                            />
                            <FormErrorMessage>
                              {form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="email">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                          >
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <Input {...field} id="email" placeholder="Email" />
                            <FormErrorMessage>
                              {form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="role_id">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.role_id && form.touched.role_id
                            }
                          >
                            <FormLabel htmlFor="role_id">Role</FormLabel>
                            <Select
                              {...field}
                              placeholder="Select role"
                              id="role_id"
                            >
                              {posRoles.map((role: any, idx: number) => (
                                <option key={idx} value={role.id}>
                                  {role.title}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.role_id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="password">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <InputGroup>
                              <Input
                                {...field}
                                type={showPassword ? "text" : "password"}
                                id="password"
                                placeholder="Enter password"
                              />
                              <InputRightElement
                                onClick={() => setShowPassword(!showPassword)}
                                cursor="pointer"
                              >
                                {showPassword ? <EyeOff /> : <Eye />}
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="confirm_password">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.confirm_password &&
                              form.touched.confirm_password
                            }
                          >
                            <FormLabel htmlFor="confirm_password">
                              Confirm Password
                            </FormLabel>
                            <InputGroup>
                              <Input
                                {...field}
                                type={showConfirmPassword ? "text" : "password"}
                                id="confirm_password"
                                placeholder="Confirm password"
                              />
                              <InputRightElement
                                onClick={() =>
                                  setShowConfirmPassword(!showConfirmPassword)
                                }
                                cursor="pointer"
                              >
                                {showConfirmPassword ? <EyeOff /> : <Eye />}
                              </InputRightElement>
                            </InputGroup>
                            <FormErrorMessage>
                              {form.errors.confirm_password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="is_active" type="checkbox">
                        {({ field }: any) => (
                          <FormControl>
                            <Checkbox
                              {...field}
                              id="is_active"
                              colorScheme="green"
                              isChecked={field.value}
                            >
                              Is Active
                            </Checkbox>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>

                    <Button
                      colorScheme="green"
                      isLoading={loading}
                      type="submit"
                      w="full"
                      mt={4}
                    >
                      Save
                    </Button>
                  </Form>
                )}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      <Box py={6}>
        <Datatable
          headerColumns={[
            { Header: "First Name", accessorKey: "first_name" },
            { Header: "Last Name", accessorKey: "last_name" },
            { Header: "Email", accessorKey: "email" },
            { Header: "Is Active", accessorKey: "is_active" },
          ]}
          data={posInternalUsers}
          dataTableHeading={"Internal Profiles"}
          handleDelete={removeUser}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
      </Box>
    </AdminAppShell>
  );
};

export default UserManagement;
