import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
import dayjs from "dayjs";

export const ViewExceptions = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const downloadExceptionReport = useReconStore(
    (state) => state.downloadExceptionReport
  );
  const downloadExceptionCSVsFileContent = useReconStore(
    (state) => state.downloadExceptionCSVsFileContent
  );
  const columns = [
    {
      Header: "File Name",
      accessor: "file_name",
      Cell: ({ value }: { value: string }) => {
        // Remove "exp_csvs/" from the file name
        return value.replace(/^exp_csvs\//, "");
      },
    },
    { Header: "Size", accessor: "size" },
    { Header: "Records", accessor: "records" },
    { Header: "Record Type", accessor: "record_type" },
    {
      Header: "Created Time",
      accessor: "created_ts",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
  ];

  const handleMoreClick = (item: any) => {
    navigate(`/admin/exceptions/${item.file_name.split("/")[1]}`);
  };

  const handleDownloadClick = (item: any) => {
    // Extract filename from the path (remove exp_csvs/ prefix)
    const fileName = item.file_name.replace(/^exp_csvs\//, "");
    
    downloadExceptionCSVsFileContent({
      file_name: fileName
    })
    .then(() => {
      toast({
        title: "Download Successful",
        description: "Your file has been downloaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    })
    .catch((error: any) => {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Download Failed",
            description: data.detail || "Invalid download parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "File Not Found",
            description: "The requested file could not be found.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Please log in to download files.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Download Failed",
            description: "An unexpected error occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    });
  };

  const handleDownloadFunc = async (params: any) => {
    const { details } = params;
    
    // Extract filename from the path (remove exp_csvs/ prefix)
    const fileName = details.file_name.replace(/^exp_csvs\//, "");

    try {
      await downloadExceptionCSVsFileContent({
        file_name: fileName
      });

      // Show a success toast
      toast({
        title: "Download Successful",
        description: "Your file has been downloaded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Download Failed",
            description: data.detail || "Invalid download parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "File Not Found",
            description: "The requested file could not be found.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Please log in to download files.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Download Failed",
            description: "An unexpected error occurred.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="3xl" fontWeight="bold">
          Exceptions Report
        </Box>
      </Flex>

      <Box py={6}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_exceptions`}
          tokenKey="jwt_access_token"
          downloadFunc={handleDownloadFunc}
          showActions={true}
          showExports={false}
          handleMore={handleMoreClick}
          handleDownloadFunc={handleDownloadClick}
          showDownloadButton={true}
          showExcelDownload={false}
          showCSVDownload={true}
          isHyphen={true}
        />
      </Box>
    </AdminAppShell>
  );
};
