import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Datatable from "../../../components/DataTable";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const validate = (values: any) => {
  const errors: any = {};

  if (!values.pto_id) {
    errors.pto_id = "Operator is required";
  }

  if (!values.route_id) {
    errors.route_id = "Route ID is required";
  }

  if (!values.location_id) {
    errors.location_id = "Location ID is required";
  }

  if (!values.route_direction) {
    errors.route_direction = "Route Direction is required";
  }

  return errors;
};

const routeDirections = [
  {
    id: 1,
    title: "FORWARD",
    value: "FORWARD",
  },
  {
    id: 2,
    title: "REVERSE",
    value: "REVERSE",
  },
];

const Route = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const { id } = useParams();
  const navigate = useNavigate();

  const [initialValues, setInitialValues] = useState({
    id: "",
    location_seq: 0,
    route_direction: "",
    enabled: true,
    route_id: id,
    location_id: "",
    pto_id: "",
  });

  const fetchSingleRoute = useStore((state) => state.fetchSingleRoute);
  const fetchAllStopsFromRoute = useStore(
    (state) => state.fetchStopsFromARoute
  );
  const fetchOperators = useStore((state) => state.fetchOperators);
  const posOperators = useStore((state) => state.operators);
  const fetchRoutes = useStore((state) => state.fetchRoutes);
  const posRoutes = useStore((state) => state.routes);
  // const posStops = useStore((state) => state.stops);
  const fetchLocations = useStore((state) => state.fetchLocations);
  // const posLocations = useStore((state) => state.locations);
  const isLoading = useStore((state) => state.loading);

  const posRoute = useStore((state) => state.route);
  const createStop = useStore((state) => state.createStop);
  const removeStop = useStore((state) => state.removeStop);
  const updateStop = useStore((state) => state.updateStop);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [stop, setStop] = useState<any>(null);
  const [singleRouteStops, setSingleRouteStops] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const toast = useToast();
  // State for viewing a stop
  const [viewStop, setViewStop] = useState<any>(null);

  // Disclosure for the view modal
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();

  // useEffect(() => {
  //   if (id) {
  //     fetchOperators();
  //     fetchRoutes();
  //     fetchSingleRoute({ id: id }).then((res: any) => {
  //       setSingleRouteStops(res?.stops);
  //     });
  //     fetchAllStopsFromRoute({ id: id });
  //     fetchLocations();
  //   }
  // }, [
  //   fetchSingleRoute,
  //   fetchOperators,
  //   fetchRoutes,
  //   fetchLocations,
  //   fetchAllStopsFromRoute,
  //   id,
  // ]);

  useEffect(() => {
    if (!id) return;

    // These fetches are independent and can be called without waiting for each other
    fetchOperators();
    fetchRoutes();
    fetchAllStopsFromRoute({ id });

    // Fetch single route and then do something with the response
    fetchSingleRoute({ id }).then((res: any) => {
      setSingleRouteStops(res?.stops);

      setInitialValues((prevValues) => ({
        ...prevValues,
        pto_id: res.pto_id || "",
      }));
      // Fetch locations and filter based on the response from fetchSingleRoute
      fetchLocations().then((locations: any) => {
        if (res?.start_location && res?.end_location) {
          const myLocs = locations.filter(
            (location: any) =>
              location.id !== res?.start_location.id &&
              location.id !== res?.end_location.id
          );
          // Assuming there's a state setter for filtered locations
          setFilteredLocations(myLocs);
        }
      });
    });
  }, [
    id,
    fetchSingleRoute,
    fetchOperators,
    fetchRoutes,
    fetchAllStopsFromRoute,
    fetchLocations,
  ]);

  const handleSubmit = (stopDetails: any) => {
    setLoading(true);
    createStop(stopDetails).then((res: any) => {
      console.log(res);
      if (res.route_direction) {
        setLoading(false);
        onClose();
        // fetchAllStopsFromRoute({ id: id });
        fetchSingleRoute({ id }).then((res: any) => {
          setSingleRouteStops(res?.stops);
        });
        toast({
          title: "Success",
          description: "Stop was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const submitUpdate = async (updatedData: any) => {
    try {
      setLoading(true);
      console.log("handle update submit", updatedData);

      const res = await updateStop(updatedData);
      if (res.id) {
        setLoading(false);
        onClose();
        const updatedRoute = await fetchSingleRoute({ id });
        setSingleRouteStops(updatedRoute?.stops || []);
        toast({
          title: "Success",
          description: "Stop was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setLoading(false);
        toast({
          title: "Error",
          description: "Failed to update the stop.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Update Stop Error:", error);
    }
  };

  const deleteStop = (stop: any, closable: () => void) => {
    // hit the apis to remove the stops data
    removeStop(stop).then(() => {
      fetchAllStopsFromRoute({ id: id }).then((res: any) => {
        setSingleRouteStops(res);
        closable();
      });
    });
  };

  const handleUpdate = (item: any) => {
    // console.log("posRoute", posRoute);
    // console.log("ID", item)
    // Override pto_id with posRoute.pto_id to ensure consistency
    const updatedItem = { ...item, pto_id: posRoute?.pto_id, location_id: item.location.id };

    console.log("Updated Item", updatedItem);

    setStop(updatedItem); // Set the stop with the overridden pto_id
    onOpen(); // Open the modal
  };

  const handleViewMore = (stop: any) => {
    setViewStop(stop);
    onViewOpen();
  };

  return (
    <AdminAppShell>
      {
        posRoute ? (
          <>
            <Flex alignItems={"center"}>
        {/* <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Text fontSize="3xl" fontWeight="bold">
            Route - {posRoute ? posRoute?.name : ""}
          </Text>
        </Flex> */}
        <Spacer />
        <Button size={"sm"} colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box marginY={"10"}>
        <Flex direction={"row"} gap={"6"}>
          <Box w={"40%"}>
            <Card>
              <CardHeader>
                <Text fontSize={"2xl"} fontWeight={"bolder"}>
                  Route Details
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Name:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posRoute?.name} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Code:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posRoute?.code} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Start Location:{" "}
                    </Heading>
                    <Input
                      readOnly
                      py="2"
                      value={posRoute?.start_location?.name}
                    />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      End Location:{" "}
                    </Heading>
                    <Input
                      readOnly
                      py="2"
                      value={posRoute?.end_location?.name}
                    />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Created:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posRoute?.created_ts} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Updated:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posRoute?.updated_ts ? posRoute?.updated_ts : "N/A" } />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Created By:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posRoute?.created_by ? posRoute?.created_by : "N/A" } />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Updated By:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posRoute?.modified_by ? posRoute?.modified_by : "N/A" } />
                  </Flex>

                </Flex>
              </CardBody>
            </Card>
          </Box>
          <Box w={"60%"}>
            <Text fontSize={"2xl"} fontWeight={"bolder"} marginY={"4"}>
              Stops Details
            </Text>
            <Flex>
              <Button
                colorScheme={"green"}
                onClick={() => {
                  setStop(null);
                  setOverlay(<OverlayTwo />);
                  onOpen();
                }}
                mb={4}
              >{`Add a Stop on ${posRoute?.name} Route`}</Button>
              <Spacer />

              <Modal isCentered isOpen={isOpen} onClose={onClose} size={`lg`}>
                {overlay}
                <ModalContent>
                  <ModalHeader>
                    {stop ? "Update Existing Stop" : "Create a New Stop"}
                  </ModalHeader>
                  <ModalCloseButton />
                  <ModalBody>
                    <Formik
                      initialValues={
                        stop
                          ? { ...stop, pto_id: posRoute?.pto_id} // Override pto_id when editing
                          : initialValues
                      }
                      enableReinitialize
                      validate={validate}
                      onSubmit={stop ? submitUpdate : handleSubmit}
                    >
                      <Form>
                        <Stack w="100%" direction="column" spacing={8}>
                          <Field name="id">
                            {({ field }: any) => (
                              <Input {...field} type="hidden" />
                            )}
                          </Field>
                          <Field name="pto_id">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.pto_id && form.touched.pto_id
                                }
                              >
                                <FormLabel>Operator</FormLabel>
                                <Select
                                  {...field}
                                  placeholder="Select an operator"
                                  type="pto_id"
                                  id="pto_id"
                                  disabled
                                >
                                  {posOperators &&
                                    posOperators.map(
                                      (item: any, index: number) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                </Select>
                                <FormErrorMessage>
                                  {form.errors.pto_id &&
                                    form.touched.pto_id &&
                                    form.errors.pto_id}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="route_id">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.pto_id && form.touched.pto_id
                                }
                              >
                                <FormLabel>Route</FormLabel>
                                <Select
                                  {...field}
                                  placeholder="Select a route"
                                  type="route_id"
                                  id="route_id"
                                  disabled={id}
                                >
                                  {posRoutes &&
                                    posRoutes.map(
                                      (item: any, index: number) => (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    )}
                                </Select>
                                <FormErrorMessage>
                                  {form.errors.route_id &&
                                    form.touched.route_id &&
                                    form.errors.route_id}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="location_id">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.location_id &&
                                  form.touched.location_id
                                }
                              >
                                <FormLabel>Location</FormLabel>
                                <Select
                                  {...field}
                                  placeholder="Select a location"
                                  type="location_id"
                                  id="location_id"
                                >
                                  {filteredLocations &&
                                    filteredLocations.map(
                                      (item: any, index: number) => (
                                        <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                      )
                                    )}
                                </Select>
                                <FormErrorMessage>
                                  {form.errors.location_id &&
                                    form.touched.location_id &&
                                    form.errors.location_id}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="route_direction">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.route_direction &&
                                  form.touched.route_direction
                                }
                              >
                                <FormLabel>Route Direction</FormLabel>
                                <Select
                                  {...field}
                                  placeholder="Select a route direction"
                                  type="route_direction"
                                  id="route_direction"
                                >
                                  {routeDirections &&
                                    routeDirections.map(
                                      (item: any, index: number) => (
                                        <option key={index} value={item.value}>
                                          {item.title}
                                        </option>
                                      )
                                    )}
                                </Select>
                                <FormErrorMessage>
                                  {form.errors.route_direction &&
                                    form.touched.route_direction &&
                                    form.errors.route_direction}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>

                          <Field name="enabled">
                            {({ field, form }: any) => (
                              <FormControl
                                isInvalid={
                                  form.errors.enabled && form.touched.enabled
                                }
                              >
                                <FormLabel>Enabled</FormLabel>
                                <Checkbox
                                  isChecked={field.value}
                                  onChange={(e) =>
                                    form.setFieldValue(
                                      "enabled",
                                      e.target.checked
                                    )
                                  }
                                  colorScheme="teal"
                                  size="lg"
                                  iconColor="red.500"
                                  id="enabled"
                                >
                                  Enabled
                                </Checkbox>
                                <FormErrorMessage>
                                  {form.errors.enabled &&
                                    form.touched.enabled &&
                                    form.errors.enabled}
                                </FormErrorMessage>
                              </FormControl>
                            )}
                          </Field>
                        </Stack>

                        <Stack direction="row" spacing={4} marginY="1rem">
                          <Button
                            width="100%"
                            colorScheme="teal"
                            variant="solid"
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="md" />
                            ) : (
                              <Box>Submit</Box>
                            )}
                          </Button>
                        </Stack>
                      </Form>
                    </Formik>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </Flex>

            {/* View Stop Modal */}
            <Modal
              isCentered
              isOpen={isViewOpen}
              onClose={onViewClose}
              size="lg"
              scrollBehavior="outside"
              closeOnOverlayClick={false}
            >
              <ModalOverlay
                bg="blackAlpha.300"
                backdropFilter="blur(10px) hue-rotate(90deg)"
              />
              <ModalContent>
                <ModalHeader>Stop Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {viewStop ? (
                    <Stack spacing={4}>
                      <Flex>
                        <Heading size="md" w="200px">
                          Route Direction:
                        </Heading>
                        <Text>{viewStop.route_direction}</Text>
                      </Flex>
                      <Flex>
                        <Heading size="md" w="200px">
                          Location Sequence:
                        </Heading>
                        <Text>{viewStop.location_seq}</Text>
                      </Flex>
                      <Flex>
                        <Heading size="md" w="200px">
                          Enabled:
                        </Heading>
                        <Text>{viewStop.enabled ? "Yes" : "No"}</Text>
                      </Flex>
                      <Flex>
                        <Heading size="md" w="200px">
                          Location Name:
                        </Heading>
                        <Text>{viewStop.location.name}</Text>
                      </Flex>
                      <Flex>
                        <Heading size="md" w="200px">
                          Created:
                        </Heading>
                        <Text>{viewStop.created_ts}</Text>
                      </Flex>
                      <Flex>
                        <Heading size="md" w="200px">
                          Updated:
                        </Heading>
                        <Text>{viewStop.updated_ts ? viewStop.update_ts : "N/A"}</Text>
                      </Flex>
                      <Flex>
                        <Heading size="md" w="200px">
                          Route ID:
                        </Heading>
                        <Text>{viewStop.route_id}</Text>
                      </Flex>
                    </Stack>
                  ) : (
                    <Spinner />
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            {!_.isEmpty(posRoute) && (
              <Datatable
                data={singleRouteStops}
                headerColumns={[
                  {
                    Header: "Route Direction",
                    accessorKey: "route_direction",
                  },
                  {
                    Header: "Location Sequence",
                    accessorKey: "location_seq",
                  },
                  {
                    Header: "Location",
                    accessorKey: "location.name",
                  },
                  {
                    Header: "Enabled",
                    accessorKey: "enabled",
                  },
                ]}
                dataTableHeading={"Stops"}
                handleDelete={(stop: any) => deleteStop(stop, onClose)}
                handleUpdate={handleUpdate}
                handleViewMore={handleViewMore}
                isSearch={false}
                isActions={true}
                isLoading={isLoading}
              />
            )}
          </Box>
        </Flex>
      </Box>
          </>
        ) : (
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
            <Spinner />
            <Text>Loading Route Info...</Text>
          </Box>
        )
      }
    </AdminAppShell>
  );
};

export default Route;
