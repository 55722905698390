import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  GridItem,
  Heading,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";
import AdminAppShell from "../../layouts/AdminAppShell";
import { DataTableFive } from "../../components";
import axios from "axios";

const TransactionsSummary = () => {
  const availableColumns = [
    { key: "pan_id", label: "PAN ID" },
    { key: "transaction_datetime", label: "Transaction DateTime" },
    { key: "location_id", label: "Location ID" },
    { key: "total_amount", label: "Total Amount" },
    { key: "pto", label: "Operator" },
    { key: "vehicle_id", label: "Vehicle ID" },
    { key: "external_sync_ts", label: "Sync Timestamp" },
    { key: "remarks", label: "Remarks" },
    // Add more columns as needed
  ];

  // State for filters
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [columns, setColumns] = useState(["pan_id", "transaction_datetime"]);

  // Data & loading states
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Pagination states
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(14);
  const [total, setTotal] = useState(0);

  const handleSubmit = async () => {
    // Only fetch if startDate and endDate are set
    if (!startDate || !endDate) return;

    setLoading(true);
    try {
      // Build fields parameter from selected columns
      const fields = columns.join(",");

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/transactions/filtered-transactions`,
        {
          params: {
            startDate,
            endDate,
            fields,
            page,
            size,
          },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "jwt_access_token"
            )}`,
          },
        }
      );

      const { items, total, page: currentPage, size: pageSize } = response.data;
      setData(items);
      setTotal(total);
      // Update page and size from response if they differ (usually they won't)
      if (currentPage !== page) setPage(currentPage);
      if (pageSize !== size) setSize(pageSize);
    } catch (err) {
      console.error("Error fetching filtered transactions:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnChange = (colKey: string) => {
    setColumns(
      (prev) =>
        prev.includes(colKey)
          ? prev.filter((c) => c !== colKey) // Remove column if already selected
          : [...prev, colKey] // Add column if not selected
    );
  };

  const isSubmitDisabled = !startDate || !endDate;

  // Calculate total pages
  const totalPages = Math.ceil(total / size);
  

  return (
    <AdminAppShell>
      <Box py={2} minH="90vh">
        <Box fontSize={"xl"} fontWeight={"bold"} mb={2}>
          Transactions Summary
        </Box>

        <Grid templateColumns={{ base: "1fr", md: "300px 1fr" }} gap={6}>
          {/* Left Section: Filters */}
          <GridItem p={2} borderRadius="md" boxShadow="sm">
            <Text fontSize="xs" color="white.600">
              Use the form below to filter transactions by date range and select
              which columns to display in the summary.
            </Text>
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setPage(1); // Reset to first page when filters change
                  }}
                />
              </FormControl>

              <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setPage(1); // Reset to first page when filters change
                  }}
                />
              </FormControl>

              <Box>
                <FormLabel>Columns to Display</FormLabel>
                <Stack spacing={2}>
                  {availableColumns.map((col) => (
                    <Checkbox
                      key={col.key}
                      isChecked={columns.includes(col.key)}
                      onChange={() => {
                        handleColumnChange(col.key);
                        setPage(1); // If columns changed, go back to first page
                      }}
                    >
                      {col.label}
                    </Checkbox>
                  ))}
                </Stack>
              </Box>
              

              <Button
                colorScheme="green"
                onClick={() => {
                  setPage(1); // Always fetch from first page on new submit
                  handleSubmit();
                }}
                isLoading={loading}
                isDisabled={isSubmitDisabled}
                title={
                  isSubmitDisabled
                    ? "Please select both start and end dates"
                    : ""
                }
              >
                Submit filter request
              </Button>
            </Stack>
          </GridItem>

          {/* Right Section: Transactions Data */}
          <GridItem
            p={2}
            borderRadius="md"
            boxShadow="sm"
            // Set a fixed height and allow scrolling inside this section
            height="620px"
            display="flex"
            flexDirection="column"
            overflowX={"scroll"}
          >
            <Heading as="h2" size="md" mb={4}>
              Transaction Data
            </Heading>

            {/* Scrollable container for the table - both X and Y */}
            <Box flex="1" overflowX="auto" overflowY="auto">
              {data.length > 0 ? (
                <DataTableFive
                  columns={availableColumns
                    .filter((col) => columns.includes(col.key))
                    .map((col) => ({
                      Header: col.label,
                      accessor: col.key,
                    }))}
                  data={data}
                  loading={loading}
                />
              ) : (
                <>
                  <Text fontSize="sm" color="white">
                    Once filters are applied, transaction data would appear
                    here.
                  </Text>
                </>
              )}
            </Box>

            {/* Pagination Controls - placed outside the scrollable section */}
            {data.length > 0 && (
              <Box
                mt={4}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={4}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  {/* First Page Button */}
                  <Button
                    onClick={() => {
                      if (page !== 1) {
                        setPage(1);
                        handleSubmit(); // Fetch first page
                      }
                    }}
                    isDisabled={page === 1 || loading}
                    size="sm"
                  >
                    <ArrowLeftIcon mr={1} />
                  </Button>

                  {/* Previous Page Button */}
                  <Button
                    onClick={() => {
                      if (page > 1) {
                        setPage(page - 1);
                        handleSubmit(); // Fetch previous page
                      }
                    }}
                    isDisabled={page === 1 || loading}
                    size="sm"
                  >
                    <ArrowBackIcon />
                  </Button>
                </Stack>

                <Box fontWeight={'bold'}>
                  Page {page} of {totalPages} (Total: {total})
                </Box>

                <Stack direction="row" spacing={2} alignItems="center">
                  {/* Next Page Button */}
                  <Button
                    onClick={() => {
                      if (page < totalPages) {
                        setPage(page + 1);
                        handleSubmit(); // Fetch next page
                      }
                    }}
                    isDisabled={page >= totalPages || loading}
                    size="sm"
                  >
                    <ArrowForwardIcon />
                  </Button>

                  {/* Last Page Button */}
                  <Button
                    onClick={() => {
                      if (page !== totalPages) {
                        setPage(totalPages);
                        handleSubmit(); // Fetch last page
                      }
                    }}
                    isDisabled={page >= totalPages || loading}
                    size="sm"
                  >
                    <ArrowRightIcon ml={1} />
                  </Button>
                </Stack>
              </Box>
            )}
          </GridItem>
        </Grid>
      </Box>
    </AdminAppShell>
  );
};

export default TransactionsSummary;
