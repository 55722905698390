import { Box, Flex, useToast } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

export const TransactionReport = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const columns = [
    { Header: "File Name", accessor: "file_name" },
    { Header: "File Gen ts", accessor: "file_gen_ts" },
    { Header: "Total Records", accessor: "total_records" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Total Charges", accessor: "total_charges" },
    { Header: "Total Nett", accessor: "total_nett" },
    { Header: "File Top Up Date", accessor: "file_topup_date" },
  ];
  const downloadTopUpTransactionReport = useReconStore((state) => state.downloadTopUpTransactionReport)

  const handleMoreClick = (item: any) => {
    console.log(item);
    navigate(
      `/admin/hyphen-transaction/${item.id}?top_up_date=${item.file_topup_date}&file_name=${item.file_name}`
    );
  };

  const handleDownloadClick = (item: any) => {
    downloadTopUpTransactionReport({
        format: "csv",
        top_up_date: item.file_topup_date
    }).then((res: any) => {
        console.log(res)
        // place notification
    }).catch((error: any) => {
        console.log(error)
        // place notification
    })
  }

  const handleDownloadFunc = async (params: any) => {
    const { format, details } = params;

    console.log("see details", details)

    try {
      await downloadTopUpTransactionReport({ format, search:'', startDate:'', endDate:'', details });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description: "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Transaction Report
          </Box>
        </Flex>
      </Flex>

      <Box py={4}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_topup_recon`}
          tokenKey="jwt_access_token"
          downloadFunc={handleDownloadFunc}
          handleDownloadFunc={handleDownloadClick}
          showActions={true}
          showExports={false}
          handleMore={handleMoreClick}
        />
      </Box>
    </AdminAppShell>
  );
};
