import React from "react";
import AdminAppShell from "../../../../layouts/AdminAppShell";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import DataTableThree from "../../../../components/DataTableThree";
import { useReconStore } from "../../../../hooks/useReconStore";

export const SingleTransactionReport = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const topUpDate = queryParams.get("top_up_date");
  const fileName = queryParams.get("file_name");
  const downloadReconCSVsFileContent = useReconStore((state) => state.downloadReconCSVsFileContent);



  const columns = [
      { Header: "Pan ID", accessor: "pan_id" },
      { Header: "User Code", accessor: "user_code" },
      { Header: "Top Up Date", accessor: "topup_date" },
      { Header: "Amount", accessor: "amount"},
      { Header: "Type", accessor: "type"},
      { Header: "Status", accessor: "status"},
      { Header: "External Sync Status", accessor: "external_sync_status"},
      { Header: "External Sync TS", accessor: "external_sync_ts"},
      { Header: "Remarks", accessor: "remarks"}
    ];

    const handleDownload = () => {
      if (fileName) {
        downloadReconCSVsFileContent({file_name:fileName});
      }
    }

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2} mb={2}>
        <PcCaseIcon size={25} />
        <Box fontSize="xl" fontWeight="bold">
          {fileName}
        </Box>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box py={6}>
          <DataTableThree
            columns={columns}
            fetchUrl={`${process.env.REACT_APP_BASE_URL}/master_topup_recon/recon-detail?top_up_date=${topUpDate}`}
            tokenKey="jwt_access_token"
            downloadFunc={handleDownload}
            showActions={false}
            showExports={false}
            showCSVExports={true}
            showDateRangeFilter={false}
          />
        </Box>
    </AdminAppShell>
  );
};
