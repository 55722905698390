import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import DatatableTwo from "../../components/DataTableTwo";
import { useReconStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import DataTableFour from "../../components/DataTableFour";
import dayjs from "dayjs";
import DataTableThree from "../../components/DataTableThree";

const TopUpHistory = () => {
  const toast = useToast();

  const allFields: any = [
    { label: "PAN ID", accessor: "pan_id" },
    {
      label: "TopUp Date",
      accessor: "topup_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { label: "User Code", accessor: "user_code" },
    { label: "Transaction Code", accessor: "txn_code" },
    { label: "Amount", accessor: "amount" },
    { label: "Type", accessor: "type" },
    { label: "Sync Status", accessor: "external_sync_status" },
    { label: "Sync TimeStamp", accessor: "external_sync_ts" },
    { label: "Remarks", accessor: "remarks" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedFields, setSelectedFields] = useState<string[]>([
    "topup_date",
    "pan_id",
    "user_code",
    "txn_code",
    "amount",
    "type",
    "external_sync_status",
    "external_sync_ts",
    "remarks",
  ]);

  const [startDate, setStartDate] = useState<string>(""); 
  const [endDate, setEndDate] = useState<string>("");
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportType, setExportType] = useState<"full" | "filtered" | null>(
    null
  );
  const [exportFormat, setExportFormat] = useState<"csv" | "excel">("csv");
  const [modalStartDate, setModalStartDate] = useState<string>("");
  const [modalEndDate, setModalEndDate] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });

  const downloadTopUpRecords = useReconStore(
    (state) => state.downloadLatestTransactionPanRecords
  );
  const downloadAllTopUpRecords = useReconStore(
    (state) => state.downloadAllTransactionPanRecords
  );

  const handleExportMenuClick = (type: "full" | "filtered") => {
    setExportType(type);

    if (type === "filtered") {
      setModalStartDate(startDate);
      setModalEndDate(endDate);
    } else {
      setModalStartDate("");
      setModalEndDate("");
    }

    setExportFormat("csv"); 
    setIsExportModalOpen(true);
  };

  const handleViewMore = (row: any) => {
    setSelectedRow(row);
    onOpen();
  };

  const handleExportDownload = async () => {
    if (!exportType) {
      toast({
        title: "Export Type Missing",
        description: "Please select an export type.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const startDateToCheck = exportType === "filtered" ? modalStartDate : startDate;
    const endDateToCheck = exportType === "filtered" ? modalEndDate : endDate;

    if (startDateToCheck && endDateToCheck && new Date(startDateToCheck) > new Date(endDateToCheck)) {
      toast({
        title: "Invalid Date Range",
        description: "Start date cannot be after end date.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setIsDownloading(true);

    try {
      if (exportType === "full") {
        await downloadAllTopUpRecords({
          format: exportFormat,
          fields: selectedFields.join(","),
          search: searchQuery,
          startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
          endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
          sort_by: sortConfig?.key,
          sort_direction: sortConfig?.direction
        });
      } else if (exportType === "filtered") {
        await downloadTopUpRecords({
          format: exportFormat,
          startDate: modalStartDate ? dayjs(modalStartDate).format("YYYY-MM-DD HH:mm:ss") : undefined,
          endDate: modalEndDate ? dayjs(modalEndDate).format("YYYY-MM-DD HH:mm:ss") : undefined
        });
      }
      setIsExportModalOpen(false);
      toast({
        title: "Export Successful",
        description: `Your ${exportFormat.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Export Failed",
        description: error.response?.data?.detail || "Failed to export data. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDownloading(false);
      setIsExportModalOpen(false);
    }
  };

  const relatedTransactionColumns = [
    {
      Header: "TopUp Date",
      accessor: "topup_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { Header: "PAN ID", accessor: "pan_id" },
    { Header: "Location ID", accessor: "location_id" },
    { Header: "User Code", accessor: "user_code" },
    { Header: "Transaction Code", accessor: "txn_code" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Type", accessor: "type" },
    { Header: "Sync Status", accessor: "external_sync_status" },
    { Header: "Sync TimeStamp", accessor: "external_sync_ts",  Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },},
    { Header: "Remarks", accessor: "remarks" },
  ];

  return (
    <AdminAppShell>
      <Flex direction={"column"} gap={"8"}>
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={"3"}>
            <PcCaseIcon size={30} />
            <Box fontSize="2xl" fontWeight={"bold"}>
              {" "}
              Viewing Top Up Transactions By PAN
            </Box>
          </Box>

          <Spacer />
        </Flex>
        <Box>
          <DataTableFour
            allFields={allFields}
            fetchUrl={`${process.env.REACT_APP_BASE_URL}/top-ups/latest-by-panid`}
            tokenKey="jwt_access_token"
            downloadFunc={() => {}}
            handleDownloadFunc={() => {}}
            showActions={true}
            handleMore={handleViewMore}
            showDownloadButton={true}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            onExport={handleExportMenuClick}
            startDate={startDate} 
            endDate={endDate} 
            setStartDate={setStartDate} 
            setEndDate={setEndDate} 
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
            sortConfig={sortConfig}
            onSortChange={setSortConfig}
          />
        </Box>
      </Flex>

      {/* Modal for Viewing More Details */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transaction Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedRow ? (
              <>
                <Box mt={4}>
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Related Transactions for PAN ID: {selectedRow.pan_id}
                  </Text>
                  <DataTableThree
                    columns={relatedTransactionColumns}
                    fetchUrl={`${process.env.REACT_APP_BASE_URL}/top-ups/by-panid/${selectedRow.pan_id}`}
                    tokenKey="jwt_access_token"
                    downloadFunc={() => {}}
                    showActions={false} 
                    handleMore={() => {}} 
                    handleDownloadFunc={() => {}} 
                    showEditButton={false}
                    showResubmitButton={false}
                    showDownloadButton={false} 
                    id={selectedRow.pan_id} 
                    showIcons={false}
                    showDateRangeFilter={true}
                    showExports={false}
                  />
                </Box>
              </>
            ) : (
              <Text>No data available.</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Export Modal */}
      <Modal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        size="2xl"
        isCentered
        scrollBehavior="outside"
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Export Transactions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={4}>
              {/* Summary of Selected Filters */}
              <Box>
                <Text fontWeight="bold" mb={2}>
                  Export Summary:
                </Text>
                {exportType === "filtered" ? (
                  <>
                    <Text>Start Date: {modalStartDate || "Not specified"}</Text>
                    <Text>End Date: {modalEndDate || "Not specified"}</Text>
                  </>
                ) : (
                  <>
                    <Text fontSize={'sm'}>Selected Fields: All</Text>
                    <Text fontSize={'sm'}>Search Term: {searchQuery || "Not Specified"}</Text>
                    <Text fontSize={'sm'}>Start Date: {startDate || "Not specified"}</Text>
                    <Text fontSize={'sm'}>End Date: {endDate || "Not specified"}</Text>
                  </>
                )}
              </Box>

              {/* Export Type Selection */}
              <FormControl>
                <FormLabel fontWeight={'bold'}>Select Columns to Include</FormLabel>
                <CheckboxGroup
                  value={selectedFields}
                  onChange={(vals) => setSelectedFields(vals as string[])}
                >
                  <Stack spacing={2}>
                    {/* Use SimpleGrid to arrange fields in multiple columns */}
                    <SimpleGrid columns={[2, 3, 4]} spacing={2}>
                      {allFields.map((field: any) => (
                        <Checkbox key={field.accessor} value={field.accessor}>
                          {field.label}
                        </Checkbox>
                      ))}
                    </SimpleGrid>
                  </Stack>
                </CheckboxGroup>
              </FormControl>

              {/* Date Range Selection for 'filtered' Export */}
              {exportType === "filtered" && (
                <Box>
                  <FormControl>
                    <FormLabel>Start Date</FormLabel>
                    <Input
                      type="date"
                      value={modalStartDate}
                      onChange={(e) => setModalStartDate(e.target.value)}
                    />
                  </FormControl>
                  <FormControl mt={4}>
                    <FormLabel>End Date</FormLabel>
                    <Input
                      type="date"
                      value={modalEndDate}
                      onChange={(e) => setModalEndDate(e.target.value)}
                    />
                  </FormControl>
                </Box>
              )}

              {/* Format Selection */}
              <FormControl as="fieldset">
                <FormLabel as="legend">Select Download Format</FormLabel>
                <RadioGroup
                  onChange={(value) =>
                    setExportFormat(value as "csv" | "excel")
                  }
                  value={exportFormat}
                >
                  <Stack direction="row">
                    <Radio value="csv">CSV</Radio>
                    <Radio value="excel">Excel</Radio>
                  </Stack>
                </RadioGroup>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setIsExportModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleExportDownload}
              isLoading={isDownloading}
              loadingText="Downloading"
            >
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminAppShell>
  );
};

export default TopUpHistory;
