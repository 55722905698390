import {
  Box,
  Flex,
  HStack,
  Icon,
  Image,
  List,
  ListItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useMemo, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  LogOutIcon,
  Users2Icon,
  LockIcon,
  ClipboardListIcon,
} from "lucide-react";
import { useAuthStore } from "../../hooks";
import "../../index.css";
import FaipayLogo from "../../assets/FairPayLogo.png";

function SideBarNav() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, setIsOpen] = useState(true);
  const [expandedSectionId, setExpandedSectionId] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const signOut = useAuthStore((state) => state.signOutUser);
  const posUser = useAuthStore((state) => state.user);

  const logOut = () => {
    signOut();
  };

  const ROUTES = [
    {
      id: 2,
      title: "ROLES AND PERMISSION",
      icon: LockIcon,
      allowedRoles: ["Super Admin User"],
      subItems: [
        {
          title: "ROLES",
          path: "/admin/roles-management",
        },
        {
          title: "USER MANAGEMENT",
          path: "/admin/user-management",
        },
      ],
    },
    {
      id: 3,
      title: "OPERATOR MANAGEMENT",
      icon: Users2Icon,
      allowedRoles: ["Administrator", "Super Admin User", "Operator"],
      subItems: [
        { title: "Operators", path: "/admin/operators" },
        { title: "Locations", path: "/admin/locations" },
        { title: "Routes", path: "/admin/routes" },
        { title: "Tariffs", path: "/admin/tariffs" },
        { title: "Devices", path: "/admin/devices" },
        { title: "License", path: "/admin/licenses" },
        { title: "Vehicles", path: "/admin/vehicles" },
        { title: "Drivers", path: "/admin/drivers" },
      ],
    },
    // {
    //   id: 4,
    //   title: "AGENT MANAGEMENT",
    //   icon: User2Icon,
    //   subItems: [{ title: "Devices", path: "/admin/operators/add" }],
    // },
    {
      id: 4,
      title: "VALIDATION LIST",
      icon: Users2Icon,
      allowedRoles: ["Administrator", "Super Admin User"],
      subItems: [
        { title: "View List", path: "/admin/validation-list" },
      ],
    },
    {
      id: 5,
      title: "TRIPS",
      icon: ClipboardListIcon,
      allowedRoles: ["Administrator", "Super Admin User", "Operator", "Finance"],
      subItems: [
        { title: "Trips History", path: "/admin/trips" },
      ],
    },
    {
      id: 6,
      title: "TRANSACTIONS",
      icon: ClipboardListIcon,
      allowedRoles: ["Administrator", "Super Admin User", "Finance"],
      subItems: [
        { title: "Transactions by PAN", path: "/admin/transaction/history" },
        { title: "Transaction History", path: "/admin/transaction-summary/history" },
      ],
    },
    {
      id: 7,
      title: "TOP UPS",
      icon: ClipboardListIcon,
      allowedRoles: ["Administrator", "Super Admin User", "Finance"],
      subItems: [
        { title: "Transactions by PAN", path: "/admin/topup/history" },
        { title: "Top Up Transaction History", path: "/admin/topup-summary/history" },
      ],
    },
    {
      id: 8,
      title: "SANRAL RECONCILIATION",
      icon: ClipboardListIcon,
      allowedRoles: ["Administrator", "Super Admin User", "Finance"],
      subItems: [
        {title: "Transaction status", path: "/admin/transaction-map"},
        {title: "Transaction Statements", path: "/admin/transaction-statements"},
        {title: "Processing Fee Invoice", path: "/admin/processing-fee-invoice"},
        {title: "View Transaction Exceptions", path: "/admin/transactions/view-exceptions"}
      ]
    },
    {
      id: 9,
      title: "HYPHEN RECONCILIATION",
      icon: ClipboardListIcon,
      allowedRoles: ["Administrator", "Super Admin User", "Finance"],
      subItems: [
        // {title: "Transaction status", path: "/admin/transaction/status"},
        {title: "Transaction Report", path: "/admin/hyphen-transaction"},
        {title: "Exception Report", path: "/admin/exceptions"}
      ]
    }
    // {
    //   id: 7,
    //   title: "CARD MANAGEMENT",
    //   icon: CreditCardIcon,
    //   subItems: [{ title: "Card Stock", path: "/admin/operators/add" }],
    // },
    // {
    //   id: 8,
    //   title: "CONFIGURATIONS",
    //   icon: WorkflowIcon,
    //   subItems: [
    //     { title: "Limits", path: "/admin/operators/add" },
    //     { title: "Rules", path: "/admin/operators/add" },
    //   ],
    // },
  ];

  // Toggle the expanded state for a menu item
  // const toggleExpansion = (id: any) => {
  //   setExpanded((prevState: any) => ({
  //     ...prevState,
  //     [id]: !prevState[id],
  //   }));
  // };

  const toggleExpansion = (id: number) => {
    setExpandedSectionId((prevId) => (prevId === id ? null : id));
  };

  // Check if a sub-item is active based on the current location
  const isActiveSubItem = (path: string) => location.pathname === path;

  // Prevents event propagation to avoid collapsing parent item on sub-item click

  // const allowedRoutes = ROUTES.filter((route) => {
  //   // Check if the user's role title matches any of the allowed titles
  //   if (posUser.role && route.allowedRoles) {
  //     return route.allowedRoles.includes(posUser.role.title);
  //   }
  //   // If allowedRoles is not defined or if the user's role is not defined,
  //   // assume the route is accessible
  //   return true;
  // });

  const allowedRoutes = useMemo(() => {
    return ROUTES.filter((route) => {
      if (posUser.role && route.allowedRoles) {
        return route.allowedRoles.includes(posUser.role.title);
      }
      return true;
    });
  }, [ROUTES, posUser.role]);

  
  useEffect(() => {
    let matchingRouteId = null;
    for (const route of allowedRoutes) {
      if (route.subItems) {
        for (const subItem of route.subItems) {
          if (subItem.path === location.pathname) {
            matchingRouteId = route.id;
            break;
          }
        }
      }
      if (matchingRouteId !== null) {
        break;
      }
    }
    if (matchingRouteId !== null && expandedSectionId !== matchingRouteId) {
      setExpandedSectionId(matchingRouteId);
    }
  }, [location.pathname]);
  
  

  return (
    <Stack
      borderRight="0.01rem solid #6c757d"
      initial={false}
      animate={isOpen ? "open" : "closed"}
      as={motion.nav}
      bg="primary.800"
      h="auto"
      w={isOpen ? "250px" : "82px"}
      px="10px"
      py="10px"
      justify="space-between"
      transition="all .4s ease-in-out"
      position="sticky"
      top="0"
    >
      <Stack align="flex-start" gap={8}>
        <HStack w="100%" justify="space-between">
          <Image src={FaipayLogo} alt="Fairpay logo" w={"150px"} />
        </HStack>

        <List w={"full"} spacing="24px">
          {allowedRoutes.map((route) => (
            <React.Fragment key={route.id}>
              <ListItem
                role="group"
                transition="all .4s ease-in-out"
                width={"100%"}
                border={"3px"}
                px="4px"
                _hover={{
                  transition: "0.3s",
                  cursor: "pointer",
                }}
                onClick={() => toggleExpansion(route.id)}
              >
                <Box display={"flex"} alignItems={"start"} gap={"2"}>
                  <Icon as={route.icon} />

                  <Flex
                    justifyContent={"space-between"}
                    alignItems={"start"}
                    w={"100%"}
                  >
                    <Box>
                      <Box fontWeight={600} fontSize="12px">
                        {route.title}{" "}
                      </Box>
                    </Box>
                    {route.subItems && route.subItems.length > 0 && (
                      <Icon
                      as={
                        expandedSectionId === route.id
                          ? ChevronDownIcon
                          : ChevronRightIcon
                      }
                    />
                    
                    )}
                  </Flex>
                </Box>
              </ListItem>
              {expandedSectionId === route.id && (
                <VStack align="start" pl="40px">
                  {route.subItems.map((subItem, index) => (
                    <NavLink
                      key={index}
                      to={subItem.path}
                      onClick={(e) => e.stopPropagation()}
                      className={({ isActive }) =>
                        isActive || isActiveSubItem(subItem.path)
                          ? `activeClassName`
                          : ``
                      }
                    >
                      <Text fontSize="xs" fontWeight={"bolder"}>
                        {subItem.title}
                      </Text>
                    </NavLink>
                  ))}
                </VStack>
              )}
            </React.Fragment>
          ))}
        </List>
      </Stack>
      <Stack spacing="24px" align="flex-start">
        <List spacing="12px">
          <>
            <ListItem
              role="group"
              transition="ease 0.3s"
              py="4px"
              px="12px"
              onClick={() => console.log("heeeey me")}
              _hover={{
                color: "#123E68",
                bg: "#FCFCFD",
                borderRadius: "6px",
                transition: "0.3s",
                cursor: "pointer",
              }}
            >
              <HStack spacing="24px" onClick={() => logOut()}>
                <Icon as={LogOutIcon} />
                <Box fontWeight={600} fontSize="14px">
                  LOG OUT
                </Box>
              </HStack>
            </ListItem>
          </>
        </List>
      </Stack>
    </Stack>
  );
}

export default SideBarNav;
