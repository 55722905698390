import axios from "axios";

/**
 * Triggers the export of latest PAN records.
 * 
 * @param {string} format - The format to export ('csv' or 'excel').
 * @param {string} [search] - Optional search term.
 * @param {string} [startDate] - Optional start date (YYYY-MM-DD).
 * @param {string} [endDate] - Optional end date (YYYY-MM-DD).
 */
export const handleExportLatestPanRecords = async (format: string, search = null, startDate = null, endDate = null) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/latest-pan-records/export`, {
      params: {
        format, // 'csv' or 'excel'
        search: search || undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
      },
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
      },
      responseType: "blob", // Important for file downloads
    });

    // Create a URL for the file
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link: any = document.createElement("a");
    link.href = url;

    // Determine the file extension based on format
    const extension = format === "csv" ? "csv" : "xlsx";
    link.setAttribute("download", `latest_pan_records.${extension}`);
    document.body.appendChild(link);
    link.click();

    // Clean up
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error exporting latest PAN records:", error);
    // Optionally, display a user-friendly error message
    // e.g., using a toast notification
  }
};
