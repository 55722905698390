import AdminAppShell from "../../layouts/AdminAppShell";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Stack,
  useDisclosure,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { Formik, Field, Form } from "formik";
import { CarTaxiFrontIcon } from "lucide-react";
import Datatable from "../../components/DataTable";
import { useAuthStore, useStore } from "../../hooks";
import { useEffect } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .max(50, "Name cannot exceed 50 alphanumeric characters")
    .matches(/^[a-zA-Z0-9\s]*$/, "Name must be alphanumeric"),
  code: Yup.string()
    .required("Code is required")
    .length(5, "Code must be exactly 5 alphanumeric characters")
    .matches(/^[a-zA-Z0-9]*$/, "Code must be alphanumeric"),
  address: Yup.string()
    .max(500, "Address cannot exceed 500 alphanumeric characters")
    .matches(/^[a-zA-Z0-9\s.,]*$/, "Address must be alphanumeric"),
  contact_person: Yup.string()
    .required("Contact Person is required")
    .max(50, "Contact Person cannot exceed 50 alphabetic characters")
    .matches(/^[a-zA-Z\s]*$/, "Contact Person must contain only letters"),
  contact_number: Yup.string()
    .required("Contact Number is required")
    .matches(
      /^\d{10}$/,
      "Contact Number must be exactly 10 numeric characters"
    ),
  license_number: Yup.string()
    .max(150, "License Number cannot exceed 150 alphanumeric characters")
    .matches(/^[a-zA-Z0-9]*$/, "License Number must be alphanumeric"),

  contact_email: Yup.string()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Invalid email address"
    )
    .max(100, "Contact Email cannot exceed 100 characters"),

  total_vehicles: Yup.string()
    .required("Total Vehicles is required")
    .matches(/^\d{1,6}$/, "Total Vehicles must be up to 6 numeric characters"),

  total_agents: Yup.string()
    .required("Total Agents is required")
    .matches(/^\d{1,10}$/, "Total Agents must be up to 10 numeric characters"),

  // enabled: Yup.boolean().required('Enabled is required'),
});

export default function Operators() {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchOperators = useStore((state) => state.fetchOperators);
  const createOperators = useStore((state) => state.createOperators);
  const removeOperator = useStore((state) => state.removeOperator);
  const updateOperator = useStore((state) => state.updateOperator);
  const posOperators = useStore((state) => state.operators);
  const posUser = useAuthStore((state) => state.user);
  const isLoading = useStore((state) => state.loading);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const toast = useToast();
  const navigate = useNavigate();

  const [operator, setOperator] = useState(null);
  const [loading, setLoading] = useState(false);

  const initialValues = {
    name: "",
    code: "",
    address: "",
    contact_person: "",
    contact_number: "",
    contact_email: "",
    license_number: "",
    total_vehicles: 0,
    total_agents: 0,
    enabled: true,
    created_by: `${posUser?.first_name} ${posUser?.last_name}`,
  };

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  const handleSubmit = (operatorDetails: any) => {
    setLoading(true);
    createOperators(operatorDetails).then((res: any) => {
      if (res.name) {
        setLoading(false);
        onClose();
        fetchOperators();
        toast({
          title: "Success",
          description: "Operator was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } else {
        setLoading(false);
      }
    });
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);

    updateOperator(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchOperators();
        toast({
          title: "Success",
          description: "Operator was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      name: item.name,
      code: item.code,
      address: item.address,
      contact_person: item.contact_person,
      contact_number: item.contact_number,
      contact_email: item.contact_email,
      license_number: item.license_number,
      total_vehicles: item.total_vehicles,
      total_agents: item.total_agents,
      enabled: item.enabled,
      modified_by: `${posUser?.first_name} ${posUser?.last_name}`,
    };

    setOperator(newPayloadFormat);

    if (operator) {
      onOpen();
      console.log(operator);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/operators/${item?.id}`);
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" justifyContent={"center"} gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Operators
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setOperator(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Operator
        </Button>
        <Modal size="2xl" isCentered isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false} scrollBehavior="outside">
          {overlay}
          <ModalContent>
            <ModalHeader>
              {operator ? "Update Existing Operator" : "Create a New Operator"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={operator ? operator : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                validateOnChange={true}
                onSubmit={operator ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Flex
                    direction="row"
                    gap="2rem"
                    justifyContent="space-between"
                  >
                    <Stack w="100%" direction="column" spacing={8}>
                      <Field name="name">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.name && form.touched.name}
                          >
                            <FormLabel>
                              Operator Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="name" id="name" />
                            <FormErrorMessage>
                              {form.errors.name &&
                                form.touched.name &&
                                form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="code">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.code && form.touched.code}
                          >
                            <FormLabel>
                              Operator Code{" "}
                              <span
                                style={{ color: "red", fontSize: "0.6rem" }}
                              >
                                maximum of five chars *
                              </span>
                            </FormLabel>
                            <Input {...field} type="code" id="code" />
                            <FormErrorMessage>
                              {form.errors.code &&
                                form.touched.code &&
                                form.errors.code}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="address">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.address && form.touched.address
                            }
                          >
                            <FormLabel>Address</FormLabel>
                            <Input {...field} type="address" id="address" />
                            <FormErrorMessage>
                              {form.errors.address &&
                                form.touched.address &&
                                form.errors.address}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="contact_person">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.contact_person &&
                              form.touched.contact_person
                            }
                          >
                            <FormLabel>
                              Contact Person’s Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input
                              {...field}
                              type="contact_person"
                              id="contact_person"
                            />
                            <FormErrorMessage>
                              {form.errors.contact_person &&
                                form.touched.contact_person &&
                                form.errors.contact_person}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="enabled">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.enabled && form.touched.enabled
                            }
                          >
                            <FormLabel>
                              Enabled <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Checkbox
                              isChecked={field.value}
                              {...field}
                              colorScheme="teal"
                              size="lg"
                              iconColor="red.500"
                              type="enabled"
                              id="enabled"
                            >
                              Enabled
                            </Checkbox>
                            <FormErrorMessage>
                              {form.errors.enabled &&
                                form.touched.enabled &&
                                form.errors.enabled}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                    <Stack w="100%" direction="column" spacing={8}>
                      <Field name="contact_number">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.contact_number &&
                              form.touched.contact_number
                            }
                          >
                            <FormLabel>
                            Contact Number
                                <span style={{ color: "red" }}>
                                  *  (e.g. 0827548982)
                                </span>
                            </FormLabel>
                            <Input
                              {...field}
                              type="contact_number"
                              id="contact_number"
                            />
                            <FormErrorMessage>
                              {form.errors.contact_number &&
                                form.touched.contact_number &&
                                form.errors.contact_number}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="contact_email">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.contact_email &&
                              form.touched.contact_email
                            }
                          >
                            <FormLabel>Contact Email</FormLabel>
                            <Input
                              {...field}
                              type="contact_email"
                              id="contact_email"
                            />
                            <FormErrorMessage>
                              {form.errors.contact_email &&
                                form.touched.contact_email &&
                                form.errors.contact_email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="license_number">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.license_number &&
                              form.touched.license_number
                            }
                          >
                            <FormLabel>Operator License Number</FormLabel>
                            <Input
                              {...field}
                              type="license_number"
                              id="license_number"
                            />
                            <FormErrorMessage>
                              {form.errors.license_number &&
                                form.touched.license_number &&
                                form.errors.license_number}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="total_vehicles">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.total_vehicles &&
                              form.touched.total_vehicles
                            }
                          >
                            <FormLabel>Total Vehicles</FormLabel>
                            <Input
                              {...field}
                              type="total_vehicles"
                              id="total_vehicles"
                            />
                            <FormErrorMessage>
                              {form.errors.total_vehicles &&
                                form.touched.total_vehicles &&
                                form.errors.total_vehicles}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="total_agents">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.total_agents &&
                              form.touched.total_agents
                            }
                          >
                            <FormLabel>Total Agents</FormLabel>
                            <Input
                              {...field}
                              type="total_agents"
                              id="total_agents"
                            />
                            <FormErrorMessage>
                              {form.errors.total_agents &&
                                form.touched.total_agents &&
                                form.errors.total_agents}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Flex>

                  <Stack direction="row" spacing={4} marginY="1rem">
                    <Button
                      width="100%"
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                    >
                      {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              header: "Operator Name",
              accessorKey: "name",
            },
            {
              header: "Operator Code",
              accessorKey: "code",
            },
            {
              header: "Contact Person",
              accessorKey: "contact_person",
            },
            {
              header: "Contact Number",
              accessorKey: "contact_number",
            },
            // {
            //   Header: "Contact Email",
            //   accessorKey: "contact_email",
            // },
            {
              header: "Total Vehicles",
              accessorKey: "total_vehicles",
            },
            {
              header: "Enabled",
              accessorKey: "enabled"
            }
          ]}
          data={posOperators}
          dataTableHeading={"Operators"}
          handleDelete={removeOperator}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={data}/> */}
      </Box>
    </AdminAppShell>
  );
}
