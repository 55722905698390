import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  List,
  ListIcon,
  ListItem,
  Select,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { useLocation, useNavigate } from "react-router-dom";

const MapDeviceRoute = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fetchVehicle = useStore((state) => state.fetchSingleVehicle);
  const posVehicle = useStore((state) => state.vehicle);
  const [loading, setLoading] = useState(false);
  const [isRouteLoading, setIsRouteLoading] = useState(false);

  const fetchVehicleRoutes = useStore((state) => state.fetchVehicleRoutes);
  const posVehicleRoutes = useStore((state) => state.vehicleRoutes);
  const fetchRoutes = useStore((state) => state.fetchRoutes);
  const posRoutes = useStore((state) => state.routes);
  const [routeId, setRouteId] = useState("");

  const fetchVehicleDevices = useStore((state) => state.fetchVehicleDevices);
  const posVehicleDevices = useStore((state) => state.vehicleDevices);
  const fetchDevices = useStore((state) => state.fetchDevices);
  const posDevices = useStore((state) => state.devices);
  const [deviceId, setDeviceId] = useState("");

  const mapVehiclesToRoutes = useStore((state) => state.mapVehicleToRoute);
  const mapVehicleToDevice = useStore((state) => state.mapVehicleToDevice);
  const fetchOperatorRoutesInfo = useStore(
    (state) => state.fetchOperatorRoutesInfo
  );
  const fetchOperatorDeviceInfo = useStore(
    (state) => state.fetchOperatorDeviceInfo
  );
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const toast = useToast();

  useEffect(() => {
    fetchVehicle({ id: getVehicleID() });
    fetchVehicleDevices({ id: getVehicleID() });
    fetchVehicleRoutes({ id: getVehicleID() });

    // Check if routes/devices are passed in state; otherwise, fetch them
    if (location.state?.filteredRoutes && location.state?.filteredDevices) {
      setFilteredRoutes(location.state.filteredRoutes);
      setFilteredDevices(location.state.filteredDevices);
    } else if (posVehicle?.pto_id) {
      fetchOperatorRoutesInfo({ pto_id: posVehicle.pto_id }).then(
        setFilteredRoutes
      );
      fetchOperatorDeviceInfo({ pto_id: posVehicle.pto_id }).then(
        setFilteredDevices
      );
    }

    // fetchRoutes();
    // fetchDevices();
  }, [
    location.state,
    posVehicle?.pto_id,
    fetchVehicle,
    fetchVehicleDevices,
    fetchVehicleRoutes,
  ]);

  const getVehicleID = () => {
    const urlPath = location?.pathname;

    const destructuredPath = urlPath.split("/");

    console.log(destructuredPath);

    return destructuredPath[3];
  };

  const handleRouteIdChange = (e: any) => {
    setRouteId(e.target.value);
  };

  const handleDeviceIdChange = (e: any) => {
    setDeviceId(e.target.value);
  };

  const handleDeviceMapSubmission = () => {
    console.log(deviceId);

    setLoading(true);

    const deviceDetails = {
      device_id: deviceId,
      vehicle_id: posVehicle?.id,
      // validity: "",
      enabled: true,
    };

    mapVehicleToDevice(deviceDetails).then((res: any) => {
      setLoading(false);
      toast({
        title: "Success",
        description: "Mapped vehicle to device successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setDeviceId("");
      fetchVehicleDevices({ id: getVehicleID() });
    });
  };

  const handleRouteMapSubmission = () => {
    console.log(routeId);

    setIsRouteLoading(true);

    const routeDetails = {
      route_id: routeId,
      vehicle_id: posVehicle?.id,
      enabled: true,
    };
    mapVehiclesToRoutes(routeDetails).then((res: any) => {
      setIsRouteLoading(false);
      toast({
        title: "Success",
        description: "Mapped vehicle to route successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setRouteId("");
      fetchVehicleRoutes({ id: getVehicleID() });
    });
  };

  return (
    <AdminAppShell>
      {posVehicle ? (
        <Box boxShadow={"lg"} p={"4"}>
          <Text fontSize={"2xl"} fontWeight={"bold"}>
            Map Vehicle to a route or device
          </Text>

          <Flex justifyContent={"center"} gap={"4"} w={"100%"}>
            <Box w={"50%"}>
              <Text
                fontWeight={"bolder"}
                textAlign={"center"}
                paddingBottom={"1rem"}
              >
                Map {posVehicle.name} to a Device
              </Text>

              <>
                <Text>
                  The following Devices have been mapped to{" "}
                  {`${posVehicle.name}`}
                </Text>
                <List boxShadow={"lg"} spacing={3} padding="4">
                  {posVehicleDevices &&
                  posVehicleDevices.devices &&
                  posVehicleDevices.devices.length > 0 ? (
                    posVehicleDevices.devices.map((device: any) => (
                      <ListItem
                        key={device.id}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <ListIcon as={CarTaxiFrontIcon} color="green.500" />
                        <Box marginRight={"5"} fontWeight={"bolder"}>
                          {device?.name}
                        </Box>
                      </ListItem>
                    ))
                  ) : (
                    <Box>Vehicle not Mapped to any device</Box>
                  )}
                </List>

                <Box>
                  <Text>Select a device to be mapped to the vehicle</Text>

                  <Box>
                    <Select
                      placeholder={
                        filteredDevices.length > 0
                          ? "Select a Device"
                          : "No devices found"
                      }
                      id="device_id"
                      onChange={handleDeviceIdChange}
                      isDisabled={filteredDevices.length === 0}
                    >
                      {filteredDevices.map((device: any) => (
                        <option key={device.device_id} value={device.device_id}>
                          {`${device.device_name} - ${device.device_serial}`}
                        </option>
                      ))}
                    </Select>

                    <Button
                      colorScheme={"green"}
                      w={"full"}
                      marginY={4}
                      onClick={handleDeviceMapSubmission}
                      isDisabled={!deviceId || loading}
                    >
                      {loading ? <Spinner /> : <>Submit</>}
                    </Button>
                  </Box>
                </Box>
              </>
            </Box>

            <Center height="auto">
              <Divider orientation="vertical" />
            </Center>

            <Box w={"50%"}>
              <Text
                fontWeight={"bolder"}
                textAlign={"center"}
                paddingBottom={"1rem"}
              >
                Map {posVehicle.name} to a Route
              </Text>

              <>
                <Text>
                  The following Routes have been mapped to{" "}
                  {`${posVehicle.name}`}
                </Text>
                <List boxShadow={"lg"} spacing={3} padding="4">
                  {posVehicleRoutes &&
                  posVehicleRoutes.routes &&
                  posVehicleRoutes.routes.length > 0 ? (
                    posVehicleRoutes.routes.map((route: any) => (
                      <ListItem
                        key={route.id}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <ListIcon as={CarTaxiFrontIcon} color="green.500" />
                        <Box marginRight={"5"} fontWeight={"bolder"}>
                          {route?.name}
                        </Box>
                      </ListItem>
                    ))
                  ) : (
                    <Box>Vehicle not Mapped to any route</Box>
                  )}
                </List>

                <Box>
                  <Text>Select a route to be mapped to the vehicle</Text>

                  <Box>
                    <Select
                      placeholder={
                        filteredRoutes.length > 0
                          ? "Select a Route"
                          : "No routes found"
                      }
                      id="route_id"
                      onChange={handleRouteIdChange}
                      isDisabled={filteredRoutes.length === 0}
                    >
                      {filteredRoutes.map((route: any) => (
                        <option key={route.id} value={route.id}>
                          {route.name}
                        </option>
                      ))}
                    </Select>

                    <Button
                      colorScheme={"green"}
                      w={"full"}
                      marginY={4}
                      onClick={handleRouteMapSubmission}
                      isDisabled={!routeId || isRouteLoading}
                    >
                      {isRouteLoading ? <Spinner /> : <> Submit</>}
                    </Button>
                  </Box>
                </Box>
              </>
            </Box>
          </Flex>

          <Box
            w={"full"}
            marginY={"3rem"}
            display={"flex"}
            justifyContent={"center"}
          >
            <Button onClick={() => navigate("/admin/vehicles")}>
              Finish Vehicle Creation Process
            </Button>
          </Box>
        </Box>
      ) : (
        <Center height={"100vh"}>
          <Box textAlign={"center"}>
            <Spinner size={"xl"} />
            <Text mt={4}>Please wait as we load the vehicle data...</Text>
          </Box>
        </Center>
      )}
    </AdminAppShell>
  );
};

export default MapDeviceRoute;
