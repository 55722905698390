// apiClient.js
import { useAuthStore } from '../hooks';
import toast from 'react-hot-toast';

export const apiClient = async (url: any, options: any = {}) => {
  const accessToken = window.localStorage.getItem('jwt_access_token');
  const defaultHeaders: any = {
    'Content-Type': 'application/json',
  };

  // Include the access token in the Authorization header if it exists
  if (accessToken) {
    defaultHeaders['Authorization'] = `Bearer ${accessToken}`;
  }

  const finalOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...options.headers,
    },
  };

  try {
    const response = await fetch(url, finalOptions);

    if (response.status === 401) {
      // Handle 401 Unauthorized error
      const authStore = useAuthStore.getState();
      authStore.signOutUser();

      toast.error('Session expired. Please log in again.', {
        duration: 5000,
        position: 'top-center',
        style: { background: '#d00000', color: '#fff' },
      });

      // Redirect to the login page
      window.location.href = '/login';
    }

    return response;
  } catch (error) {
    // Handle network or other errors
    console.error('API Client Error:', error);
    throw error;
  }
};
