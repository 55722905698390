import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import Datatable from "../../components/DataTable";
import { useAuthStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import { Formik, Field, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const vehicleModels = [
  {
    id: 1,
    title: "Quantum",
  },
  {
    id: 2,
    title: "Sprinter",
  },
  {
    id: 3,
    title: "Coaster",
  },
  {
    id: 4,
    title: "NY350",
  },
  {
    id: 5,
    title: "Other",
  },
];

// const validate = (values: any) => {
//   const errors: any = {};

//   if (!values.model) {
//     errors.model = "Model is required";
//   }

//   if (!values.vehicle_capacity) {
//     errors.vehicle_capacity = "Vehicle Capacity is required";
//   }

//   // if (!values.model) {
//   //   errors.model = "Model is required";
//   // }

//   if (!values.name) {
//     errors.name = "Name is required";
//   }

//   if (!values.make) {
//     errors.make = "Make is required";
//   }

//   if (!values.type) {
//     errors.type = "Type is required";
//   }

//   if (!values.vehicle_number) {
//     errors.vehicle_number = "Vehicle Number is required";
//   }

//   if (!values.fuel_type) {
//     errors.fuel_type = "Fuel Type is required";
//   }

//   if (!values.pto_id) {
//     errors.pto_id = "Operator is required";
//   }

//   // if (!values.vehicle_capacity) {
//   //   errors.model = "Vehicle Capacity is required";
//   // }

//   return errors;
// };

const validationSchema = Yup.object().shape({
  model: Yup.string().required("Model is required"),
  vehicle_capacity: Yup.number()
    .min(1, "Vehicle Capacity must be greater than 0")
    .required("Vehicle Capacity is required"),
  name: Yup.string()
    .required("Name is required")
    .max(30, "Vehicle Name cannot exceed 30 characters"),
  make: Yup.string()
    .required("Make is required")
    .max(30, "Make cannot exceed 30 characters"),
  type: Yup.string().required("Type is required"),
  vehicle_number: Yup.string()
    .required("Vehicle Number is required")
    .matches(
      /^[A-Z0-9\s-]*$/,
      "Only uppercase letters, digits, spaces, and dashes are allowed"
    ),
  // fuel_type: Yup.string().required('Fuel Type is required'),
  pto_id: Yup.string().required("Operator is required"),
  enabled: Yup.boolean().required("Enabled is required"),
});

const fuelTypes = ["Diesel", "Petrol", "Other"];

const Vehicles = () => {
  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const fetchVehicles = useStore((state) => state.fetchVehicles);
  const createVehicles = useStore((state) => state.createVehicles);
  const removeVehicle = useStore((state) => state.removeVehicle);
  const updateVehicle = useStore((state) => state.updateVehicle);
  const posVehicles = useStore((state) => state.vehicles);
  const isLoading = useStore((state) => state.loading);
  const posUser = useAuthStore((state) => state.user);

  const fetchOperators = useStore((state) => state.fetchOperators);
  const posOperators = useStore((state) => state.operators);
  const fetchOperatorRoutesInfo = useStore((state) => state.fetchOperatorRoutesInfo);
  const fetchOperatorDeviceInfo = useStore((state) => state.fetchOperatorDeviceInfo);

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [mapDevice, setMapDevice] = useState(false);
  const [mapRoute, setMapRoute] = useState(false);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [filteredDevices, setFilteredDevices] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const initialValues = {
    model: "",
    name: "",
    make: "",
    type: "",
    vehicle_number: "",
    fuel_type: "",
    pto_id: "",
    vehicle_capacity: 0,
    enabled: false,
    created_by: `${posUser?.first_name} ${posUser?.last_name}`,
  };

  useEffect(() => {
    fetchVehicles();
    fetchOperators();
  }, [fetchVehicles, fetchOperators]);

  // const handleSubmit = async (vehicleDetails: any) => {
  //   setLoading(true);
  //   vehicleDetails.enabled = true;
  //   createVehicles(vehicleDetails).then((res: any) => {
  //     if (res.vehicle_number) {
  //       setLoading(false);
  //       onClose();
  //       fetchVehicles();

  //       // Fetch routes and devices for the PTO
  //       const routes = await fetchOperatorRoutesInfo({ pto_id: res.pto_id });
  //       const devices = await fetchOperatorDeviceInfo({ pto_id: res.pto_id });

  //       // redirect the user to a page that maps a device or a route
  //       navigate(`/admin/vehicles/${res.id}/map-device-route`);

  //       toast({
  //         title: "Success",
  //         description: "Vehicle was added successfully!",
  //         status: "success",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     } else {
  //       setLoading(false);
  //     }
  //   });
  // };

  const handleSubmit = async (vehicleDetails: any) => {
    setLoading(true);
    vehicleDetails.enabled = true;

    try {
      const res = await createVehicles(vehicleDetails);
      if (res.vehicle_number) {
        setLoading(false);
        onClose();
        fetchVehicles();

        // Fetch routes and devices for the PTO
        const routes = await fetchOperatorRoutesInfo({ operator_id: res.pto_id });
        const devices = await fetchOperatorDeviceInfo({ operator_id: res.pto_id });

        // Store filtered routes and devices
        setFilteredRoutes(routes);
        setFilteredDevices(devices.operator.devices);

        // Redirect to map device & route page with the filtered data
        navigate(`/admin/vehicles/${res.id}/map-device-route`, {
          state: { filteredRoutes: routes, filteredDevices: devices.operator.devices },
        });

        toast({
          title: "Success",
          description: "Vehicle was added successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: "Error",
        description: "An error occurred while creating the vehicle.",
        status: "error",
        duration: 9000,
        isClosable: true,
      }) 
    } finally {
        setLoading(false);
    }
  };

  const submitUpdate = (updatedData: any) => {
    setLoading(true);
    console.log("handle update submit", updatedData);

    updateVehicle(updatedData).then((res: any) => {
      if (!_.isEmpty(res)) {
        setLoading(false);
        onClose();
        fetchVehicles();
        toast({
          title: "Success",
          description: "Vehicle was updated successfully!",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      }
    });
  };

  const handleUpdate = (item: any) => {
    const newPayloadFormat: any = {
      id: item.id,
      model: item.model,
      name: item.name,
      make: item.make,
      type: item.type,
      vehicle_number: item.vehicle_number,
      fuel_type: item.fuel_type,
      pto_id: item.pto_id,
      vehicle_capacity: item.vehicle_capacity,
      enabled: item.enabled,
      modified_by: `${posUser?.first_name} ${posUser?.last_name}`,
    };

    setVehicle(newPayloadFormat);

    if (vehicle) {
      onOpen();
      console.log(vehicle);
    }
  };

  const handleViewMore = (item: any) => {
    navigate(`/admin/vehicles/${item.id}`);
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Vehicles
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setVehicle(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create Vehicle
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size="xl" closeOnOverlayClick={false}>
          {overlay}
          <ModalContent>
            <ModalHeader>
              {vehicle ? "Update Existing Vehicle" : "Create a New Vehicle"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={vehicle ? vehicle : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={vehicle ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    gap="1rem"
                  >
                    <Stack w="100%" direction="column" spacing={8}>
                      <Field name="pto_id">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.pto_id && form.touched.pto_id
                            }
                          >
                            <FormLabel>
                              Operator <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Select
                              {...field}
                              placeholder="Select an operator"
                              type="pto_id"
                              id="pto_id"
                            >
                              {posOperators &&
                                posOperators
                                  .map((item: any, index: number) => (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.pto_id &&
                                form.touched.pto_id &&
                                form.errors.pto_id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="model">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.model && form.touched.model}
                          >
                            <FormLabel>
                              Model <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Select
                              {...field}
                              placeholder="Select a vehicle model"
                              type="model"
                              id="model"
                            >
                              {vehicleModels &&
                                vehicleModels.map(
                                  (item: any, index: number) => (
                                    <option key={index} value={item.title}>
                                      {item.title}
                                    </option>
                                  )
                                )}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.model &&
                                form.touched.model &&
                                form.errors.model}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="make">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.make && form.touched.make}
                          >
                            <FormLabel>
                              Make <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="make" id="make" />
                            <FormErrorMessage>
                              {form.errors.make &&
                                form.touched.make &&
                                form.errors.make}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="name">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.name && form.touched.name}
                          >
                            <FormLabel>Name of Vehicle <span style={{ color: "red" }}>*</span></FormLabel>
                            <Input {...field} type="name" id="name" />
                            <FormErrorMessage>
                              {form.errors.name &&
                                form.touched.name &&
                                form.errors.name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>

                    <Stack w="100%" direction="column" spacing={8}>
                      <Field name="type">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.type && form.touched.type}
                          >
                            <FormLabel>
                              Type <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Select
                              {...field}
                              type="type"
                              id="type"
                              placeholder="Select vehicle type"
                            >
                              <option value="Minibus">Minibus</option>
                            </Select>
                            <FormErrorMessage>
                              {form.errors.type &&
                                form.touched.type &&
                                form.errors.type}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="vehicle_number">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.vehicle_number &&
                              form.touched.vehicle_number
                            }
                          >
                            <FormLabel>
                              Vehicle Registration Number{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input
                              {...field}
                              type="vehicle_number"
                              id="vehicle_number"
                            />
                            <FormErrorMessage>
                              {form.errors.vehicle_number &&
                                form.touched.vehicle_number &&
                                form.errors.vehicle_number}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="fuel_type">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.fuel_type && form.touched.fuel_type
                            }
                          >
                            <FormLabel>Fuel Type</FormLabel>
                            <Select {...field} placeholder="Select fuel type">
                              {fuelTypes.map((type, index) => (
                                <option key={index} value={type}>
                                  {type}
                                </option>
                              ))}
                            </Select>
                            <FormErrorMessage>
                              {form.errors.fuel_type &&
                                form.touched.fuel_type &&
                                form.errors.fuel_type}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="vehicle_capacity">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.vehicle_capacity &&
                              form.touched.vehicle_capacity
                            }
                          >
                            <FormLabel>Vehicle Capacity</FormLabel>
                            <Input
                              {...field}
                              type="vehicle_capacity"
                              id="vehicle_capacity"
                            />
                            <FormErrorMessage>
                              {form.errors.vehicle_capacity &&
                                form.touched.vehicle_capacity &&
                                form.errors.vehicle_capacity}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="enabled">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.enabled && form.touched.enabled
                            }
                          >
                            <FormLabel>
                              Enabled <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Checkbox
                              isChecked={field.value}
                              {...field}
                              colorScheme="teal"
                              size="lg"
                              iconColor="red.500"
                              type="enabled"
                              id="enabled"
                            >
                              Enabled
                            </Checkbox>
                            <FormErrorMessage>
                              {form.errors.enabled &&
                                form.touched.enabled &&
                                form.errors.enabled}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Flex>

                  <Stack direction="row" spacing={4} marginY="1rem">
                    <Button
                      width="100%"
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                    >
                      {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter>
              <Button onClick={onClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              header: "Vehicle Model",
              accessorKey: "model",
            },
            {
              header: "Vehicle Name",
              accessorKey: "name",
            },
            {
              header: "Vehicle Make",
              accessorKey: "make",
            },
            {
              header: "Vehicle Type",
              accessorKey: "type",
            },
            {
              header: "Vehicle Number",
              accessorKey: "vehicle_number",
            },
            // {
            //   Header: "Fuel Tyoe",
            //   accessorKey: "fuel_type",
            // },
            {
              header: "Vehicle Capacity",
              accessorKey: "vehicle_capacity",
            },
            {
              header: "Enabled",
              accessorKey: "enabled",
            },
          ]}
          data={posVehicles}
          dataTableHeading={"Vehicles"}
          handleDelete={removeVehicle}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default Vehicles;
