import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
// import _ from "lodash";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
// import Datatable from "../../components/DataTable";
import DatatableTwo from "../../components/DataTableTwo";
import { useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import DataTableFour from "../../components/DataTableFour";
import dayjs from "dayjs";
import DataTableThree from "../../components/DataTableThree";
// import { formatDateToISOString } from "../../utils";
// import { exportData } from "../../utils/reportingDownloads";

const TopUpHistory = () => {
  const toast = useToast();

  const allFields: any = [
    { label: "PAN ID", accessor: "pan_id" },
    {
      label: "TopUp Date",
      accessor: "topup_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { label: "User Code", accessor: "user_code" },
    { label: "Transaction Code", accessor: "txn_code" },
    { label: "Amount", accessor: "amount" },
    { label: "Type", accessor: "type" },
    { label: "Sync Status", accessor: "external_sync_status" },
    { label: "Sync TimeStamp", accessor: "external_sync_ts" },
    { label: "Remarks", accessor: "remarks" },
  ];

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectedFields, setSelectedFields] = useState<string[]>([
    "topup_date",
    "pan_id",
    "user_code",
    "txn_code",
    "amount",
    "type",
    "external_sync_status",
    "external_sync_ts",
    "remarks",
  ]);

  const [startDate, setStartDate] = useState<string>(""); // Moved here
  const [endDate, setEndDate] = useState<string>("");
  // New state variables for export functionality
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportType, setExportType] = useState<"full" | "filtered" | null>(
    null
  );
  const [exportFormat, setExportFormat] = useState<"csv" | "excel">("csv");
  const [modalStartDate, setModalStartDate] = useState<string>("");
  const [modalEndDate, setModalEndDate] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState(false);

  const handleExportMenuClick = (type: "full" | "filtered") => {
    setExportType(type);

    if (type === "filtered") {
      // Optionally, pre-fill date ranges if they are managed in a shared state or store
      // For simplicity, we'll leave them empty and let the user input in the modal
      setModalStartDate(startDate);
      setModalEndDate(endDate);
    } else {
      // Reset modal dates for full export
      setModalStartDate("");
      setModalEndDate("");
    }

    setExportFormat("csv"); // Default format
    setIsExportModalOpen(true);
  };

  const handleViewMore = (row: any) => {
    setSelectedRow(row);
    onOpen();
  };

  const relatedTransactionColumns = [
    {
      Header: "TopUp Date",
      accessor: "topup_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD HH:mm");
      },
    },
    { Header: "PAN ID", accessor: "pan_id" },
    { Header: "Location ID", accessor: "location_id" },
    { Header: "User Code", accessor: "user_code" },
    { Header: "Transaction Code", accessor: "txn_code" },
    { Header: "Amount", accessor: "amount" },
    { Header: "Type", accessor: "type" },
    { Header: "Sync Status", accessor: "external_sync_status" },
    { Header: "Sync TimeStamp", accessor: "external_sync_ts",  Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD HH:mm");
    },},
    { Header: "Remarks", accessor: "remarks" },
    // Add more columns as needed
  ];

  return (
    <AdminAppShell>
      <Flex direction={"column"} gap={"8"}>
        {/* <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={30} />
          <Box fontSize="2xl" fontWeight="bold">
            Transaction History
          </Box>
        </Flex> */}

        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} alignItems={"center"} gap={"3"}>
            <PcCaseIcon size={30} />
            <Box fontSize="2xl" fontWeight={"bold"}>
              {" "}
              Viewing Top Up Transactions History
            </Box>
          </Box>

          <Spacer />
        </Flex>
        <Box>
          <DataTableFour
            allFields={allFields}
            fetchUrl={`${process.env.REACT_APP_BASE_URL}/top-ups/latest-by-panid`}
            tokenKey="jwt_access_token"
            downloadFunc={() => {}}
            handleDownloadFunc={() => {}}
            showActions={true}
            handleMore={handleViewMore}
            showDownloadButton={true}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            onExport={() => {}}
            startDate={startDate} // Pass startDate
            endDate={endDate} // Pass endDate
            setStartDate={setStartDate} // Pass setStartDate
            setEndDate={setEndDate} // Pass setEndDate
          />
        </Box>
      </Flex>

      {/* Modal for Viewing More Details */}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Transaction Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedRow ? (
              <>
                <Box mt={4}>
                  <Text fontSize="lg" fontWeight="bold" mb={4}>
                    Related Transactions for PAN ID: {selectedRow.pan_id}
                  </Text>
                  <DataTableThree
                    columns={relatedTransactionColumns}
                    fetchUrl={`${process.env.REACT_APP_BASE_URL}/top-ups/by-panid/${selectedRow.pan_id}`}
                    tokenKey="jwt_access_token"
                    downloadFunc={() => {}}
                    showActions={false} // Adjust based on requirements
                    handleMore={() => {}} // No additional actions in related transactions
                    handleDownloadFunc={() => {}} // Implement if needed
                    showEditButton={false}
                    showResubmitButton={false}
                    showDownloadButton={false} // Adjust if download is needed
                    id={selectedRow.pan_id} // Pass PAN ID if necessary
                    showIcons={false}
                    showDateRangeFilter={true}
                    showExports={false}
                  />
                </Box>
              </>
            ) : (
              <Text>No data available.</Text>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AdminAppShell>
  );
};

export default TopUpHistory;
