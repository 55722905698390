import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Spacer,
  Button,
  useToast,
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import DataTableThree from "../../../../components/DataTableThree";
import AdminAppShell from "../../../../layouts/AdminAppShell";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useReconStore } from "../../../../hooks";
import dayjs from "dayjs";

export const SingleTransactionStatement = () => {
  let { id } = useParams();
  const toast = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refNum = queryParams.get("refNum");
  console.log(refNum);
  const navigate = useNavigate();
  const downloadFinancialTransactionStatementReport = useReconStore(
    (state) => state.downloadFinancialTransactionStatementReport
  );
  const downloadInvoiceTransactionStatementReport = useReconStore(
    (state) => state.downloadInvoiceTransactionStatementReport
  );
  const downloadTransitTransactionStatementReport = useReconStore(
    (state) => state.downloadTransitTransactionStatementReport
  );
  const downloadDetailInvoiceFinancialTransactionStatementReport =
    useReconStore(
      (state) => state.downloadDetailInvoiceFinancialTransactionStatementReport
    );

  const dtlFinancialStatementsColumn = [
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    {
      Header: "Accounting Date",
      accessor: "accounting_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    { Header: "Transaction Concept", accessor: "transaction_concept" },
    { Header: "Processing Fee Mode", accessor: "processing_fee_mode" },
    { Header: "TCH Statement Number", accessor: "tch_statement_number" },
    {
      Header: "TCH Statement Date",
      accessor: "tch_statement_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
  ];

  const dtlInvoiceStatementsColumn = [
    { Header: "Reference Number", accessor: "reference_number" },
    { Header: "Issue Date", accessor: "issue_date", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD");
    }, },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    { Header: "TCH Statement Number", accessor: "tch_statement_number",},
    { Header: "TCH Statement Date", accessor: "tch_statement_date", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD");
    }, },
  ];

  const dtlInvoiceFinancialStatementsColumn = [
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "VAT Amount", accessor: "vat_amount" },
    { Header: "Sanral ID", accessor: "sanral_id" },
    { Header: "Accounting Date", accessor: "accounting_date", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD");
    },},
    { Header: "Transaction Concept", accessor: "transaction_concept" },
    { Header: "Processing Fee Mode", accessor: "processing_fee_mode" },
    { Header: "Processing Fee", accessor: "processing_fee" },
    { Header: "Comments", accessor: "comment" },
    { Header: "TCH Statement Number", accessor: "tch_statement_number" },
    { Header: "TCH Statement Date", accessor: "tch_statement_date", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD");
    }, },
  ];

  const dtlTransitTransactionStatementsColumn = [
    { Header: "Financial ID", accessor: "financial_id" },
    { Header: "Financial TT Amount", accessor: "fin_tt_amount" },
    { Header: "Invoice TT Amount", accessor: "invoice_tt_amount" },
    { Header: "TCH Statement Number", accessor: "tch_statement_number" },
    { Header: "TCH Statement Date", accessor: "tch_statement_date", Cell: ({ value }: { value: string }) => {
      return dayjs(value).format("YYYY-MM-DD");
    }, },
    { Header: "Transit TXN ID", accessor: "transit_txn_id" },
    { Header: "TCH GUID", accessor: "tch_guid" },
    { Header: "Invoice Ref Number", accessor: "invoice_ref_number" },
    { Header: "Invoice Fin ID", accessor: "inv_fin_id" },
  ];

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadFinancialTransactionStatementReport({
        format,
        search,
        startDate,
        endDate,
        tch_statement_number: refNum,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  /**
   * handleInvoiceDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleInvoiceDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadInvoiceTransactionStatementReport({
        format,
        search,
        startDate,
        endDate,
        tch_statement_number: refNum,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  /**
   * handleTransitTransactionDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleTransitTransactionDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadTransitTransactionStatementReport({
        format,
        search,
        startDate,
        endDate,
        tch_statement_number: refNum,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  /**
   * handleDetailedInvoiceFinancialStatementDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDetailedInvoiceFinancialStatementDownloadFunc = async (
    params: any
  ) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadDetailInvoiceFinancialTransactionStatementReport({
        format,
        search,
        startDate,
        endDate,
        tch_statement_number: refNum,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2} mb={2}>
        <PcCaseIcon size={25} />
        <Box fontSize="xl" fontWeight="bold">
          Reference Number: {refNum}
        </Box>
        <Spacer />
        <Button size={"sm"} colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>
      <Tabs py={6}>
        <TabList>
          <Tab>Detailed Financial Statements</Tab>
          <Tab>Detailed Invoice Statements</Tab>
          <Tab>Detailed Invoice Financial Statements</Tab>
          <Tab>Detailed Transit Transactions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlFinancialStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_financial_statements?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                downloadFunc={() => {}}
                handleDownloadFunc={handleDownloadFunc}
                showActions={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlInvoiceStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_invoice_statements?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                handleDownloadFunc={handleInvoiceDownloadFunc}
                showActions={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlInvoiceFinancialStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_invoice_financial_statements?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                handleDownloadFunc={
                  handleDetailedInvoiceFinancialStatementDownloadFunc
                }
                showActions={false}
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <DataTableThree
                columns={dtlTransitTransactionStatementsColumn}
                fetchUrl={`${process.env.REACT_APP_BASE_URL}/detail_transit_transaction?tch_statement_number=${refNum}`}
                tokenKey="jwt_access_token"
                handleDownloadFunc={handleTransitTransactionDownloadFunc}
                showActions={false}
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </AdminAppShell>
  );
};
