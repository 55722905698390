import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text,
  Image,
  Box,
  useColorModeValue,
  Link,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon, EmailIcon, LockIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import ThemeShell from "../../layouts/ThemeShell";
import { Formik, Field, Form } from "formik";
import { useAuthStore } from "../../hooks";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import FaipayLogo from "../../assets/FairPayLogo.png";
import { useToast } from "@chakra-ui/react";

const initialValues = {
  email: "",
  password: "",
};

const validate = (values: any) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};

export default function Login() {
  const signIn = useAuthStore((state) => state.signInUser);
  const loading = useAuthStore((state) => state.loading);
  const navigate = useNavigate();
  const toast = useToast();

  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (values: any) => {
    signIn(values).then((res: any) => {
      console.log(res);
      if (!_.isEmpty(res)) {
        if (res?.detail) {
          toast({
            title: "Oops! Please try again.",
            description: `${res?.detail}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Hey. Welcome back ",
            description: "Happy to see you again",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate("/");
        }
      }
    });
  };

  // Use Chakra's color mode values for theming
  const formBackground = useColorModeValue("white", "gray.700");
  const inputBackground = useColorModeValue("gray.100", "gray.600");
  const textColor = useColorModeValue("gray.800", "white");
  const linkColor = useColorModeValue("teal.500", "teal.200");
  const placeholderColor = useColorModeValue("gray.500", "gray.300");

  return (
    <ThemeShell>
      <Flex
        minH="90vh"
        align="center"
        justify="center"
        bg={useColorModeValue("gray.50", "gray.800")}
      >
        <Container
          maxW="md"
          bg={formBackground}
          borderRadius="lg"
          p={8}
          boxShadow="lg"
        >
          <Stack spacing={8}>
            <Stack align="center">
              <Image src={FaipayLogo} alt="Fairpay logo" w="150px" />
              <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                Transport Solution
              </Text>
            </Stack>

            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={handleSubmit}
            >
              <Form>
                <Stack spacing={4}>
                  <Field name="email">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={form.errors.email && form.touched.email}
                      >
                        <FormLabel color={textColor}>Email address</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <EmailIcon color={placeholderColor} />
                          </InputLeftElement>
                          <Input
                            {...field}
                            type="email"
                            id="email"
                            bg={inputBackground}
                            color={textColor}
                            placeholder="Enter your email"
                            _placeholder={{ color: placeholderColor }}
                          />
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.email &&
                            form.touched.email &&
                            form.errors.email}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="password">
                    {({ field, form }: any) => (
                      <FormControl
                        isInvalid={
                          form.errors.password && form.touched.password
                        }
                      >
                        <FormLabel color={textColor}>Password</FormLabel>
                        <InputGroup>
                          <InputLeftElement pointerEvents="none">
                            <LockIcon color={placeholderColor} />
                          </InputLeftElement>
                          <Input
                            {...field}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            bg={inputBackground}
                            color={textColor}
                            placeholder="Enter your password"
                            _placeholder={{ color: placeholderColor }}
                          />
                          <InputRightElement>
                            <IconButton
                              aria-label={
                                showPassword ? "Hide password" : "Show password"
                              }
                              icon={
                                showPassword ? <ViewOffIcon /> : <ViewIcon />
                              }
                              onClick={() =>
                                setShowPassword((show) => !show)
                              }
                              variant="ghost"
                              size="sm"
                            />
                          </InputRightElement>
                        </InputGroup>
                        <FormErrorMessage>
                          {form.errors.password &&
                            form.touched.password &&
                            form.errors.password}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Stack spacing={6}>
                    <Button
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                      isLoading={loading}
                    >
                      Login
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            </Formik>
          </Stack>
        </Container>
      </Flex>
    </ThemeShell>
  );
}
