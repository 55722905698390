import { Box, Flex, useToast } from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DataTableThree from "../../../components/DataTableThree";
import { useReconStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
import dayjs from "dayjs";

export const TransactionStatement = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const downloadTansactionStatementReport = useReconStore(
    (state) => state.downloadTansactionStatementReport
  );
  const downloadTransactionStatementDetails = useReconStore(
    (state) => state.downloadTransactionStatementDetails
  );
  const columns = [
    { Header: "Reference Number", accessor: "reference_number" },
    { Header: "Total Amount", accessor: "total_amount" },
    { Header: "Vat Amount", accessor: "vat_amount" },
    {
      Header: "Issue Date",
      accessor: "issue_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: ({ value }: { value: string }) => {
        return dayjs(value).format("YYYY-MM-DD");
      },
    },
  ];

  const handleMoreClick = (item: any) => {
    console.log(item);
    navigate(
      `/admin/transaction-statements/${item.id}?refNum=${item.reference_number}`
    );
  };

  const handleDownloadClick = (item: any) => {
    console.log("see items", item);

    downloadTransactionStatementDetails({
      format: "excel",
      tch_statement_number: item.reference_number,
    })
      .then((res: any) => {
        console.log(res);
        // place notification
      })
      .catch((error: any) => {
        console.log(error);
        // place notification
      });
  };

  /**
   * handleDownloadFunc handles exporting data based on parameters.
   *
   * @param {Object} params - Parameters for export.
   * @param {string} params.format - 'csv' or 'excel'.
   * @param {string} params.type - 'full' or 'filtered'.
   * @param {string} [params.search] - Optional search term.
   * @param {string} [params.startDate] - Optional start date.
   * @param {string} [params.endDate] - Optional end date.
   */
  const handleDownloadFunc = async (params: any) => {
    const { format, type, search, startDate, endDate } = params;

    try {
      await downloadTansactionStatementReport({
        format,
        search,
        startDate,
        endDate,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleDownloadTransactionStatementFunc = async (params: any) => {
    const { format, details } = params;

    console.log("see details", details);

    try {
      await downloadTransactionStatementDetails({
        format,
        search: "",
        startDate: "",
        endDate: "",
        details,
      });

      // Show a success toast
      toast({
        title: "Export Successful",
        description: `Your ${format.toUpperCase()} file has been downloaded.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      // Handle specific error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          toast({
            title: "Export Failed",
            description: data.detail || "Invalid export parameters.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 404) {
          toast({
            title: "No Records Found",
            description: "There are no records matching your criteria.",
            status: "warning",
            duration: 5000,
            isClosable: true,
          });
        } else if (status === 401) {
          toast({
            title: "Unauthorized",
            description: "Your session has expired. Please log in again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          // Optionally, redirect to login
        } else {
          toast({
            title: "Export Failed",
            description:
              "An unexpected error occurred while exporting the data.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      } else {
        // Network or other errors
        toast({
          title: "Export Failed",
          description: "A network error occurred. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <AdminAppShell>
      <Flex alignItems="center" gap={2}>
        <PcCaseIcon size={35} />
        <Box fontSize="2xl" fontWeight="bold">
          Transaction Statements
        </Box>
      </Flex>

      <Box py={4}>
        <DataTableThree
          columns={columns}
          fetchUrl={`${process.env.REACT_APP_BASE_URL}/transaction_statements`}
          tokenKey="jwt_access_token"
          downloadFunc={handleDownloadTransactionStatementFunc}
          handleDownloadFunc={handleDownloadFunc}
          handleMore={handleMoreClick}
          showExports={true}
          showDownloadButton={true}
        />
      </Box>
    </AdminAppShell>
  );
};
