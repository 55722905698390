import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spacer,
  Stack,
  Text,
  Button,
  FormErrorMessage,
  useToast,
  InputGroup,
  InputRightElement,
  IconButton,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { PcCaseIcon, EyeIcon, EyeOffIcon } from "lucide-react";
import React, { useState } from "react";
import { useAuthStore, useStore } from "../../hooks";
import AdminAppShell from "../../layouts/AdminAppShell";
import * as Yup from "yup";

const Profile = () => {
  const posUser = useAuthStore((state) => state.user);
  const updatePersonalInternalUserPassword = useStore(
    (state) => state.updatePersonalInternalUserPassword
  );
  const toast = useToast();

  // Validation schema for password change form
  const PasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("New password is required"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Please confirm your new password"),
  });

  const handlePasswordChange = (values: any, actions: any) => {
    const payload = {
      id: posUser?.id,
      password: values.newPassword,
      confirm_password: values.confirmNewPassword,
    };

    updatePersonalInternalUserPassword(payload)
      .then((response: any) => {
        if (response.message === "Password updated successfully") {
          actions.resetForm();
          actions.setSubmitting(false);
          toast({
            title: "Success",
            description: "Password updated successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } else {
          // Handle validation errors
          if (Array.isArray(response)) {
            const fieldMapping: any = {
              password: "newPassword",
              confirm_password: "confirmNewPassword",
            };

            const formErrors: Record<string, string> = {};

            response.forEach((error: any) => {
              const fieldName = error.loc[1]; // Get the field name from "loc"
              const formikFieldName = fieldMapping[fieldName];
              if (formikFieldName) {
                formErrors[formikFieldName] = error.msg;
              }
            });

            actions.setErrors(formErrors);
          } else {
            // Handle other types of errors
            toast({
              title: "Error",
              description:
                "An error occurred while updating your password",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }

          actions.setSubmitting(false);
        }
      })
      .catch((error: any) => {
        // Handle network or unexpected errors
        console.error("Password update failed:", error);
        toast({
          title: "Error",
          description:
            "An unexpected error occurred. Please try again later.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        actions.setSubmitting(false);
      });
  };

  // State to manage visibility of passwords
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Profile
          </Box>
        </Flex>
        <Spacer />
      </Flex>

      {/* Side-by-side layout */}
      <Flex mt={5} gap={5} direction={{ base: "column", md: "row" }}>
        {/* User Information Card */}
        <Card width={"40%"}>
          <CardHeader>
            <Heading size="md">User Information</Heading>
          </CardHeader>
          <CardBody>
            <Stack direction="column" spacing={6}>
              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input readOnly value={posUser?.first_name} />
              </FormControl>

              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input readOnly value={posUser?.last_name} />
              </FormControl>

              <FormControl>
                <FormLabel>Contact Email</FormLabel>
                <Input readOnly value={posUser?.email} />
              </FormControl>
            </Stack>
          </CardBody>
        </Card>

        {/* Password Change Card */}
        <Card flex="1">
          <CardHeader>
            <Heading size="md">Change Password</Heading>
          </CardHeader>
          <CardBody>
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmNewPassword: "",
              }}
              validationSchema={PasswordSchema}
              onSubmit={handlePasswordChange}
            >
              {(props) => (
                <Form>
                  <Stack direction="column" spacing={6}>
                    {/* Current Password Field */}
                    <FormControl
                      isInvalid={
                        !!props.errors.currentPassword &&
                        !!props.touched.currentPassword
                      }
                    >
                      <FormLabel>Current Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          type={showCurrentPassword ? "text" : "password"}
                          name="currentPassword"
                          placeholder="Enter your current password"
                        />
                        <InputRightElement>
                          <IconButton
                            variant="ghost"
                            aria-label={
                              showCurrentPassword
                                ? "Hide password"
                                : "Show password"
                            }
                            icon={
                              showCurrentPassword ? (
                                <EyeOffIcon />
                              ) : (
                                <EyeIcon />
                              )
                            }
                            onClick={() =>
                              setShowCurrentPassword(!showCurrentPassword)
                            }
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {props.errors.currentPassword}
                      </FormErrorMessage>
                    </FormControl>

                    {/* New Password Field */}
                    <FormControl
                      isInvalid={
                        !!props.errors.newPassword &&
                        !!props.touched.newPassword
                      }
                    >
                      <FormLabel>New Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          type={showNewPassword ? "text" : "password"}
                          name="newPassword"
                          placeholder="Enter a new password"
                        />
                        <InputRightElement>
                          <IconButton
                            variant="ghost"
                            aria-label={
                              showNewPassword ? "Hide password" : "Show password"
                            }
                            icon={
                              showNewPassword ? (
                                <EyeOffIcon />
                              ) : (
                                <EyeIcon />
                              )
                            }
                            onClick={() =>
                              setShowNewPassword(!showNewPassword)
                            }
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {props.errors.newPassword}
                      </FormErrorMessage>
                    </FormControl>

                    {/* Confirm New Password Field */}
                    <FormControl
                      isInvalid={
                        !!props.errors.confirmNewPassword &&
                        !!props.touched.confirmNewPassword
                      }
                    >
                      <FormLabel>Confirm New Password</FormLabel>
                      <InputGroup>
                        <Field
                          as={Input}
                          type={
                            showConfirmNewPassword ? "text" : "password"
                          }
                          name="confirmNewPassword"
                          placeholder="Confirm your new password"
                        />
                        <InputRightElement>
                          <IconButton
                            variant="ghost"
                            aria-label={
                              showConfirmNewPassword
                                ? "Hide password"
                                : "Show password"
                            }
                            icon={
                              showConfirmNewPassword ? (
                                <EyeOffIcon />
                              ) : (
                                <EyeIcon />
                              )
                            }
                            onClick={() =>
                              setShowConfirmNewPassword(
                                !showConfirmNewPassword
                              )
                            }
                          />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>
                        {props.errors.confirmNewPassword}
                      </FormErrorMessage>
                    </FormControl>

                    <Button
                      mt={4}
                      colorScheme="green"
                      isLoading={props.isSubmitting}
                      type="submit"
                    >
                      Change Password
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Flex>
    </AdminAppShell>
  );
};

export default Profile;
